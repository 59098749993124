import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Paper } from '@material-ui/core';
import PageLayout from '../../common/PageLayout';
import CommonPage from '../../common/CommonPage';
import CommonTable from '../../common/CommonTable';

@observer
export default class AuditConfigContainer extends CommonPage {
  constructor() {
    super();
    this.state = {
      audit_companies: [],
    };
  }

  componentWillMount() {
    const { url } = this.getAuditMetadata();
    this.action.execute('get', `${this.APIEndpoints.AUDIT}/${url}`, 'audit_companies').then(r => { 
      this.setState({ audit_companies: r.data });
    });
  }

  getAuditMetadata() { 
    const { language } = this.store;
    const isAuditUser = window.location.href.includes('users');
    const isAuditCompany = window.location.href.includes('company');
    const isAuditCatalog = window.location.href.includes('catalog');
    const isAuditFlowApproveGmud = window.location.href.includes('flow-approve-gmud');
    const isAuditForm = window.location.href.includes('form');

    switch (true) {
      case isAuditUser:
        return {
          name: language.USERS,
          url: 'users'
        }
      case isAuditCompany:
        return {
          name: language.COMPANY,
          url: 'company'
        }
      case isAuditCatalog:
        return {
          name: language.CATALOG
        }
      case isAuditFlowApproveGmud:
        return {
          name: language.GMUD_FLOW
        }
      case isAuditForm:
        return {
          name: language.FORMS
        }
    }
  }

  render() {
    const isAuditUser = window.location.href.includes('users')

    const { name } = this.getAuditMetadata();
    const { language } = this.store;
    const data = [];
    let col = [
      { key: 'company', label: name },
      { key: 'responsible', label: language.RESPONSIBLE },
      { key: 'date', label: language.DATE, style: { width: 80 } },
      { key: 'action', label: language.ACTION },
    ];

    if( isAuditUser ) {
      col = [
        { key: 'affected', label: language.USER },
        { key: 'email', label: language.EMAIL },
        { key: 'date', label: language.DATE, style: { width: 80 } },
        { key: 'responsible', label: language.RESPONSIBLE },
        { key: 'action', label: language.ACTION },
      ];
    }
    
    return (
      <PageLayout
        icon={<div className="auditIcon titleIconSize" />}
        title={`${language.AUDIT} - ${name}`}
        rightElements={[]}
      >
        <Paper style={{ padding: 0, marginTop: '-1rem' }}>
          <CommonTable
            title={''}
            data={this.state.audit_companies.map(a => ({
              ...a,
              action: (<p style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: a.action }}></p>),
              date: moment(a.date).format('DD/MM/YYYY HH:mm:ss'),
            }))}
            columns={col}
            language={language}
            qtdRow={data.length}
            hideFooterToolbar={true}
          />
        </Paper>
      </PageLayout>
    );
  }
}

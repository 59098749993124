import React, { Fragment, useState, useEffect, useReducer } from 'react';
import GC from '@common/GC';
import { Grid } from '@material-ui/core';
import CommonForm from '@common/CommonForm2';
import CommonTable from '@common/CommonTable';
import Button from '@common/Button';
import AttachFile from '@common/AttachFile';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import Dialog from '@common/Dialog';
import Constants from '@data/Constants';
import decrypt from '../../../util/decrypt';
import DialogConfirm from '../../../common/DialogConfirm';
import DownloadFiles from '../../../util/DownloadFiles';
import Store from '../../../data/Store';
import { getGroupsFromUserLogged } from '../../sac/utils/getGroupsFromUserLogged';
import { translateFromPtBR } from '@common/languages/Dictionary';

const { language } = Store;

const crypto = require('crypto');
const Scroll = ({ children, h }) => (
  <PerfectScrollbar
    className="scrollVisible"
    style={{
      height: h || 104,
    }}
    option={{
      suppressScrollX: true,
    }}
  >
    {children}
  </PerfectScrollbar>
);

const YesOrNot = {
  yes: language.YES,
  not: language.NO,
};

const RejectStatus = {
  Reject: language.REJECTED,
  Cancel: language.CANCELED,
  Reject_CAB: language.CAB_REJECTED,
};

const createRandomToken = (text) => {
  const aSecret = 'Orch3str0!@forever!';

  try {
    const crypto = require('crypto');

    let cipher, tRet;

    cipher = crypto.createCipher('aes-256-cbc', aSecret);
    tRet = cipher.update(text.toString(), 'utf8', 'base64');
    tRet += cipher.final('base64');
    return tRet;
  } catch (e) {
    return {};
  }
};

const getFieldAlias = (field) => {
  switch (field) {
    case 'companies_var':
      return 'companies_var';
    case 'users':
      return 'allusers_var';
    case 'subdepartments':
      return 'subsdpt_var';
    case 'departments':
      return 'departments_var';
    case 'boss':
      return 'chieftdpt_var';
    case 'contracts':
      return 'contracts_var';
    default:
      return field;
  }
}

const getMaybeValueBoolean = (value) => { 
  return value === true ? language.YES : value === false ? language.NO : value;
}

const formshtmlLabel = (t, htmlValues) => {
  const result = [];
  const html = $('<div />', { html: t });

  try {
    JSON.parse(htmlValues).forEach((item) => {
      const aliasField = getFieldAlias(item.name);
      const el = html.find('[fieldtype="' + aliasField + '"]');
      const label = html.find('[for="' + aliasField.replace('[]', '') + '"]').text() || el.attr('label') || item.label;
      result.push({ field: label, value: getMaybeValueBoolean(item.value) });
    });
    return result;
  } catch {
    return ' ';
  }
}

export default function ({
  option,
  findOption,
  change,
  activities,
  onFormSubmitAc,
  users,
  donwFileAc,
  change_risks,
  donwFileAc_,
  action,
  APIEndpoints,
  Progress,
  toggleAlert,
  cabBoard,
  validTech,
  validTechCheck,
  userl,
  setvalidTech,
  setOpenFinishedJustify,
  setOpenRPIJustify,
  changeStatus,
  preventActionsDueGmudFreeze,
}) {
  let [modal, SetModal] = useState(false);
  let [modalAc, SetModalAc] = useState(false);
  let [modalDetail, SetmodalDetail] = useState(false);
  let [modalDetailDesc, SetmodalDetailDesc] = useState(false);
  let [TypeEv, setTypeEv] = useState(0);
  let [log, SetLog] = useState('');
  let [acselect, Setacselect] = useState('');
  let [integration, setIntegration] = useState('default');
  let list = ['Baixo', 'Médio', 'Alto'];
  let [modalProgress, setModalProgress] = useState(false);
  let [cancel, setCancel] = useState(false);
  const [idActivitySelected, setIdActivitySelected] = useState(0);
  const [modalApprove, setModalApprove] = useState(false);
  const [modalReject, setModalReject] = useState(false);
  const [modalEditTechResponsible, setModalEditTechResponsible] =
    useState(undefined);

  const [groupsFromUserLogged, setGroupsFromUserLogged] = useState([]);
  const [lineApprove, setLineApprove] = useState({});
  function decryp(aMsg) {
    var aSecret = 'Orch3str0!@forever!';
    try {
      aMsg = aMsg.replace(/\s/g, '');
      let decipher, tRet;
      decipher = crypto.createDecipher('aes-256-cbc', aSecret);

      tRet = decipher.update(aMsg.replace(/\s/g, '+'), 'base64', 'utf8');

      tRet += decipher.final('utf8');
      return tRet;
    } catch (e) {
      return {};
    }
  }
  let generalParams = JSON.parse(
    decrypt(sessionStorage.getItem('params')) || '{}',
  )[0];

  useEffect(() => {
    (async () => {
      const userId = Store.getUserLoged().id;
      const groups = await getGroupsFromUserLogged(userId);
      setGroupsFromUserLogged(groups);
    })();
  }, []);

  const onDownFile = (path, file) => {
    DownloadFiles(path, file);
  };

  const Progress_ = (v) => {
    acselect.progress = true;
    acselect.id_change = change.id_change;
    Progress(acselect);
  };

  const validOrder = (g) => {
    let v = activities.filter(
      (item) =>
        item.id_change_activity !== g.id_change_activity &&
        item.checked != true &&
        // g.type == item.type &&
        g.priority > item.priority,
    );

    if (v.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  const onFormSubmitAc_ = (v) => {
    let filesSend = [];
    SetModalAc(false);
    setModalProgress(false);
    let cont = 0;

    let contador = 0;
    let campossize = false;
    let camposobrigatorios = false;
    let camposobrigatoriosradio = false;
    let camposobrigatorioscheck = false;
    let fields = [];
    let template = '';
    $('#formatasktemplate').html();
    if ($('#formatasktemplate').html() != undefined) {
      $('#formatasktemplate textarea').each(function () {
        $(this).attr('value', $(this).val());

        $(this).html($(this).val());
        fields.push({ name: $(this).attr('name'), value: $(this).val() });
      });

      $('#formatasktemplate input').each(function () {
        if ($(this).attr('type') == 'radio') {
          if (
            $('input[name=' + $(this).attr('name') + ']:checked').val() ==
            $(this).val()
          ) {
            $(this).attr('checked', true);
            fields.push({
              name: $(this).attr('name'),
              value: $(this).attr('value'),
            });
          } else {
            $(this).removeAttr('checked');
          }
          let ip = $(this);

          $('#template  label').each(function () {
            var for_ = '';
            try {
              for_ = $(this).attr('for');
            } catch (e) {}

            if (for_ === ip.attr('name')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatoriosradio = true;
                  $('[name=' + for_ + ']').each(function () {
                    try {
                      if ($(this).is(":checked")) {
                        camposobrigatoriosradio = false;
                      }
                    } catch (e) {}
                  });
                  $(this).css('color', camposobrigatoriosradio ? 'red' : 'var(--primary)');
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'checkbox') {
          if (
            $('input[id=' + $(this).attr('id') + ']:checked').attr('id') ==
            $(this).attr('id')
          ) {
            $(this).attr('checked', true);
            fields.push({
              name: $(this).attr('name'),
              value: $(this).attr('value'),
            });
          } else {
            $(this).removeAttr('checked');
          }
          let ip = $(this);
          $('#template  label').each(function () {
            var for_ = ' ';
            try {
              for_ = $(this).attr('for');
            } catch (e) {}

            if (ip.attr('name').indexOf(for_) > -1) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  camposobrigatorioscheck = true;
                  $('#template  input').each(function () {
                    try {
                      if ($(this).attr('name').indexOf(for_) > -1) {
                        try {
                          if ($(this).is(":checked")) {
                            camposobrigatorioscheck = false;
                          }
                        } catch (e) {}
                      }
                    } catch (e) {}
                  });
                  $(this).css('color', camposobrigatorioscheck ? 'red' : 'var(--primary)');
                }
              }
            }
          });
        } else if ($(this).attr('type') == 'datetime-local') {
          const userLogin = Store.getUserLoged();
          const timezone = userLogin?.timezone?.value;

          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: moment.utc($(this).val()).toISOString(),
          });
          $(this).attr('value', $(this).val());
        } else {
          $(this).attr('value', $(this).val());
          fields.push({
            name: $(this).attr('name') || $(this).attr('id'),
            value: $(this).attr('value'),
          });
          let siz = $(this).attr('max');
          let min = $(this).attr('min');
          if (siz) {
            if (
              parseInt($(this).val().length) > parseInt(siz) ||
              parseInt($(this).val().length) < parseInt(min)
            ) {
              $(this).css('border-color', 'red');
              $(this).before(
                `<label  id="formtemplabel__" style='color:red'>  ${
                  min || 0
                } á ${siz || 0} ${language.CHARACTERS}</label>`,
              );

              campossize = true;
            }
          }

          let ip = $(this);
          $('#template  label').each(function () {
            if ($(this).attr('for') === ip.attr('id')) {
              if ($(this).text().split('*')[1] != undefined) {
                if ($(this).text().split('*')[1].length >= 0) {
                  if (ip.attr('value').length == 0) {
                    camposobrigatorios = true;
                  }
                }
              }
            }
          });
        }
      });

      $('#formatasktemplate select').each(function () {
        $('#' + $(this).children('option:selected').attr('id')).attr(
          'selected',
          'selected',
        );

        fields.push({
          name: $(this).attr('name') || $(this).attr('id'),
          value: $(this).find(':selected').text(),
        });

        const label = $(this).parent().find('label');
        if (label.text().split('*')[1] != undefined) {
          if (label.text().split('*')[1].length >= 0) {
            if (!$(this).val() || $(this).val() == "") {
              camposobrigatorios = true;
              $(this).css('border-color', 'red');
            } else {
              $(this).css('border-color', 'var(--primary)');
            }
          }
        }
      });

      $('#formatasktemplate label').each(function () {
        try {
          if ($(this).attr('for').indexOf('textarea') > -1) {
            if ($(this).text().split('*')[1] != undefined) {
              if ($(this).text().split('*')[1].length >= 0) {
                // alert($('#'+$(this).attr('for')).text())

                let node_ = $(this)
                  .parent()
                  .parent()
                  .nextAll()
                  .slice(2, 3)
                  .children()[0];
                if (
                  (node_ &&
                    node_.firstChild.tagName == 'P' &&
                    node_.firstChild.textContent.length == 0) ||
                  (!node_ && $('#' + $(this).attr('for')).text().length == 0)
                ) {
                  camposobrigatorios = true;
                }
              }
            }
          }
        } catch (e) {}
      });
      template = createRandomToken($('#formatasktemplate').html());
    }

    if (v.files && v.files.length > 0) {
      let total = Array.from(v.files).length;
      Array.from(v.files).forEach((file) => {
        const promise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (!!reader.result) {
              resolve(reader.result);
            } else {
              reject(Error('Failed converting to base64'));
            }
          };
        });
        promise.then((result) => {
          if (file.size >= 40000000) {
            alert(
              language.FILE + ' ' + file.name + ' ' + language.MORE_THAN_40MB,
            );
            this.setState({ sendFile: false });
            cont = 0;
          } else {
            filesSend.push({
              file: result,
              type: file.type,
              namefile: file.name,
              name: file.name,
            });
            cont = cont + 1;
            if (total === cont) {
              v.id_activity = acselect.id_change_activity;
              v.fk_id_sponsor = acselect.fk_id_sponsor;
              v.id_change = change.id_change;
              v.files = filesSend;
              onFormSubmitAc(v);
            }
          }
        });
      });
    } else {
      v.id_activity = acselect.id_change_activity || idActivitySelected;
      v.fk_id_sponsor = acselect.fk_id_sponsor;
      v.id_change = change.id_change;
      if (template !== '') {
        v.template = template;
        v.fields = JSON.stringify(fields);
      }

      if (cancel == true) {
        v.executed = 3;
      }
      onFormSubmitAc(v);
    }
  };

  useEffect(() => {
    if (acselect.id_change_activity > 0) {
      validTechCheck(acselect.id_change_activity, acselect);
    }
  }, [acselect.id_change_activity]);

  useEffect(() => {
    if (validTech == true) {
      if (validOrder(acselect) == true) {
        if (acselect !== '') {
          setModalProgress(true);
        }
      } else {
        toggleAlert(true, language.INCORRECT_ORDER, 'error');
      }
    } else {
      if (acselect.fk_id_sponsor > 0) {
      } else {
      }
    }
    setvalidTech(false);
  }, [validTech]);

  const initialState = {};
  const [json_template_solicitation, updateState] = useReducer(
    (state, updates) => ({
      ...state,
      ...updates,
    }),
    initialState,
  );

  const formshtml = (t) => {
    let data = [];

    t.split('<div').map((i, key) => {
      const required = (i || '').indexOf('formbuilder-required') > -1;
      const addEmptyOption = i.indexOf('addemptyoption') > -1 || i.indexOf('add-empty-option') > -1;
      const labelMatch = (i || '').match(/label=\"([^\"]+)\"/);
      const label = labelMatch ? labelMatch[1] : undefined;

      if( addEmptyOption && i.indexOf('<select') > -1 ) {
        i.replace(/>Selecione</, function(match) {
          let originalText = match.slice(1, -1);
          let newText = translateFromPtBR(originalText, language);
          return `>${newText}<`;
        });
      }

      if (i.indexOf('companies_var') > -1) {
        data.push(
          data.push(
            <div id="companies_var" style={{ margin: '10px 0px' }}>
              <CommonForm
                onChangeField={(field, value) => {
                  updateState({ companies_var: value });
                }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'companies_var',
                    label: '',
                    method: 'POST',
                    route: `${APIEndpoints.COMPANY}/filter`,
                    fieldquery: 'search',
                    textlabel: label || language.COMPANY,
                    textinit: '',
                    fieldvaluedb: 'name',
                    fieldlabeldb: 'name',
                    idinit: 0,
                    routeAll: `${APIEndpoints.COMPANY}/all?`,
                    methodGetAll: 'POST',
                    routeGetCount: `${APIEndpoints.COMPANY}/count`,
                    detail: true,
                    showEmptyFilter: addEmptyOption,
                    required,
                    visible: true,
                    disablePortal: true,
                    showId: true,
                    idFieldToShow: 'id_company',
                  },
                ]}
              />
            </div>,
          ),
        );
      } else if (i.indexOf('allusers_var') > -1) {
        data.push(
          <div id="allusers_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ allusers_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'allusers_var',
                  label: '',
                  method: 'POST',
                  route: `${APIEndpoints.USER}/filter`,
                  fieldquery: 'search',
                  textlabel: label || language.USER,
                  textinit: '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 'Usuário',
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                  detail: true,
                  visible: true,
                  disablePortal: true,
                  showId: true,
                  showEmptyFilter: addEmptyOption,
                  required,
                  idFieldToShow: 'id_user',
                },
              ]}
            />
          </div>,
        );
      } else if (i.indexOf('hours_var') > -1) {
        let nameField = 'hours_var';
        try {
          const regex = /<p(.*?)<\/p>/;
          const textWithoutTags = i.match(regex);
          nameField = textWithoutTags[1].split('|')[1];
        } catch (e) {}
        data.push(
          <div id="hours_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ hours: value });
              }}
              fields={[
                {
                  col: 12,
                  name: nameField,
                  label: label || language.HOURS,
                  type: 'time',
                  format: '##:##',
                  required,
                },
              ]}
            />
          </div>,
        );
      } else if (i.indexOf('hidden_var') > -1) {
        let value_field = '';
        let name_field = '';
        try {
          const regex = /<p(.*?)<\/p>/;
          const textWithoutTags = i.match(regex);
          name_field = textWithoutTags[1].split('|')[1];
          value_field = textWithoutTags[1].split('|')[2];
        } catch (e) {}
        const html_field = `<div hidden><input value = ${value_field} name = ${name_field} /></div>`;
        data.push(<div dangerouslySetInnerHTML={{ __html: html_field }} />);
      } else if (i.indexOf('subsdpt_var') > -1) {
        data.push(
          <div id="subsdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ subdepartments_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'subdepartments',
                  label: '',
                  textlabel: label || language.SUBDEPARTMENT,
                  method: 'POST',
                  filterAll: { fk_id_department: 1 },
                  filter: { fk_id_department: 1 },
                  route: `${APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  disablePortal: true,
                  showEmptyFilter: addEmptyOption,
                  required,
                  showId: true,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>,
        );
      } else if (i.indexOf('departments_var') > -1) {
        data.push(
          <div id="departments_var" style={{ margin: '10px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ departments_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'departments',
                  label: '',
                  textlabel: label || language.DEPARTMENTS,
                  method: 'POST',
                  route: `${APIEndpoints.DEPARTMENT}/filter/`,
                  routeGetCount: `${APIEndpoints.DEPARTMENT}/filter?count=0`,
                  fieldquery: 'search',
                  textinit: '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${APIEndpoints.DEPARTMENT}/filter?`,
                  methodGetAll: 'POST',
                  disablePortal: true,
                  showId: true,
                  showEmptyFilter: addEmptyOption,
                  required,
                  idFieldToShow: 'id_department',
                },
              ]}
            />
          </div>,
        );
      } else if (i.indexOf('contracts_var') > -1) {
        data.push(
          <div>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ contracts_var: value });
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'contracts_var',
                  label: '',
                  method: 'POST',
                  route: `${APIEndpoints.CONTRACT}/filter`,
                  fieldquery: 'search',
                  textlabel: ``,
                  textinit: '',
                  fieldvaluedb: 'id_contract',
                  fieldlabeldb: 'name',
                  idinit: 0,
                  routeAll: `${APIEndpoints.CONTRACT}/all?`,
                  methodGetAll: 'POST',
                  routeGetCount: `${APIEndpoints.CONTRACT}/count?`,
                  textlabel: language.CONTRACTS || label,
                  showId: true,
                  showEmptyFilter: addEmptyOption,
                  required,
                  idFieldToShow: 'id_contract',
                },
              ]}
            />
          </div>,
        );
      } else if (i.indexOf('chieftdpt_var') > -1) {
        data.push(
          <div id="chieftdpt_var" style={{ margin: '15px 0px 30px 0px' }}>
            <CommonForm
              onChangeField={(field, value) => {
                updateState({ chieftdpt_var: value });
                setChieftDptName(value);
              }}
              fields={[
                {
                  col: 12,
                  type: 'autocomplete',
                  name: 'boss',
                  label: '',
                  textlabel: label || language.DEPARTMENT_BOSS,
                  method: 'POST',
                  filter: { fk_id_company: 1 },
                  route: `${APIEndpoints.USER}/filter`,
                  routeGetCount: `${APIEndpoints.USER}/active/count/all`,
                  fieldquery: 'search',
                  textinit: '',
                  fieldvaluedb: 'name',
                  fieldlabeldb: 'name',
                  idinit: 'Chefe de Departamento',
                  routeAll: `${APIEndpoints.USER}/filter/all?`,
                  filterAll: 0,
                  methodGetAll: 'POST',
                  detail: true,
                  visible: true,
                  showEmptyFilter: addEmptyOption,
                  required,
                  disablePortal: true,
                },
              ]}
            />
          </div>,
        );
      } else {
        if (i.length > 0) {
          let g = '';
          if (i.indexOf('ql-tooltip ql-hidden') > -1) {
            g = `<div style = "display : none" ${i}`;
          } else if (
            i.indexOf('ql-preview') > -1 ||
            i.indexOf('ql-clipboard') > -1 ||
            i.indexOf('ql-snow') > -1
          ) {
            g = `<div  ${i}`;
          } else if (i.indexOf('ql-editor') > -1) {
            g = `<div style = "border: 1px solid #629daa80; min-height : 30px; border-radius : 0px 0px 10px 10px" ${i}`;
          } else {
            g = `<div class = "template-dinamic" ${i}`;
          }

          data.push(<div dangerouslySetInnerHTML={{ __html: g }} />);
        }
      }
    });
    return data;
  };
  let order = [4, 1, 2, 3];

  activities = activities.sort(function (a, b) {
    return a.priority - b.priority;
  });

  activities = activities.map((item) => {
    const hasActivityGroup = groupsFromUserLogged.some((g) => (
      String(g.id_group_users) === String(item.fk_id_group_tech)
    ));

    return {
      ...item,
      enableGroupEdition: (change.status === 3 || (change.status === 6 && !item.checked))
        && hasActivityGroup,
    };
  });

  /*let itens=[];
  order.map(p=>{
     activities.filter(g=>g.type == p).map(a=>{
      itens.push(a);
    })
   })

   activities = itens*/

  const html = change.html ? decrypt(change.html) : null;

  return (
    <div id="InfoTicket">
      {/*------------------------------------TABELA ABAIXO DO FORMULÁRIO--------------------------------------------------------------*/}

      {/*------------------------------------LINHA CAB--------------------------------------------------------------*/}

      {cabBoard &&
        cabBoard.cab_id &&
        [
          'Cancelled',
          'CAB',
          'Closed',
          'Concluded',
          'Finished',
          'Progress',
          'Rollback',
        ].includes(cabBoard.gmud_status) && (
          <>
            <Grid item xs={4}>
              <GC
                items={[
                  {
                    label: 'CAB',
                    value: moment(cabBoard.cab_date).format('DD/MM/YYYY'),
                  },
                  {
                    label: 'ATA',
                    value: cabBoard.record && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: cabBoard.record,
                        }}
                      />
                    ),
                  },
                  {
                    label: language.ATTACHMENT,
                    value:
                      cabBoard.files &&
                      cabBoard.files.map((cB) => (
                        <AttachFile
                          onClick={() =>
                            onDownFile(
                              `${APIEndpoints.CHANGE}/cab/record/${cB.id_change_cab_file}&${cB.file}`,
                              cB.file,
                            )
                          }
                          label={cB.name}
                        />
                      )),
                  },
                ]}
              />
            </Grid>
            {cabBoard.users && (
              <Grid container spacing={16} className="mt-3">
                <Grid item xs={12}>
                  <div className="_table">
                    <CommonTable
                      paper={false}
                      mini
                      bar={false}
                      pagination={false}
                      orderColumn={false}
                      col={[
                        {
                          key: 'name',
                          label: language.NAME,
                          thConfig: true,
                          style: { width: '75%' },
                        },
                        {
                          key: 'approved',
                          label: language.APPROVED,
                          thConfig: true,
                          style: { width: '5%' },
                        },
                        {
                          key: 'rejected',
                          label: language.REJECTED,
                          thConfig: true,
                          style: { width: '5%' },
                        },
                        {
                          key: 'date',
                          label: language.DATE,
                          thConfig: true,
                          style: { width: '15%' },
                        },
                      ]}
                      data={cabBoard.users.map((cbu) => ({
                        name: cbu.name,
                        approved: cbu.dt_up
                          ? cbu.approved && <i class="fas fa-check" />
                          : '',
                        rejected: cbu.dt_up
                          ? cbu.rejected && <i class="fas fa-times" />
                          : '',
                        date: cbu.dt_up ? moment(cbu.dt_up).format('LLL') : '',
                      }))}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
            <hr />
          </>
        )}

      {/*------------------------------------LINHA 1--------------------------------------------------------------*/}
      <Grid container spacing={16} className="mt-3">
        {/*------------------------------------LINHA 1 - COLUNA 1 --------------------------------------------------------------*/}
        <Grid item xs={12}>
          {(change._status == 'Finish_Fail' ||
            change._status == 'Finish_Partial' ||
            change._status == 'Finish_Cancel' ||
            change._status == 'Finished') &&
            change.rpi !== true && (
              <Button
                variant="normal"
                label={language.SEE_FINALIZATION}
                color="success"
                style={{ marginBottom: 10, marginRight: 10, padding: 10 }}
                onClick={() => setOpenFinishedJustify(true)}
                icon="fas fa-eye"
              />
            )}
          {change.rpi && (
            <Button
              variant="normal"
              label={language.SEE_RPI}
              color="success"
              style={{ marginBottom: 10, marginLeft: 10, padding: 10 }}
              onClick={() => setOpenRPIJustify(true)}
              icon="fas fa-eye"
            />
          )}
          <h3 className="title">{language.ACTIVITIES}</h3>
          {modalEditTechResponsible && (
            <Dialog
              modal
              open
              title={language.EDITING_OF_TECH_GMUD_AFTER_APPROVAL}
              onClose={() => setModalEditTechResponsible(undefined)}
            >
              <CommonForm
                values={{ fk_id_sponsor: modalEditTechResponsible.name }}
                fields={[
                  {
                    col: 12,
                    type: 'autocomplete',
                    name: 'fk_id_sponsor',
                    label: language.RESPONSIBLE,
                    textlabel: language.RESPONSIBLE,
                    textinit:
                      modalEditTechResponsible.nameuser || language.RESPONSIBLE,
                    method: 'POST',
                    routeAll: `${Constants.APIEndpoints.USER}/techgroup/${modalEditTechResponsible.fk_id_group_tech}?`,
                    fieldquery: 'search',
                    textinit:
                      modalEditTechResponsible.nameuser || language.RESPONSIBLE,
                    fieldvaluedb: 'id_user',
                    fieldlabeldb: 'name',
                    idinit:
                      (modalEditTechResponsible &&
                        modalEditTechResponsible.fk_id_sponsor) ||
                      0,
                    methodGetAll: 'POST',
                    showEmail: true,
                    isMulti: false,
                  },
                ]}
                onSubmit={(values) => {
                  action
                    .execute(
                      'put',
                      Constants.APIEndpoints.CHANGE +
                        `/activity/update/techresponsible/${modalEditTechResponsible.id_change_activity}/${values.fk_id_sponsor}`,
                    )
                    .then(() => {
                      setModalEditTechResponsible(undefined);
                      window.location.reload();
                    })
                    .catch((e) => {
                      console.log(
                        'Ocorreu um erro ao atualizar o responsável da atividade',
                        e,
                      );
                    });
                }}
              />
            </Dialog>
          )}
          <div className="_table">
            <CommonTable
              paper={false}
              mini
              bar={false}
              pagination={false}
              orderColumn={false}
              col={[
                {
                  key: 'id_change_activity',
                  label: language.ID,
                  thConfig: true,
                  style: { width: '5%' },
                },
                {
                  key: 'type',
                  label: language.TYPE,
                  thConfig: true,
                  style: { width: '5%' },
                },
                {
                  key: 'dt_start',
                  label: language.START_DATE,
                  thConfig: true,
                  style: { width: '10%' },
                },
                {
                  key: 'end_start',
                  label: language.END_DATE,
                  thConfig: true,
                  style: { width: '10%' },
                },
                {
                  key: 'name',
                  label: language.NAME,
                  thConfig: true,
                  style: { width: '30%' },
                },
                {
                  key: 'descri',
                  label: language.DESCRIPTION,
                  thConfig: true,
                  style: { width: '5%' },
                },
                {
                  key: 'Anexos',
                  label: language.ATTACHMENTS,
                  thConfig: true,
                  style: { width: '10%' },
                },
                {
                  key: 'tech',
                  label: language.TECH,
                  thConfig: true,
                  style: { width: '10%' },
                },
                {
                  key: 'group_tech',
                  label: language.TECH_GROUP,
                  thConfig: true,
                  style: { width: '10%' },
                },
                {
                  key: 'executed',
                  label: language.EXECUTED,
                  thConfig: true,
                },
                {
                  key: 'execTec',
                  label: language.LAST_UPDATE,
                  thConfig: true,
                  style: { width: '10%' },
                },
                {
                  key: 'time_consumed',
                  label: language.ACTUAL_START_DATE,
                  thConfig: true,
                  style: { width: '7,5%' },
                },
                {
                  key: 'dt_execution',
                  label: language.ACTUAL_END_DATE,
                  thConfig: true,
                  style: { width: '10%' },
                },
                {
                  key: 'Anexos_',
                  label: language.EXECUTION_ANNEXES,
                  thConfig: true,
                  style: { width: '7,5%' },
                },
                {
                  key: 'status',
                  label: language.STATE,
                  thConfig: true,
                  style: { width: '7,5%' },
                },
                {
                  key: 'ac',
                  label: '#',
                  thConfig: true,
                  style: { width: '5%' },
                },
                {
                  key: 'priority',
                  label: '#',
                  thConfig: true,
                  style: { width: '3%' },
                },
              ]}
              data={
                activities &&
                activities.map((g) => ({
                  id_change_activity: g.id_change_activity,
                  tipo: (
                    <b>
                      {' '}
                      {g.type == 1
                        ? language.ACTIVITY
                        : g.type == 2
                          ? 'Test'
                          : g.type == 3
                            ? 'RollBack'
                            : 'PreRec'}{' '}
                    </b>
                  ),
                  dt_start: moment.utc(g.start_date).format('DD/MM/YYYY HH:mm'),
                  dt_end: moment.utc(g.end_date).format('DD/MM/YYYY HH:mm'),
                  name: g.name,
                  descri: (
                    <i
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        SetmodalDetailDesc(true);
                        Setacselect(g);
                      }}
                      class="fas fa-eye"
                    ></i>
                  ),
                  Anexos:
                    g.file &&
                    g.file.map((h) => (
                      //  const donwFileAc=(id, file, type) =>{
                      <Grid item xs={6}>
                        <AttachFile
                          onClick={() =>
                            donwFileAc(h.id_change_activity_file, h.name, '')
                          }
                          label={h.name}
                          type="image/png"
                        />
                      </Grid>
                    )),
                  tech: (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {g.nameuser}
                      {generalParams.allow_editing_of_tech_gmud_after_approval &&
                        g.enableGroupEdition ? (
                          <Button
                            variant="normal"
                            label={language.EDIT}
                            style={{
                              marginBottom: '10px',
                              marginTop: '10px',
                              width: '100%',
                            }}
                            onClick={() => {
                              setModalEditTechResponsible(g);
                            }}
                          />
                        ) : null}
                    </div>
                  ),
                  group_tech: g.group_tech,
                  executed:
                    change._status == 'CAB' ? (
                      ''
                    ) : g.time_spent != null ? (
                      <Button
                        variant="normal"
                        label={g.fail == true ? language.FAILURE : language.YES}
                        color={g.fail == true ? 'danger' : 'success'}
                        style={{ marginTop: 2 }}
                      />
                    ) : g.cancelled == true ? (
                      <Button
                        variant="normal"
                        label={language.CANCELED}
                        color="danger"
                        style={{ marginTop: 2 }}
                      />
                    ) : g.progress == true ? (
                      <Button
                        variant="normal"
                        label={language.IN_PROGRESS}
                        color="success"
                        style={{ marginTop: 2 }}
                      />
                    ) : (
                      <Button
                        variant="normal"
                        label={language.NOT_EXECUTED}
                        color="default"
                        style={{ marginTop: 2 }}
                        onClick={() => {
                          if (preventActionsDueGmudFreeze) {
                            Store.toggleAlert(
                              true,
                              language.ACTION_NOT_ALLOWED_GMUD_FROZEN,
                              'error',
                            );
                            return false;
                          }
                          if (change._status == 'Progress') {
                            if (g.fk_id_sponsor > 0) {
                              if (g.fk_id_sponsor == userl.id) {
                                Setacselect(g);
                              } else {
                                toggleAlert(
                                  true,
                                  language.USER_IS_NOT_RESPONSIBLE_REGISTERED_IN_THE_TASK,
                                  'error',
                                );
                              }
                            } else {
                              Setacselect(g);
                            }
                          } else {
                            toggleAlert(
                              true,
                              language.GMUD_STATUS_DOES_NOT_ALLOW_RUNNING_TASKS,
                              'error',
                            );
                          }
                        }}
                      />
                    ),

                  execTec: g.nameexecuser || g.user_progress,
                  time_consumed:
                    g.cancelled == true
                      ? moment(g.dt_up).format('DD/MM/YYYY HH:mm')
                      : g.progress == true
                        ? moment(g.dt_progress).format('DD/MM/YYYY HH:mm')
                        : '',
                  dt_execution:
                    g.cancelled == true
                      ? moment(g.dt_up).format('DD/MM/YYYY HH:mm')
                      : g.time_spent != null
                        ? moment(g.dt_up).format('DD/MM/YYYY HH:mm')
                        : '',
                  Anexos_:
                    g.files &&
                    g.files.map((h) => (
                      <Grid item xs={6}>
                        <AttachFile
                          onClick={() =>
                            donwFileAc_(h.id_change_activity_file, h.name, '')
                          }
                          label={h.name}
                          type="image/png"
                        />
                      </Grid>
                    )),
                  status:
                    g.time_spent != null ? (
                      g.fail == true ? (
                        <i class="fas fa-times "></i>
                      ) : (
                        <i class="fas fa-check"></i>
                      )
                    ) : (
                      ''
                    ),
                  ac:
                    g.time_spent != null || g.cancelled == true ? (
                      <i
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          SetmodalDetail(true);
                          Setacselect(g);
                          setCancel(false);
                          setTypeEv(0);
                        }}
                        class="fas fa-eye"
                      ></i>
                    ) : g.progress == true ? (
                      <i
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          SetModalAc(true);
                          Setacselect(g);
                          setCancel(false);
                          setTypeEv(0);
                        }}
                        class="fas fa-eye"
                      ></i>
                    ) : null,
                  priority: g.priority,
                }))
              }
            />
          </div>
        </Grid>
        {/*------------------------------------LINHA 1 - COLUNA 2 --------------------------------------------------------------*/}
      </Grid>

      <Grid container spacing={16} className="mt-3">
        <Grid item xs={6}>
          <h3 className="title">Tickets</h3>
          <div className="_table">
            <CommonTable
              paper={false}
              mini
              bar={false}
              pagination={false}
              orderColumn={false}
              col={[
                {
                  key: 'id',
                  label: 'Ticket',
                  thConfig: true,
                  style: { width: '23.75%' },
                },
                {
                  key: 'subject',
                  label: language.SUBJECT,
                  thConfig: true,
                  style: { width: '23.75%' },
                },
              ]}
              data={
                change.tickets_ &&
                change.tickets_.map((h) => ({
                  id: h.id_tickets,
                  subject: h.subject,
                }))
              }
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          <h3 className="title">{language.RISKS}</h3>
          <div className="_table">
            <CommonTable
              paper={false}
              mini
              bar={false}
              pagination={false}
              orderColumn={false}
              col={[
                {
                  key: 'order',
                  label: language.ORDER,
                  thConfig: true,
                  style: { width: '10%' },
                },
                {
                  key: 'name',
                  label: language.RISK,
                  thConfig: true,
                  style: { width: '70%' },
                },
                {
                  key: 'answer',
                  label: language.ANSWER,
                  thConfig: true,
                  style: { width: '20%' },
                },
              ]}
              data={change_risks.map((h) => {
                return {
                  order: h.order,
                  name: h.name,
                  answer: YesOrNot[h.answer],
                };
              })}
            />
          </div>
        </Grid>
      </Grid>
      <hr />
      <Grid container spacing={16} className="mt-3">
        <Grid item xs={6}>
          <h3 className="title">{language.OUTAGES}</h3>
          <div className="_table">
            <CommonTable
              paper={false}
              mini
              bar={false}
              pagination={false}
              orderColumn={false}
              col={[
                {
                  key: 'id_outage_ic',
                  label: language.OUTAGE_ID,
                  thConfig: true,
                  style: { width: '23.75%' },
                },
                {
                  key: 'ic',
                  label: 'IC',
                  thConfig: true,
                  style: { width: '23.75%' },
                },
                {
                  key: 'start_date',
                  label: language.START_DATE,
                  thConfig: true,
                  style: { width: '23.75%' },
                },
                {
                  key: 'end_date',
                  label: language.END_DATE,
                  thConfig: true,
                  style: { width: '23.75%' },
                },
              ]}
              data={
                change.outages &&
                change.outages.map((outage) => ({
                  id_outage_ic: outage.id_outage_ic,
                  ic: outage.ic,
                  start_date: moment.utc(outage.start_date).format(
                    'DD/MM/YYYY HH:mm',
                  ),
                  end_date: moment.utc(outage.end_date).format('DD/MM/YYYY HH:mm'),
                }))
              }
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          <h3 className="title">CAB</h3>
          <div className="_table">
            <CommonTable
              paper={false}
              mini
              bar={false}
              pagination={false}
              orderColumn={false}
              col={[
                {
                  key: 'event',
                  label: language.EVENT,
                  thConfig: true,
                  style: { width: '15%' },
                },
                {
                  key: 'group_users',
                  label: language.GROUP,
                  thConfig: true,
                  style: { width: '25%' },
                },
                {
                  key: 'date',
                  label: language.DATE,
                  thConfig: true,
                  style: { width: '15%' },
                },
                {
                  key: 'approved',
                  label: language.APPROVED,
                  thConfig: true,
                  style: { width: '15%' },
                },
                {
                  key: 'rejected',
                  label: language.REJECTED,
                  thConfig: true,
                  style: { width: '15%' },
                },
                {
                  key: 'record',
                  label: language.REASON,
                  thConfig: true,
                  style: { width: '25%' },
                },
              ]}
              data={
                change.cabs &&
                change.cabs.map((cab) => {
                  return {
                    event: cab.event,
                    group_users: cab.group_users,
                    date: moment(cab.date).format('DD/MM/YYYY HH:mm'),
                    approved: cab.approved ? language.YES : language.NO,
                    rejected: cab.rejected ? language.YES : language.NO,
                    record: cab.record
                      ? cab.record.replace(/<\/?[^>]+(>|$)/g, '')
                      : '',
                  };
                })
              }
            />
          </div>
        </Grid>
      </Grid>

      <hr />
      {/*------------------------------------LINHA 2 --------------------------------------------------------------*/}
      <h3 className="title">{language.APPROVAL_HISTORY}</h3>
      <Grid container spacing={16} className="mt-3">
        <Grid item xs={12}>
          <div className="_table">
            <CommonTable
              paper={false}
              mini
              bar={false}
              pagination={false}
              orderColumn={false}
              col={[
                {
                  key: 'dt_register',
                  label: language.DATE_REGISTER,
                  thConfig: true,
                  style: { width: '23.75%' },
                },
                {
                  key: 'tech',
                  label: language.APPROVER,
                  thConfig: true,
                  style: { width: '23.75%' },
                },
                {
                  key: 'user_exec',
                  label: language.PERFORMED,
                  thConfig: true,
                  style: { width: '23.75%' },
                },
                {
                  key: 'status',
                  label: language.STATE,
                  thConfig: true,
                  style: { width: '23.75%' },
                },
                {
                  key: 'dt_aprovacao',
                  label: language.APPROVAL_DATE,
                  thConfig: true,
                  style: { width: '23.75%' },
                },
                {
                  key: 'reason',
                  label: language.JUSTIFICATION,
                  thConfig: true,
                  style: { width: '10.75%' },
                },
                {
                  key: 'approve',
                  label: language.APPROVE,
                  thConfig: true,
                  style: { width: '10.75%' },
                },
                {
                  key: 'reject',
                  label: language.REJECT,
                  thConfig: true,
                  style: { width: '10.75%' },
                },
              ]}
              data={[
                ...((change.revision && change.revision) || []),
                ...((change.revision && change.revision_hist) || []),
              ]

                .map((h) => ({
                  dt_register: moment(h.dt_cad).format('DD/MM/YYYY HH:mm'),
                  tech: h.group || h.name,
                  user_exec: h.user_exec,
                  status:
                    h.dt_up == null ? (
                      language.PENDING
                    ) : h.status == true ? (
                      <i class="fas fa-check"></i>
                    ) : (
                      <i class="fas fa-times"></i>
                    ),
                  dt_aprovacao:
                    h.dt_up == null
                      ? language.PENDING
                      : moment(h.dt_up).format('DD/MM/YYYY HH:mm'),
                  reason: (
                    <i
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        SetLog(h.log);
                        SetModal(true);
                      }}
                      class="fas fa-eye"
                    ></i>
                  ),
                  approve:
                    h.dt_up == null ? (
                      <i
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (preventActionsDueGmudFreeze) {
                            Store.toggleAlert(
                              true,
                              language.ACTION_NOT_ALLOWED_GMUD_FROZEN,
                              'error',
                            );
                            return false;
                          }
                          setModalApprove(true);
                          setLineApprove(h);
                        }}
                        class="fas fa-save"
                      ></i>
                    ) : null,
                  reject:
                    h.dt_up == null ? (
                      <i
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (preventActionsDueGmudFreeze) {
                            Store.toggleAlert(
                              true,
                              language.ACTION_NOT_ALLOWED_GMUD_FROZEN,
                              'error',
                            );
                            return false;
                          }
                          setModalReject(true);
                          setLineApprove(h);
                        }}
                        class="fas fa-trash-alt"
                      ></i>
                    ) : null,
                }))
                .concat()}
            />
          </div>
        </Grid>
      </Grid>

      <br></br>

      <h3 className="title">{language.HISTORY_BACK_TO_EDITION}</h3>
      <Grid container spacing={16} className="mt-3">
        <Grid item xs={12}>
          <div className="_table">
            <CommonTable
              paper={false}
              mini
              bar={false}
              pagination={false}
              orderColumn={false}
              col={[
                {
                  key: 'data',
                  label: language.DATE,
                  thConfig: true,
                  style: { width: '23.75%' },
                },
                {
                  key: 'name',
                  label: language.USER,
                  thConfig: true,
                  style: { width: '23.75%' },
                },
                {
                  key: 'reason',
                  label: language.JUSTIFICATION,
                  thConfig: true,
                  style: { width: '10.75%' },
                },
              ]}
              data={
                change.historic &&
                change.historic.filter((h) => h.action === "Correct").length > 0 &&
                change.historic.filter((h) => h.action === "Correct").map((h) => ({
                  data: moment(h.dt_cad).format('DD/MM/YYYY - HH:mm:ss'),
                  name: h.name_user !== "undefined" ? h.name_user : null,
                  reason: (
                    <i
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        SetLog(
                          h.justification.includes('Editou anexos') 
                          ? h.justification.replace('Editou anexos', language.ATTACHED_EDIT) 
                          : h.justification
                        );
                        SetModal(true);
                      }}
                      class="fas fa-eye"
                    ></i>
                  ),
                }))
              }
            />
          </div>
        </Grid>
      </Grid>

      <br></br>

      <Grid container spacing={16} className="mt-3">
        <Grid item xs={6}>
          <h3 className="title">
            {language.HISTORY_OF_REJECTIONS_AND_CANCELLATIONS}
          </h3>
          <div className="_table">
            <CommonTable
              paper={false}
              mini
              bar={false}
              pagination={false}
              orderColumn={false}
              col={[
                {
                  key: 'data',
                  label: language.DATE,
                  thConfig: true,
                  style: { width: '23.75%' },
                },
                {
                  key: 'name',
                  label: language.USER,
                  thConfig: true,
                  style: { width: '23.75%' },
                },
                {
                  key: 'action',
                  label: language.ACTION,
                  thConfig: true,
                  style: { width: '23.75%' },
                },
                {
                  key: 'status',
                  label: language.STATE,
                  thConfig: true,
                  style: { width: '23.75%' },
                },
                {
                  key: 'reason',
                  label: language.JUSTIFICATION,
                  thConfig: true,
                  style: { width: '10.75%' },
                },
              ]}
              data={
                change.historic &&
                change.historic.filter((h) => h.action !== "Correct").length > 0 &&
                change.historic.filter((h) => h.action !== "Correct").map((h) => ({
                  data: moment(h.dt_cad).format('DD/MM/YYYY - HH:mm:ss'),
                  name: h.name_user !== "undefined" ? h.name_user : null,
                  action:
                    h.action == 'Reject'
                      ? language.REJECTED_THE_GMUD
                      : h.justification !== "undefined" ? h.justification : null,
                  status: RejectStatus[h.action],
                  reason: (
                    <i
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        SetLog(h.justification);
                        SetModal(true);
                      }}
                      class="fas fa-eye"
                    ></i>
                  ),
                }))
              }
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <h3 className="title">{language.FORM_FIELDS}</h3>
          <div className="_table">
            <CommonTable
              paper={false}
              mini
              bar={false}
              pagination={false}
              orderColumn={false}
              col={[
                {
                  key: 'field',
                  label: language.FIELD,
                  thConfig: true,
                  style: { width: '50%' },
                },
                {
                  key: 'value',
                  label: language.VALUE,
                  thConfig: true,
                  style: { width: '50%' },
                },
              ]}
              data={
                change.html_values && formshtmlLabel(html, change.html_values)
              }
            />
          </div>
        </Grid>
      </Grid>

      {/*------------------------------------LINHA 3--- --------------------------------------------------------------*/}
      <br></br>
      <h3 className="title">{language.GMUD_INFORMATION}</h3>
      <Grid container spacing={16} className="mt-3">
        {/*------------------------------------LINHA 3 - COLUNA 1 --------------------------------------------------------------*/}
        <Grid item xs={4}>
          <GC
            items={[
              {
                label: language.TYPE_ACTION,
                value: findOption('type_action', 'actionsOptions'),
              },
              {
                label: language.COMPANY,
                value: change.company,
              },
              {
                label: language.CONTRACT,
                value: change.name_contract,
              },
              {
                label: language.INVOLVED_AREAS,
                value: (
                  <Scroll>
                    {change.techgroups_labels &&
                      change.techgroups_labels.map((g) => <ul>{g}</ul>)}
                  </Scroll>
                ),
              },
              {
                label: language.DEVICES,
                value: (
                  <Scroll>
                    {change.devices_labels &&
                      change.devices_labels.map((g) => <ul>{g}</ul>)}
                  </Scroll>
                ),
              },
              {
                label: language.REQUESTER,
                value: (
                  <div style={{ height: 126 }}>
                    <Scroll>{change.solicitants && change.solicitants}</Scroll>
                  </div>
                ),
              },
            ]}
          />
        </Grid>
        {/*------------------------------------LINHA 3 - COLUNA 2 --------------------------------------------------------------*/}
        <Grid item xs={4}>
          <GC
            items={[
              {
                label: language.START_DATE_AND_TIME,
                value: moment.utc(change.start_date).format(
                  'DD/MM/YYYY - HH:mm:ss',
                ),
              },
              {
                label: language.END_DATE_AND_TIME,
                value: moment.utc(change.end_date).format('DD/MM/YYYY - HH:mm:ss'),
              },

              {
                label: language.INVOLVED_TECH,
                //techs_labels
                value: (
                  <div style={{ height: 126 }}>
                    <Scroll>
                      {change.techs_labels &&
                        change.techs_labels.map((g) => <ul>{g}</ul>)}
                    </Scroll>
                  </div>
                ),
              },
            ]}
          />
          <GC
            items={[
              {
                label: language.ATTACHMENTS,
                valueProps: { className: 'pr-0' },
                value: (
                  <PerfectScrollbar
                    className="scrollVisible"
                    style={{
                      height: 131,
                    }}
                    option={{
                      suppressScrollX: true,
                    }}
                  >
                    <Grid
                      container
                      spacing={16}
                      style={{ zoom: 0.7, fontWeight: 500 }}
                    >
                      {(change.file || change.file_cancellation) &&
                        [
                          ...(change.file || []),
                          ...(change.file_cancellation || []),
                        ].map((cF, i) => (
                          <Grid item xs={6} key={i}>
                            <AttachFile
                              label={cF.file}
                              onClick={() => {
                                const isFileCancellation =
                                  change.file_cancellation?.includes(cF);
                                const idFile = isFileCancellation
                                  ? cF.id_change_cancellation_file
                                  : cF.id_change_file;
                                onDownFile(
                                  `${APIEndpoints.CHANGE}/${isFileCancellation ? 'downloadcancellation' : 'downloadchange'}/${idFile}&${cF.file}`,
                                  cF.file,
                                );
                              }}
                              style={{ maxWidth: '100%' }}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </PerfectScrollbar>
                ),
              },
            ]}
          />
        </Grid>
        {/*------------------------------------LINHA 3 - COLUNA 3 --------------------------------------------------------------*/}
        <Grid item xs={4}>
          <GC
            items={[
              {
                label: language.INTEGRATION,
                valueProps: { className: 'pr-0' },
                value: change.id_integration,
              },
              {
                label: language.CORRELATION,
                valueProps: { className: 'pr-0' },
                value: change.correlation_id,
              },
              {
                label: language.CORRELATION_DISPLAY,
                valueProps: { className: 'pr-0' },
                value: change.correlation_display,
              },
              {
                label: language.REASON,
                valueProps: { className: 'pr-0' },
                value: (
                  <PerfectScrollbar
                    className="scrollVisible"
                    style={{
                      height: 176,
                    }}
                    option={{
                      suppressScrollX: true,
                    }}
                  >
                    <p dangerouslySetInnerHTML={{ __html: change.reason }} />
                  </PerfectScrollbar>
                ),
              },
            ]}
          />
          <GC
            items={[
              {
                label: language.DESCRIPTION,
                valueProps: { className: 'pr-0' },
                value: (
                  <PerfectScrollbar
                    className="scrollVisible"
                    style={{
                      height: 131,
                    }}
                    option={{
                      suppressScrollX: true,
                    }}
                  >
                    <p dangerouslySetInnerHTML={{ __html: change.benefits }} />
                  </PerfectScrollbar>
                ),
              },
            ]}
          />
        </Grid>
      </Grid>

      <Dialog
        open={modal}
        onClose={() => SetModal(false)}
        contentStyle={{ height: '80%', maxWidth: 'none' }}
        fullWidth={true}
        maxWidth="lg"
        autoScrollBodyContent={true}
        title={''}
      >
        <div className="container" style={{ padding: '20px 0' }}>
          <p dangerouslySetInnerHTML={{ __html: log }}></p>
        </div>
      </Dialog>

      <Dialog
        open={modalDetailDesc}
        onClose={() => {
          Setacselect('');
          SetmodalDetailDesc(false), setModalProgress(false);
        }}
        contentStyle={{ height: '80%', maxWidth: 'none' }}
        fullWidth={true}
        maxWidth="lg"
        autoScrollBodyContent={true}
        title={language.DESCRIPTION}
      >
        <div className="container" style={{ padding: '20px 0' }}>
          <p dangerouslySetInnerHTML={{ __html: acselect.description }} />
        </div>
      </Dialog>

      <Dialog
        open={modalDetail & !modalAc}
        onClose={() => {
          SetmodalDetail(false);
          setModalProgress(false);
        }}
        contentStyle={{ height: '80%', maxWidth: 'none' }}
        fullWidth={true}
        maxWidth="lg"
        autoScrollBodyContent={true}
        title={language.EVIDENCE}
      >
        <div className="container" style={{ padding: '20px 0' }}>
          <p dangerouslySetInnerHTML={{ __html: acselect.evidence }} />
        </div>
      </Dialog>

      <Dialog
        open={modalApprove}
        onClose={() => {
          setModalApprove(false);
        }}
        contentStyle={{ height: '80%', maxWidth: 'none' }}
        fullWidth={true}
        maxWidth="lg"
        autoScrollBodyContent={true}
        title={language.APPROVE}
      >
        <CommonForm
          fields={[
            {
              name: 'reason',
              col: 12,
              type: 'textEditor',
              label: language.JUSTIFY,
            },
          ]}
          onSubmit={(values) => {
            changeStatus(
              'approve',
              {
                ...change,
                approved: true,
                fk_id_change: change.id_change,
                log: values.reason,
              },
              false,
              {
                type_approve: lineApprove.name ? 'user' : 'group',
                id_approve:
                  lineApprove.fk_id_user ?? lineApprove.fk_id_group_users,
              },
            );
            setModalApprove(false);
          }}
        />
      </Dialog>
      <Dialog
        open={modalReject}
        onClose={() => {
          setModalReject(false);
        }}
        contentStyle={{ height: '80%', maxWidth: 'none' }}
        fullWidth={true}
        maxWidth="lg"
        autoScrollBodyContent={true}
        title={language.REJECT}
      >
        <CommonForm
          fields={[
            {
              name: 'reason',
              col: 12,
              type: 'textEditor',
              label: language.JUSTIFY,
            },
          ]}
          onSubmit={(values) => {
            changeStatus(
              'reject',
              {
                ...change,
                approved: false,
                fk_id_change: change.id_change,
                log: values.reason,
              },
              false,
              {
                type_approve: lineApprove.name ? 'user' : 'group',
                id_approve:
                  lineApprove.fk_id_user ?? lineApprove.fk_id_group_users,
              },
            );
            setModalReject(false);
          }}
        />
      </Dialog>
      <DialogConfirm
        open={modalProgress && !modalAc && !modalDetail && !modalDetailDesc}
        maxWidth="xs"
        onClose={() => {
          setModalProgress(false);
          setIdActivitySelected(acselect.id_change_activity);
          Setacselect('');
        }}
        title={language.ACTIVITY_IN_GMUD}
        message={language.DO_YOU_WANT_TO_PUT_ACTIVITY_IN_PROGRESS}
        confirmLabel={language.YES}
        cancelLabel={language.NO}
        gmud={true}
        gmudlabel={language.CANCEL_ACTIVITY}
        cancelProps={{
          color: 'primary',
        }}
        CancelP={{
          color: 'danger',
        }}
        eventClose
        onConfirm={() => {
          Progress_();
          setModalProgress(false);
        }}
        onConfirmCancel={() => {
          setModalProgress(false);
          SetModalAc(true);
          setCancel(true);
          setTypeEv(3);
        }}
        onCancel={() => {
          setModalProgress(false);
        }}
      />

      <Dialog
        open={modalAc}
        onClose={() => {
          setModalProgress(false);
          SetModalAc(false);
        }}
        contentStyle={{ height: '60%', maxWidth: 'none' }}
        fullWidth={true}
        maxWidth="xl"
        autoScrollBodyContent={true}
        title={''}
      >
        {' '}
        <div style={{ marginTop: 15 }}>
          {generalParams.azure_devops == true ? (
            <CommonForm
              hiddenSubmit
              onChangeField={(f, v) => {}}
              fields={[
                {
                  col: 12,
                  type: 'select',
                  name: 'integraion',
                  label: language.TYPE_OF_ACTIVITY,
                  options: [
                    { label: 'default', value: 'default' },
                    { label: 'azure', value: 'azure' },
                  ],
                },
              ]}
            />
          ) : null}

          {integration == 'azure' ? (
            <CommonForm
              hiddenSubmit
              fields={[
                {
                  col: 12,
                  type: 'select',
                  name: 'organization',
                  label: language.ORGANIZATION,
                  options: [],
                },
                {
                  col: 12,
                  type: 'select',
                  name: 'project',
                  label: language.PROJECTS,
                  options: [],
                },
                {
                  col: 12,
                  type: 'select',
                  name: 'branch',
                  label: language.PROJECTS,
                  options: [],
                },
                {
                  col: 12,
                  type: 'select',
                  name: 'pipiline',
                  label: language.PROJECTS,
                  options: [],
                },
                {
                  col: 4,
                  type: 'element',
                  content: (v, h, submit) => (
                    <Button
                      onClick={() => submit()}
                      icon="fa fa-save"
                      variant="normal"
                      label={language.SAVE}
                      color="success"
                      style={{ marginTop: 15 }}
                    />
                  ),
                },
              ]}
              onSubmit={(v) => null}
            />
          ) : null}
          {integration == 'default' ? (
            <div>
              {acselect.html ? (
                <p
                  style={{
                    color: '#888080',
                    marginBottom: '5px',
                  }}
                  id={'formatasktemplate'}
                >
                  {formshtml(decryp(acselect.html))} <br></br>{' '}
                </p>
              ) : (
                ''
              )}
              <CommonForm
                hiddenSubmit
                onSubmit={(g) => onFormSubmitAc_(g)}
                onChangeField={(f, v) => {
                  if (f.name == 'executed') {
                    setTypeEv(v);
                  }
                }}
                fields={[
                  {
                    col: 6,
                    type: 'select',
                    name: 'executed',
                    label: language.EXECUTED,
                    options: [
                      { value: 1, label: language.FAILURE },
                      { value: 2, label: language.SUCCESS },
                      { value: 3, label: language.CANCEL },
                    ],
                    visible: cancel == true ? false : true,
                  },
                  {
                    col: 6,
                    type: 'time',
                    name: 'time_spent',
                    label: language.TIME_CONSUMED,
                    visible: TypeEv == 3 ? false : true,
                  },
                  {
                    span: 12,
                    name: 'evidence',
                    label: language.EVIDENCE,
                    type: 'textEditor',
                  },
                  {
                    col: 4,
                    type: 'element',
                    visible: TypeEv == 3 ? false : true,
                  },
                  {
                    col: 4,
                    name: 'files',
                    label: language.ATTACHMENT,
                    type: 'file',
                    visible: TypeEv == 3 ? false : true,
                  },
                  {
                    col: 12,
                    type: 'element',
                    content: (v, h, submit) => (
                      <div style={{ textAlign: 'right' }}>
                        <Button
                          onClick={() => submit()}
                          icon="fa fa-save"
                          variant="normal"
                          label={language.SAVE}
                          color="success"
                          style={{ marginTop: 15 }}
                        />
                      </div>
                    ),
                  },
                ]}
              />{' '}
            </div>
          ) : null}
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </Dialog>
    </div>
  );
}

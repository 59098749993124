import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CommonForm from '@common/CommonForm2';
import CommonTable from '@common/CommonTable';
import { Crad, Chip } from '@material-ui/core';
import Dialog from '@common/Dialog';
import RaisedButton from '@common/RaisedButton';
import CommonPage from '../../common/CommonPage';
import { validaPass } from '../../util/ValidePassword';
import Button from '@common/Button';
import ContractUser_ from './ContractUser';
import CompanyUser from './CompanyUser';
import GroupUser from './GroupUser';
import PasswordRequirements from './components/PasswordRequirements';
import { CSVLink } from 'react-csv';
import * as SensritUtilsPk from '@sensrit/utils';
import { EMAIL_REGEX } from '../../util/regex';
import Store from '@data/Store';
import { translateFromPtBR } from '@common/languages/Dictionary';

var id_company_selected = 0;
var editvalor = [];

const formatSelectOptions = (options, attributeName, attributeValue) => {
  const normalizedOptions = options.map((option) => ({
    value: option[attributeValue],
    label: option[attributeName],
  }));

  return normalizedOptions;
};

@observer
export default class GeneralUserList extends CommonPage {
  constructor() {
    super();
    this.state = {
      company: '',
      open: false,
      valuesEdit: '',
      values: {},
      role: '',
      reset: false,
      user: [],
      openContract: false,
      id_city: 0,
      id_state: 0,
      openCompany: false,
      openGroup: false,
      companies: [],
      departmentOptions: [],
      occupations: [],
      arrayStatus: [],
      locationsOptions: [],
      use_service_portal_only_disabled: true,
      use_service_portal_only: false,
      fk_id_perfil_disabled: false,
      password: '',
    };
    this.onStatusChange = this.onStatusChange.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.handlePages = this.handlePages.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.openpassword = this.openpassword.bind(this);
    this.sendPassword = this.sendPassword.bind(this);
    this.closeContract = this.closeContract.bind(this);
    this.DepartmentList = this.DepartmentList.bind(this);
  }

  changeCompany = (evt) => {
    this.setState({ company: evt.target.value });
  };

  componentWillMount() {
    this.action.execute('get', this.APIEndpoints.COMPANY, 'companies');
    this.action.execute(
      'get',
      this.APIRoot + '/api/generalparameters',
      'general_parameters'
    );
  }

  openpassword(values) {
    /** app.post("/redefine", (req, res, next) =>
    RedefinePassword(req.db, req.body) */

    this.setState({ reset: true, user: values });
  }
  sendPassword(values) {
    console.log(this.store.getUserLoged());
    let data = {
      id_requester: this.store.getUserLoged().id,
      id_user: this.state.user.id_user,
      new_pass: values.password,
      notpass: true,
      password_change_required: values.password_change_required,
    };

    this.action.execute(
      'post',
      this.APIRoot + '/redefine',
      '',
      data
    ).then((result) => {
      this.store.toggleAlert(
        true,
        this.store.language.CHANGE_PASSWORD_SUCCESS,
        'success'
      );
      this.setState({ reset: false });
    }, (error) => {
      console.log(error);
      this.store.toggleAlert(
        true,
        this.store.language.ERROR_PASSWORD_NOT_ALLOWED,
        'error'
      );
    });

  }

  closeContract() {
    this.setState({ openContract: false, openCompany: false });
  }

  DepartmentList(id) {
    this.action
      .execute('get', this.APIEndpoints.DEPARTMENT + '/' + id, '')
      .then((v) => {
        let listDepartments = v.data.map((c) => ({
          value: parseInt(c.id_department),
          label: c.name,
        }));
        this.setState({ departmentOptions: listDepartments });
      });
  }
  LocationList(idCompany) {
    this.action
      .execute('get', this.APIEndpoints.LOCATION + '/company/' + idCompany, '')
      .then((list) => {
        let listLocations = list.data.map((v) => ({
          value: parseInt(v.id_rel_company_location),
          label: v.name,
        }));
        this.setState({ locationsOptions: listLocations });
      });
  }

  GetTimezones() {
    this.action
      .execute('get', this.APIEndpoints.TIMEZONE, '')
      .then((response) => {
        const { data } = response;
        this.setState({
          timezoneOptions: formatSelectOptions(data, 'name', 'id_timezone'),
        });
      });
  }

  onChangeField(field, value, selected, handleValue) {
    if (field.name === 'password') {
      this.setState({ password: value });
    } else if (field.name === 'confirmation') {
      this.setState({ confirmPassword: value });
    } else if (field.name === 'alternative_email') {
      this.setState({ alternative_email: value });
    } else if (field.name === 'id_country') {
      this.action.execute(
        'get',
        this.APIEndpoints.COUNTRY + '/' + value,
        'states'
      );
      this.store.cities = [];
    } else if (field.name === 'enable_ticket_listing_for_the_department') {
      if (value == true) {
        handleValue(false, 'enable_ticket_listing_for_associated_companies');

        editvalor &&
          (editvalor.enable_ticket_listing_for_associated_companies = false);

        this.setState((prev) => ({
          values: {
            ...prev.values,
            enable_ticket_listing_for_associated_companies: false,
          },
        }));
      }
    } else if (
      field.name === 'enable_ticket_listing_for_associated_companies'
    ) {
      if (value == true) {
        handleValue(false, 'enable_ticket_listing_for_the_department');

        editvalor &&
          (editvalor.enable_ticket_listing_for_the_department = false);

        this.setState((prev) => ({
          values: {
            ...prev.values,
            enable_ticket_listing_for_the_department: false,
          },
        }));
      }
    } else if (field.name === 'id_state') {
      this.action.execute(
        'get',
        this.APIEndpoints.STATE + '/' + value,
        'cities'
      );
    } else if (field.name === 'role') {
      const isSolicitant = value === 'solicitant';
      try {
        editvalor.role = value;
        if (!isSolicitant) editvalor.use_service_portal_only = false;
      } catch (e) {}

      this.setState({
        role: value,
        fk_id_perfil_disabled:
          isSolicitant &&
          (editvalor?.use_service_portal_only ||
            this.state.use_service_portal_only),
        use_service_portal_only_disabled: !isSolicitant,
        use_service_portal_only: false,
      });
    } else if (field.name === 'type') {
      try {
        editvalor.type = value;
      } catch (e) {}
      this.setState({
        type: value,
      });
    } else if (field.name === 'id_company') {
      this.DepartmentList(value);

      try {
        editvalor.id_company = value;
      } catch (e) {}
      this.setState({
        company: value,
      });

      id_company_selected = value;

      if (this.props.role == 'sponsor') {
        let filterCompayTer = companiesOptions.filter(
          (co) => co.value == value
        );

        if (filterCompayTer[0].type == 2) {
          this.setState({ removeDepart: true });
        } else {
          this.setState({ removeDepart: false });
        }
      }
      this.LocationList(value);
    } else if (field.name === 'use_service_portal_only') {
      if (value) {
        editvalor && (editvalor.fk_id_perfil = null);
      }

      editvalor && (editvalor.use_service_portal_only = value);

      this.setState((prev) => ({
        fk_id_perfil_disabled: !!value,
        use_service_portal_only: value,
        values: {
          ...prev.values,
          fk_id_perfil: value ? null : prev.values.fk_id_perfil,
        },
      }));
    } else if (field.name === 'fk_id_perfil') {
      editvalor && (editvalor.fk_id_perfil = value);
      this.setState((prev) => ({
        values: {
          ...prev.values,
          fk_id_perfil: value,
        },
      }));
    }
  }

  onStatusChange(toChange) {
    let active = !toChange.status;
    this.props.onFormSubmit({ id_user: toChange.id_user, active: active });

    let itens = this.state.arrayStatus;

    if (itens[toChange.id_user]) {
      itens[toChange.id_user] = active;
    } else {
      itens = { [toChange.id_user]: active };
    }

    // itens.push({id_user:toChange.id_user,  active:active})
    this.setState({ arrayStatus: itens });
  }

  toggleModal = (open) => {
    if (this.store.countries.length == 0) {
      this.action.execute('get', this.APIEndpoints.COUNTRY, 'countries');
    }
    this.action.execute(
      'get',
      this.APIEndpoints.USER + '/userchange/' + this.store.getUserLoged().id,
      'ad'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.PERFIL + `getperfils`,
      'perfil'
    );
    this.GetTimezones();
    this.action.execute(
      'get',
      this.APIRoot + '/api/generalparameters',
      'general_parameters'
    );
    if (this.state.occupations.length == 0) {
      this.action
        .execute('get', this.APIEndpoints.OCCUPATION, '')
        .then((occupation) => {
          this.setState({ occupations: occupation.data });
        });
    }
    if (this.state.companies.length == 0) {
      this.action.execute('get', this.APIEndpoints.COMPANY, '').then((item) => {
        this.setState({
          companies: item.data,
        });
      });
    }

    this.props.toggleModal(open);
    this.setState({ valuesEdit: '', reset: false });
    editvalor = [];
  };

  async handlePages(page, edit) {
    const user = await this.action.execute('get', this.APIEndpoints.USER + '/' + edit.id_user, 'ad');
    edit = user.data;

    editvalor = edit;
    this.props.toggleModal(true);
    this.onChangeField({ name: 'id_country' }, edit && edit.id_country);
    this.onChangeField({ name: 'id_state' }, edit && edit.id_state);
    this.onChangeField({ name: 'id_company' }, edit && edit.id_company);
    if (this.store.countries.length == 0) {
      this.action.execute('get', this.APIEndpoints.COUNTRY, 'countries');
    }
    this.action.execute(
      'get',
      this.APIEndpoints.USER + '/userchange/' + this.store.getUserLoged().id,
      'ad'
    );
    this.action.execute(
      'get',
      this.APIEndpoints.PERFIL + `getperfils`,
      'perfil'
    );
    this.GetTimezones();
    this.action.execute(
      'get',
      this.APIRoot + '/api/generalparameters',
      'general_parameters'
    );
    if (this.state.occupations.length == 0) {
      this.action
        .execute('get', this.APIEndpoints.OCCUPATION, '')
        .then((occupation) => {
          this.setState({ occupations: occupation.data });
        });
    }
    if (this.state.companies.length == 0) {
      this.action.execute('get', this.APIEndpoints.COMPANY, '').then((item) => {
        this.setState({
          companies: item.data,
        });
      });
    }

    this.DepartmentList(editvalor.id_company);

    this.LocationList(editvalor.id_company);

    this.setState({
      valuesEdit: edit,
      role: editvalor.role,
      use_service_portal_only_disabled: edit.role !== 'solicitant',
    });
  }

  onFormSubmit(values) {
    if (values.fk_id_sponsor === 0) {
      values.fk_id_sponsor = null;
    }
    if (values.fk_id_alternative_sponsor === 0) {
      values.fk_id_alternative_sponsor = null;
    }

    values.use_service_portal_only = this.state.use_service_portal_only;

    this.props.onFormSubmit(values);
    this.toggleModal(false);
  }

  getOccupationName(id) {
    let occupation = this.state.occupations.filter(
      (occ) => occ.id_occupation == id
    )[0];
    if (occupation) {
      return occupation.name;
    } else return '';
  }

  render() {
    let {
      language,
      onFormSubmit,
      departments,
      occupations,
      values,
      handlePage,
      redirect,
      GeneralUserListModal,
      perfil,
      countries,
      APIEndpoints,
      email,
      callPagination,
      callDataFilter,
      callPageSelectFilter,
      downloadUsers,
      countTotal,
      cleanFilters,
      sortBy,
    } = this.props;
    let { states, cities, companies } = this.store;
    let occupationOptions = this.state.occupations.map((occupation) => ({
      value: occupation.id_occupation,
      label: occupation.name,
    }));
    let { departmentOptions, locationsOptions, timezoneOptions, password } =
      this.state;

    let perfilList = [];
    perfilList.push({
      value: 0,
      label: language.NONE,
    });
    perfil.map((obj) =>
      perfilList.push({
        value: obj.perfil_id,
        label: obj.name,
      })
    );

    let typeOptions = [
      { value: 1, label: language.CLIENT },
      { value: 2, label: language.INTERNAL },
      { value: 3, label: language.OUTSOURCED },
    ];

    let { role } = this.state;
    let csvData = [];

    let company =
      editvalor.id_company != undefined
        ? editvalor.id_company
        : id_company_selected;
    let type = values ? values.type : this.state.type;
    let companiesOptions = companies.map((obj) => ({
      value: obj.id_company,
      label: obj.name,
      type: obj.type,
    }));

    let countryOptions = countries.map((obj) => {
      return { value: obj.id_country, label: translateFromPtBR(obj.name, Store.language) };
    });
    let stateOptions = states.map((obj) => {
      return { value: obj.id_state, label: obj.name };
    });

    let cityOptions = cities.map((obj) => {
      return { value: obj.id_city, label: obj.name };
    });
    /**
     * foi colocado esse if pois a leitura da api de usuario GetAllUserPagination retorna maiusculo
     * E no grid a leitura correta é minuscula, não fiz a alteração na query pois impactaria outras rotinas que já utilizam maiusculo
     */
    if (editvalor.role == 'SCM') {
      editvalor.role = editvalor.role.toString().toLowerCase();
    }

    let fields = [
      {
        col: 3,
        name: 'role',
        label: language.ACCESS,
        startIcon: 'fas fa-users-cog',
        type: 'select',
        options:
          this.store.getUserLoged().role == 'client' ||
          editvalor.role == 'client'
            ? [
                { value: 'tech', label: language.TECH },
                { value: 'solicitant', label: language.SOLICITANT },
                { value: 'client', label: language.ADMIN },
                { value: 'scm', label: language.SCM },
                { value: 'solicitant', label: language.SOLICITANT },
              ]
            : this.store.getUserLoged().role == 'SCM' ||
              this.store.getUserLoged().role == 'scm' ||
              editvalor.role == 'scm' ||
              editvalor.role == 'SCM'
            ? [
                { value: 'tech', label: language.TECH },
                { value: 'solicitant', label: language.SOLICITANT },
                { value: 'scm', label: language.SCM },
              ]
            : [
                { value: 'tech', label: language.TECH },
                { value: 'solicitant', label: language.SOLICITANT },
              ],

        required: true,
        disabled:
          (editvalor.role == 'client' &&
            this.store.getUserLoged().role != 'client') ||
          (editvalor.role == 'SCM' &&
            this.store.getUserLoged().role != 'client' &&
            this.store.getUserLoged().role != 'SCM' &&
            this.store.getUserLoged().role != 'scm'),
      },
      {
        col: 3,
        name: 'name',
        label: language.NAME,
        startIcon: 'fas fa-signature',
        type: 'text',
        required: true,
      },
      {
        col: 3,
        name: 'hour_value',
        label: language.HOUR_VALUE,
        type: 'number',
      },
      {
        col: 3,
        name: 'hour_value_additional',
        label: language.HOUR_VALUE_ADDITIONAL,
        type: 'number',
      },
      {
        col: 3,
        name: 'email',
        label: language.EMAIL,
        startIcon: 'fas fa-envelope',
        type: 'text',
        required: true,
      },
      {
        col: 3,
        name: 'userad',
        label: language.AD_USER,
        type: 'text',
        required: false,
      },
      {
        col: 3,
        name: 'phone',
        label: language.PHONE,
        type: 'text',
        format: '(##) #####-####',
        placeholder: '(00)00000-0000',
        startIcon: 'fas fa-phone',
      },
      {
        col: 3,
        name: 'ramal',
        label: language.TELEPHONE_EXTENSION,
        type: 'text',
        required: false,
      },
      {
        col: 3,
        name: 'id_company',
        label: language.COMPANY,
        type: 'select',
        required: true,
        options: companiesOptions,
      },
    ];
    fields.push();

    fields.push({
      col: 3,
      name: 'registration',
      label: language.REGISTRATION,
      type: 'text',
      required: false,
    });

    if (!this.state.removeDepart) {
      fields.push({
        col: 3,
        name: 'fk_id_department',
        label: language.DEPARTMENT,
        type: 'select',
        options: departmentOptions,
      });
    } else
      fields.push({
        col: 3,
        name: 'type',
        label: language.TYPE,
        type: 'select',
        required: true,
        options: typeOptions,
        disabled: !!(values && values.type),
      });

    fields.push({
      col: 3,
      name: 'fk_id_perfil',
      label: language.PROFILE,
      type: 'select',
      required: false,
      options: perfilList,
      disabled: this.state.fk_id_perfil_disabled,
    });

    fields.push(
      {
        col: 3,
        name: 'occupation',
        label: language.POSITION,
        type: 'select',
        required: false,
        options: occupationOptions,
      },
      {
        col: 3,
        name: 'id_country',
        label: language.COUNTRY,
        type: 'select',
        startIcon: 'fas fa-flag',
        options: countryOptions,
        required: true,
        visible: !this.store.general_parameters.enabled_location_user,
      },
      {
        col: 3,
        name: 'id_state',
        startIcon: 'far fa-flag',
        label: language.DISTRICT,
        type: 'select',
        options: stateOptions,
        required: false,
        visible: !this.store.general_parameters.enabled_location_user,
      },
      {
        col: 3,
        name: 'id_city',
        startIcon: 'fas fa-city',
        label: language.CITY,
        type: 'select',
        options: cityOptions,
        required: false,
        visible: !this.store.general_parameters.enabled_location_user,
      }
    );

    fields.push({
      col: 3,
      name: 'sys_id',
      label: 'Sys Id',
      type: 'text',
      required: false,
    });

    fields.push({
      col: 3,
      type: 'autocomplete',
      name: 'fk_id_sponsor',
      label: language.DIRECT_LEADER,
      textlabel: language.DIRECT_LEADER,
      method: 'POST',
      filter: 0,
      route: `${APIEndpoints.USER}/filter`,
      routeGetCount: `${APIEndpoints.USER}/active/count/${0}`,

      fieldquery: 'search',
      /* textinit:
      (valuesEdit&&valuesEdit.sponsor)  || 'Lider Direto',
      fieldvaluedb: 'fk_id_sponsor',
      fieldlabeldb: 'name',
      idinit: (valuesEdit&&valuesEdit.fk_id_sponsor) ,*/
      textinit:
        (this.state.valuesEdit && this.state.valuesEdit.sponsor) ||
        language.DIRECT_LEADER,
      fieldvaluedb: 'id_user',
      fieldlabeldb: 'name',
      idinit: this.state.valuesEdit && this.state.valuesEdit.fk_id_sponsor,
      routeAll: `${APIEndpoints.USER}/filter/all?`,
      filterAll: 0,
      methodGetAll: 'POST',
      detail: true,
      visible: true,
      required: false,
      showEmail: true,
    });

    fields.push(
      {
        col: 3,
        type: 'autocomplete',
        name: 'fk_id_alternative_sponsor',
        label: language.ALTERNATIVE_LEADER,
        textlabel: language.ALTERNATIVE_LEADER,
        method: 'POST',
        filter: 0,
        route: `${APIEndpoints.USER}/filter`,
        routeGetCount: `${APIEndpoints.USER}/active/count/${0}`,

        fieldquery: 'search',
        textinit:
          (this.state.valuesEdit &&
            this.state.valuesEdit.alternative_sponsor) ||
          language.ALTERNATIVE_LEADER,
        fieldvaluedb: 'id_user',
        fieldlabeldb: 'name',
        idinit:
          this.state.valuesEdit &&
          this.state.valuesEdit.fk_id_alternative_sponsor,
        routeAll: `${APIEndpoints.USER}/filter/all?`,
        filterAll: 0,
        methodGetAll: 'POST',
        detail: true,
        visible: true,
        required: false,
        showEmail: true,
      },
      {
        col: 3,
        name: 'alternative_email',
        label: language.ALTERNATIVE_EMAIL,
        startIcon: 'fas fa-envelope',
        type: 'text',
        error:
          this.state.alternative_email &&
          !EMAIL_REGEX.test(this.state.alternative_email),
        // required: true,
      },
      {
        col: 3,
        name: 'fk_id_rel_company_location',
        label: language.LOCATION,
        type: 'select',
        required: this.store.general_parameters.enabled_location_user,
        options: locationsOptions,
      },
      {
        col: 3,
        name: 'id_timezone',
        label: 'Timezone',
        type: 'select',
        required: true,
        options: this.state.timezoneOptions,
      },
      {
        col: 3,
        type: 'select',
        name: 'id_language',
        label: language.LANGUAGE,
        options: [
          { value: 'pt_br', label: 'Português BR' },
          { value: 'es', label: 'Español' },
          { value: 'en', label: 'English' },
        ],
        required: true,
      }
    );

    fields.push(
      {
        col: 1,
        name: 'vip',
        label: language.VIP,
        type: 'check',
      },
      {
        col: 2,
        name: 'sendmail',
        label: language.ALLOW_SENDING_EMAIL,
        type: 'switch',
      },
      {
        col: 3,
        name: 'use_service_portal_only',
        label: language.USE_SERVICE_PORTAL_ONLY,
        type: 'switch',
        disabled: this.state.use_service_portal_only_disabled,
      },
      {
        col: 3,
        name: 'enable_ticket_listing_for_the_department',
        label: language.ENABLE_TICKET_LISTING_FOR_THE_DEPARTMENT,
        type: 'switch',
        visible: editvalor.role == 'solicitant' || editvalor.role == 'tech',
      },
      {
        col: 4,
        name: 'enable_ticket_listing_for_associated_companies',
        label: language.ENABLE_TICKET_LISTING_FOR_ASSOCIATED_COMPANIES,
        type: 'switch',
        visible: editvalor.role == 'solicitant' || editvalor.role == 'tech',
      }
    );

    let col = [
      { key: 'id_user', label: language.ID },
      { key: 'status', label: language.STATUS },
      { key: 'name', label: language.NAME },
      { key: 'email', label: language.EMAIL },
      { key: 'access', label: language.ACCESS },
      { key: 'perfil', label: language.PROFILE },
      { key: 'company', label: language.COMPANY },
      { key: 'department', label: language.DEPARTMENT },
      { key: 'sys_id', label: 'Sys Id' },
      { key: 'actions', label: language.ACTION },
    ];

    let colCSV = [
      { key: 'status', label: language.STATUS },
      { key: 'name', label: language.NAME },
      { key: 'email', label: language.EMAIL },
      { key: 'access', label: language.ACCESS },
      { key: 'perfil', label: language.PROFILE },
      { key: 'company', label: language.COMPANY },
      { key: 'department', label: language.DEPARTMENT },
      { key: 'telefone', label: language.PHONE },
    ];

    csvData = this.props.userItems.map((obj) => [
      obj.id_user,
      obj.status ? language.ACTIVE : language.INACTIVE,
      obj.name,
      obj.email,
      obj.role == 'client' ? 'Admin' : obj.role,
      obj.perfil,
      obj.company,
      obj.department,
      obj.phone,
      obj.sys_id
    ]);
    let data = this.props.userItems.map((obj) => {
      if (this.state.arrayStatus[obj.id_user] != undefined) {
        obj.status = this.state.arrayStatus[obj.id_user];
      }

      return {
        id_user: obj.id_user,
        status: (
          <Button
            variant="circle"
            icon={obj.status ? 'fa fa-check' : 'fas fa-times'}
            color={obj.status ? 'success' : 'danger'}
            style={{ fontSize: 12 }}
            size={0.7}
            className="mr-2"
            onClick={() => this.onStatusChange(obj)}
          />
        ),
        name: obj.name,
        email: obj.email,
        access: obj.role == 'client' ? 'Admin' : obj.role,
        perfil: obj.perfil,
        company: obj.company,
        department: obj.department,
        sys_id: obj.sys_id,
        actions: (
          <React.Fragment>
            <Chip
              className="mx-2 muit-chip"
              label={
                <div className="d-flex align-items-center justify-content-between">
                  <Button
                    variant="circle"
                    icon="fas fa-edit"
                    label={language.EDIT}
                    color="default"
                    style={{ fontSize: 12 }}
                    size={0.7}
                    className="mr-2"
                    onClick={() => {
                      obj.role == 'Admin'
                        ? (obj.role = 'client')
                        : obj.role == 'Técnico'
                        ? (obj.role = 'tech')
                        : obj.role == 'Solicitante'
                        ? (obj.role = 'solicitant')
                        : '';
                      this.handlePages('form', obj);
                    }}
                  />
                  <Button
                    variant="circle"
                    icon="far fa-trash-alt"
                    label={language.DELETE}
                    color="danger"
                    style={{ fontSize: 13 }}
                    size={0.7}
                    onClick={() => handlePage('remove', obj)}
                  />

                  <Button
                    variant="circle"
                    icon="fas fa-key"
                    label={language.CHANGE_PASSWORD}
                    color="default"
                    style={{
                      fontSize: 12,
                      marginLeft: '5px',
                    }}
                    size={0.7}
                    className="mr-2"
                    onClick={() => this.openpassword(obj)}
                  />
                  <Button
                    variant="circle"
                    icon="fas fa-plus"
                    label={language.ADD_CONTRACT}
                    color="primary"
                    style={{ fontSize: 13 }}
                    size={0.7}
                    onClick={() => {
                      this.setState({ valuesEdit: obj, openContract: true });
                    }}
                  />
                  <Button
                    variant="circle"
                    icon="fas fa-plus"
                    label={language.ADD_COMPANY}
                    color="primary"
                    style={{ fontSize: 13, marginLeft: 5 }}
                    size={0.7}
                    onClick={() =>
                      this.setState({ valuesEdit: obj, openCompany: true })
                    }
                  />
                  <Button
                    variant="circle"
                    icon="fas fa-plus"
                    label={language.ADD_GROUP}
                    color="primary"
                    style={{ fontSize: 13, marginLeft: 5 }}
                    size={0.7}
                    onClick={() =>
                      this.setState({ valuesEdit: obj, openGroup: true })
                    }
                  />
                </div>
              }
            />
          </React.Fragment>
        ),
      };
    });
    let { configurationProps = {} } = this.props;

    if (values?.use_service_portal_only != undefined)
      values.use_service_portal_only = this.state.use_service_portal_only;

    return (
      <div id="GeneralUserList" className="pb-3">
        <CommonTable
          col={col}
          data={data}
          orderColumn
          paginationTop={false}
          searchColumn={true}
          countTotal={countTotal}
          loadSearch={true}
          isAutomaticPagination={false}
          /*noFilter = {true}*/
          callPageSelect={(page) => callPagination(page)}
          callDataFilter={(values) => callDataFilter(values)}
          onClearAllFilter={() => cleanFilters()}
          onSortItem={(item, sortByStatus) => {
            sortBy(item, sortByStatus);
          }}
          searchColumnEvent={(v) => {
            this.props.callPageSelectFilter(0, v, 75);
          }}
          callPageSelectFilter={(page, values, rowsPerPage, type) =>
            callPageSelectFilter(page, values, rowsPerPage, type)
          }
          print={false}
          beforeBar={
            <div className="d-flex align-items-center">
              <RaisedButton
                style={{ zoom: 0.8 }}
                zoom={0.8}
                className="mr-3"
                color="primary"
                circleButton
                icon="fas fa-plus"
                label={language.ADD + ' ' + language.USER}
                onClick={() => this.toggleModal(true)}
              />
              <Button
                variant="circle"
                icon="fas fa-file-csv"
                label={language.EXPORT + ' ' + language.USERS}
                color="warning"
                className="mr-3"
                style={{
                  fontSize: 15,
                }}
                onClick={downloadUsers}
              />
            </div>
          }
          index="GeneralUserList"
          rowsPerPageOptions={[75]}
        />
        <Dialog
          style={{ height: '200px' }}
          title={language.GENERAL_USER_CREATION}
          open={GeneralUserListModal}
          onClose={() => this.props.toggleModal(false)}
          maxWidth="lg"
          monitor_scroll
        >
          <CommonForm
            fields={fields}
            values={editvalor.cnpj != undefined ? editvalor : this.state.values}
            onChangeField={this.onChangeField}
            onSubmit={(values) => this.onFormSubmit(values)}
            button={{
              label: language.SAVE,
            }}
          />
        </Dialog>

        <Dialog
          style={{ height: '200px' }}
          title={language.CHANGE_PASSWORD}
          open={this.state.reset}
          onClose={() => this.toggleModal(false)}
          maxWidth="sm"
          miniVersion={true}
          monitor_scroll
        >
          <CommonForm
            fields={[
              {
                col: 12,
                type: 'element',
                content: <div className="tw-h-4"></div>,
              },
              {
                col: 12,
                name: 'password',
                label: language.PASSWORD,
                startIcon: 'fas fa-key',
                type: 'password',
                error:
                  this.state.password &&
                  !SensritUtilsPk.checkIfStringHasValidPasswordFn(
                    this.state.password
                  ),
                required: true,
              },
              {
                col: 12,
                type: 'element',
                content: (v, h, submit) => (
                  <>
                    <PasswordRequirements
                      language={language}
                      password={this.state.password}
                    />
                  </>
                ),
              },
              {
                col: 12,
                type: 'element',
                content: <div className="tw-h-4"></div>,
              },
              {
                col: 12,
                name: 'confirmation',
                label: language.CONFIRM_PASSWORD,
                startIcon: 'fas fa-key',
                type: 'password',
                error:
                  this.state.confirmPassword &&
                  this.state.password !== this.state.confirmPassword,
                required: true,
              },
              {
                col: 12,
                name: 'password_change_required',
                label: language.PASSWORD_CHANGE_NEXT_LOGON,
                type: 'switch',
                // required: true,
              },
            ]}
            values={editvalor.cnpj != undefined ? editvalor : values}
            onChangeField={this.onChangeField}
            onSubmit={(values) => this.sendPassword(values)}
            button={{
              label: language.SAVE,
              disabled: !(
                SensritUtilsPk.checkIfStringHasValidPasswordFn(
                  this.state.password
                ) && this.state.password === this.state.confirmPassword
              ),
            }}
          />
        </Dialog>
        {this.state.openContract && (
          <ContractUser_
            closeContract={this.closeContract}
            action={this.action}
            language={language}
            APIEndpoints={this.APIEndpoints}
            values={this.state.valuesEdit}
            store={this.store}
            language={language}
          />
        )}
        {this.state.openCompany && (
          <CompanyUser
            closeContract={this.closeContract}
            action={this.action}
            APIEndpoints={this.APIEndpoints}
            values={this.state.valuesEdit}
          />
        )}
        {this.state.openGroup && (
          <GroupUser
            action={this.action}
            APIEndpoints={this.APIEndpoints}
            language={language}
            onClose={() => {
              this.setState({
                openGroup: false,
                valuesEdit: '',
              });
            }}
            values={this.state.valuesEdit}
          />
        )}
      </div>
    );
  }
}

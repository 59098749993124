export default {
  _ANALYZE: 'Analyze',
  _CONTRACT: 'Contract',
  _PROGRESS: 'Progress',
  _REQUEST: 'Request',
  _SERVICE: 'Service',
  _TEMPLATE: 'TEMPLATE',
  ABSTRACT: 'Abstract',
  ACCEPT: 'Accept',
  ACCEPT_SUB_CALLS_OTHER_GROUPS: 'Accept sub calls from other groups',
  ACCEPTABLE: 'Acceptable',
  ACCEPTED: 'Accepted',
  ACCESS: 'Access',
  ACCESS_CONTROL: 'Access Control',
  ACCESS_OPTION_VIEW_REPORTS: 'Access this option to view all Reports',
  ACCESS_OPTION_VIEW_THE_REPORTS: 'Access this option to view the Report',
  ACCESS_REMOTE: 'Remote access',
  ACCOUNT: 'Account',
  ACCOUNT_MANAGER: 'Account Manager',
  ACCOUNTED_HOURS_WILL_NOT_BE_DELETED: 'Accounted hours will not be deleted',
  ACCUMULATED: 'Accumulated',
  ACCUMULATED_TOTAL: 'Accumulated total',
  ACCUMULATED_VALUE: 'Accumulated value',
  ACCUMULATED_VALUES: 'Accumulated values',
  ACN: 'ACN',
  ACTION: 'Action',
  ACTION_NOT_ALLOWED_GMUD_FROZEN: 'Action not allowed, Change frozen',
  ACTIONS: 'Actions',
  ACTIONS_TICKET: 'Ticket Actions',
  ACTIVE: 'Active',
  ACTIVE_PLURAL: 'Actives',
  ACTIVE_TIME: 'Active time',
  ACTIVITIES: 'Activities',
  ACTIVITIES_EXCEEDED_HOURS: 'Activities (exceeded hours)',
  ACTIVITIES_HOURS_PERFORMED: 'Activities (hours performed)',
  ACTIVITIES_IN_BACKLOG: 'Activities in backlog',
  ACTIVITIES_IN_DOING: 'Activities in progress',
  ACTIVITIES_IN_DONE: 'Completed activities',
  ACTIVITIES_IN_STOPPED: 'Stopped activities',
  ACTIVITIES_IN_TODO: 'Activities to do',
  ACTIVITIES_IN_VALIDATION: 'Validation activities',
  ACTIVITIES_PLANNED_HOURS: 'Activities (planned hours)',
  ACTIVITIES_RELATED_TO_PROJECT_YOU_INVOLVED_IN: 'Activities related to the project you are involved in',
  ACTIVITY: 'Activity',
  ACTIVITY_CLOSED_IN_MONTH: 'Closed activities',
  ACTIVITY_DATE: 'Activity Date',
  ACTIVITY_DESCRIPTION: 'Activity Description',
  ACTIVITY_DID_NOT_REACH_100_PERCENT: 'Activity did not reach 100%',
  ACTIVITY_EFFORT: 'Activity Effort',
  ACTIVITY_EVOLUTION: '% Activity Evolution',
  ACTIVITY_GROUP_NAME: 'Activity group name',
  ACTIVITY_IN_APRREARS: 'Overdue activities',
  ACTIVITY_IN_ARREARS: 'Overdue activities ',
  ACTIVITY_IN_EXECUTION: 'Activities in progress',
  ACTIVITY_IN_GMUD: 'activity in Change',
  ACTIVITY_NAME: 'Activity name',
  ACTIVITY_NEXT_EXPIRATION: 'Overdue activities',
  ACTIVITY_PENDENT: 'There are pending activities',
  ACTIVITY_PER_USER: 'Activity per user',
  ACTIVITY_REGISTER: 'Need to register activities',
  ACTIVITY_STARTED_IN_MONTH: 'Activities started in the month',
  ACTIVITY_STATUS: 'Activity status',
  ACTIVITY_SUMMARY: 'Activity Summary',
  ACTIVITY_TAGS: 'Activity tags',
  ACTIVITY_TIME: 'Activity time',
  ACTIVITY_WITHOUT_START: 'Activity registered without start date!',
  ACTUAL_AVAILABILITY: 'Actual availability',
  ACTUAL_END_DATE: 'Actual End Date',
  ACTUAL_START_DATE: 'Actual Start Date',
  AD_USER: 'User AD',
  ADD: 'Add',
  ADD_A_COMMENT: 'Add a comment',
  ADD_A_NEW_RESULT: 'Add a new result',
  ADD_A_SUBACTIVITY: 'Add a subactivity',
  ADD_ACTIVITIES: 'Add Activities',
  ADD_ACTIVITIES_TO_CATEGORY: 'Add activities to category',
  ADD_ACTIVITY: 'Add Activity',
  ADD_APPROVAL_TO_GROUP: 'Add Approver to Group',
  ADD_ASSIGNMENT: 'Add New Assignment',
  ADD_BLACKOUT: 'Add Blackout',
  ADD_CATALOG: 'Add Catalog',
  ADD_CATALOG_TO_CATEGORY: 'Add Service Catalog to Category',
  ADD_CATEGORIZATION: 'Add Categorization',
  ADD_CATEGORY: 'Add new category',
  ADD_CHANGE_TEMPLATE: 'Add change template',
  ADD_COMPANY: 'Add Contracted Company',
  ADD_COMPLETION_TEMPLATE: 'Add Call Completion Template',
  ADD_CONTRACT: 'Add Contract',
  ADD_CREDENTIALS: 'Add Credentials',
  ADD_DEPARTMENT: 'Add Department',
  ADD_EVENT: 'Add Event',
  ADD_EVIDENCE: 'Add Evidence',
  ADD_EXPECTED_RESULT: 'Add Expected Result',
  ADD_FORM: 'Add Form',
  ADD_GLOBAL: 'Add Global',
  ADD_GROUP: 'Add Group',
  ADD_GROUPING: 'Add grouping',
  ADD_IN_STOCK: 'Register in Stock',
  ADD_KNOWLEDGE_BASE: 'Register Knowledge Base',
  ADD_LOCATION: 'Add Location',
  ADD_MAIL: 'Add Email',
  ADD_MILESTONE: 'Add Milestone',
  ADD_MONITORING_GROUP: 'Add Monitoring Group',
  ADD_NEW_BLACKOUT: 'Add New Blackout',
  ADD_NEW_BUILD: 'Add new build',
  ADD_NEW_DEVICE: 'Add New Device',
  ADD_NEW_ITEM: 'Add new item',
  ADD_NEW_PASSWORD: 'Register new password',
  ADD_NEW_PASSWORD2: 'Add new password',
  ADD_NEW_TYPE_CONTRACT: 'Add new type of contract',
  ADD_OBSERVER: 'Add Observers',
  ADD_OTHER_COSTS: 'Add other costs',
  ADD_OUTSOURCED: 'Add Outsourced',
  ADD_REQUEST_FORM: 'Add Request Form',
  ADD_REQUESTER: 'Add Requester',
  ADD_RISKS_AND_ACTIVITIES: 'Add Risks and Milestones',
  ADD_RULE: 'Add rule',
  ADD_SERVICE: 'Add Services',
  ADD_SERVICE_PORTAL: 'Configure Service Portal',
  ADD_SLA: 'Add SLA',
  ADD_SOLICITANT: 'Add request',
  ADD_SOLICITANT_TO_GROUP: 'Add request to group',
  ADD_SPONSOR: 'Register New Sponsor',
  ADD_SPRINT: 'Add Sprint',
  ADD_SUBJECT: 'Add subject',
  ADD_TASK: 'Add New Assignment',
  ADD_TASK_ADD: 'Add Task',
  ADD_TASK2: 'Add Task',
  ADD_TECH_TO_GROUP: 'Add Tech to Group',
  ADD_TECHS: 'Add Technician',
  ADD_THE_ACTIVITY: 'add the activity',
  ADD_THIRD_LEVEL_CATEGORY: 'Add Third Level Category',
  ADD_TO_SPRINT: 'Add to Sprint',
  ADD_USER: 'Add User',
  ADD_USER_TO_DEPARTMENT: 'Add user to department',
  ADD_USER_TO_GROUP: 'Add user to group',
  ADD_USER_TO_NOTIFICATION_GROUP: 'Add user to notification group',
  ADD_USERS_TO_PROJECT: 'Add Users to the project',
  ADDED_COSTS: 'Added costs',
  ADDITIONAL_INFORMATION: 'Additional Information',
  ADDITIONAL_RECIPIENTS: 'Additional Recipients',
  ADDITIONAL_RECIPIENTS_HOLDER: 'Use a comma "," to separate email addresses',
  ADDITIONALINFO: 'Additional information',
  ADDRESS: 'Address',
  ADHERENCE: 'Adherence',
  ADHERENT: 'Adherent',
  ADMIN: 'Administrator',
  ADVANCE: 'Advance',
  ADVANCED_SEARCH: 'Advanced Search',
  AFFECTED_AREA: 'Affected area',
  AFFECTED_PRODUCT: 'Affected product',
  AFTER: 'After',
  AFTER_DEADLINE: 'After the deadline',
  AFTER_POST_IMPLEMENTATION_REVIEW: 'After post implementation review',
  AGENT: 'Agent',
  AGENT_DOWNLOAD: 'Agent Download',
  AGENT_VERSION: 'Agent Version',
  ALERT: 'Alert',
  ALERT_ATTACH_SAVE_CHECK: 'The attachment has been added successfully, to save click the save button at the end of the checks',
  ALERT_CENTER: 'Alert center',
  ALERT_CONTRACTOR_APROVE_REQUEST: 'Only the contractor is allowed to approve the request!',
  ALERT_DELETE_SUCCESS: 'Data successfully removed',
  ALERT_INSERT_SUCCESS: 'Data inserted successfully',
  ALERT_MANAGEMENT: 'Alert management',
  ALERT_NO_RESULT_FOUND: 'No results found',
  ALERT_TICKET: 'not met',
  ALERT_UPDATE_SUCCESS: 'Data updated successfully',
  ALERT_WANT_GENERATE_DETAILED_GMUD: 'Want to generate a detailed Change? We advise the complete. If you want to generate a simple and fast Change choose the standard option',
  ALERTS: 'Alerts',
  ALERTS_GROUP: 'Alert groups',
  ALIAS: 'Alias',
  ALL: 'All',
  ALL_ADDED_ACTIVITIES: 'All Added Activities',
  ALL_DEVICES: 'All Devices',
  ALLOCATED: 'Allocated',
  ALLOCATED_HOURS: 'Allocated Hours',
  ALLOCATION: 'Allocation',
  ALLOCATION_AND_DELIVERIES: 'Allocation and deliveries',
  ALLOCATION_OF_TEAM: 'Allocation of team',
  ALLOW_CREATE_SUB_CALLS: 'Allow to create sub Calls',
  ALLOW_CUSTOMIZE_PRIORITY: 'Allow customize priority',
  ALLOW_EDITING_OF_TECH_GMUD_AFTER_APPROVAL: 'Allow editing of technical gmud after approval',
  ALLOW_SENDING_EMAIL: 'Allow sending email',
  ALLOWANCE: 'Allowance',
  ALLOWANCE_READY: 'Successfully Paid Ticket',
  ALLOWANCE_SELECTED: 'Selected tickets',
  ALLOWANCE_SLA: 'Allowance SLA',
  ALLOWED_EXTENSIONS: 'Allowed extensions',
  ALSO_FINALIZE_THE_SUBCONTRACTS: 'Also finalize the subcontracts',
  ALSO_REMOVE_THE_SUBCONTRACTS: 'Also remove the subcontracts',
  ALTERNATE_BOSS: 'Alternate Boss',
  ALTERNATIVE_EMAIL: 'Email alternativo',
  ALTERNATIVE_LEADER: 'Alternative email',
  AMOUNT: 'Amount',
  AMOUNT_OF_HOURS: 'Amount of hours',
  AMOUNT_OF_MILESTONES: 'Amount Of Milestones',
  AMOUNT_OF_RISKS: 'Amount Of Risks',
  AN_HOUR: 'an hour',
  AN_INTERNAL_ERROR_OCCURRED_ON_THE_SERVER: 'An internal error occurred on the server',
  ANALITIC: 'Analitic',
  ANALYSIS: 'Analise',
  ANALYSIS_OF_INFORMATION_CALL: 'Information Analysis of Calls',
  ANALYSIS_OF_INFORMATION_GSD: 'Analysis GSD Information',
  ANALYSIS_PROJECT_MANAGEMENT: 'Analysis of project management',
  ANALYST: 'analyst',
  ANALYST_CALLS: 'Analyst Calls',
  ANALYSTS: 'analysts',
  ANALYZE: 'Analyze',
  ANALYZE_CHANGE: 'Analyze Change',
  ANALYZE_DEVICE_MONITORING: 'Analyze device monitoring',
  ANALYZING: 'Analyzing',
  AND: 'and',
  ANNOTATION: 'Annotation',
  ANSWER: 'Resposta',
  ANSWER_QUESTION: 'Answer a Question',
  ANSWERED_CALLS_RESOLVED_AND_CLOSED: 'Answered calls (Resolved and Closed)',
  ANSWERS: 'Answers',
  ANTICIPATED: 'Anticipated',
  APPLICABLE: 'Applicable',
  APPROVAL: 'Approval',
  APPROVAL_CARRIED_OUT_SUCCESSFULLY: 'Approval carried out successfully',
  APPROVAL_CENTER: 'Approval Center',
  APPROVAL_DATE: 'Approval Date',
  APPROVAL_DETAILS: 'Approval Details',
  APPROVAL_GROUP: 'Group of Approvers',
  APPROVAL_HISTORY: 'Approval History',
  APPROVAL_REQUIRED: 'Approval required',
  APPROVAL_SUCCESSFULLY_COMPLETED: 'Approval successfully completed',
  APPROVALS: 'Approvals',
  APPROVE: 'Aprove',
  APPROVE_ATTACHMENTS: 'Approve attachments',
  APPROVE_EVIDENCE: 'Approve evidence',
  APPROVE_KNOWLEDGE: 'Aprovar Criação/Alteração da base de conhecimento',
  APPROVE_MATURITY: 'Approve maturity',
  APPROVE_REQUESTS: 'Approve requests',
  APPROVED: 'Approved',
  APPROVER: 'Approver',
  APPROVERS: 'Approvers',
  APPROVERS_GROUP: 'Group of Approvers',
  APPROVERS_GROUPS: 'Approvers Groups',
  APPROVING_GROUP: 'Approving Group',
  ARCHITECTURE: 'Architecture',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COST: 'Are you sure you want to exclude this cost?',
  AREA_BENEFITED_FROM_THE_PROJECT: 'Area benefited from the project',
  ASK: 'Question',
  ASKS: 'Asks',
  ASSESSMENT: 'Assessment',
  ASSESSMENTS: 'Assessments',
  ASSESSMENTS_LIST: 'Assessments List',
  ASSET_INVOLVED: 'Asset Involved',
  ASSET_MANAGEMENT: 'Asset Management',
  ASSET_MANAGER: 'Asset Manager',
  ASSET_TYPE: 'Asset Type',
  ASSIGN: 'To Assign',
  ASSIGN_CALLS_ONLY_TECH_IS_FREE: 'Assign calls only if the technician is free',
  ASSIGNED_GROUP: 'Assigned group',
  ASSIGNED_TECH_STOP_RESPONSE_SLA: 'Assigned technician stops response SLA',
  ASSOCIATE: 'Associate',
  ASSOCIATE_COMPANY: 'Associate Company',
  ASSOCIATE_DEPARTMENT: 'Associate Department',
  ASSOCIATE_IN: 'Associate in',
  ASSOCIATE_PREDECESSOR_TASKS: 'Associate Predecessor Tasks',
  ASSOCIATE_PROJECT_ACTIVITIES: 'Associate project activities',
  ASSOCIATE_TASK_TEMPLATE: 'Associate Task Template',
  ASSOCIATE_TASKS: 'Associate Tasks',
  ASSOCIATE_TECH_CONTRACT: 'Associate Technician x contracts',
  ASSOCIATE_TECH_X_COMPANY: 'Associate Technician x Company',
  ASSOCIATE_TICKET: 'Associate Ticket',
  ASSOCIATE_TICKETS: 'Associate Tickets',
  ASSOCIATE_TO_TEMPLATE: 'Associate to Template',
  ASSOCIATE_TO_TICKET: 'Associate to ticket',
  ASSOCIATE_USER: 'Associate User',
  ASSOCIATE_USER_CONTRACT: 'Associate User x contracts',
  ASSOCIATE_USER_X_COMPANY: 'Associate User x Company',
  ASSOCIATE_WITH_GP: 'Associate with GP',
  ASSOCIATE_WITH_TK: 'Associate with TK',
  ASSOCIATED_ACTIVITY: 'Associated activity',
  ASSOCIATED_ITEMS: 'Associated Items',
  ASSOCIATED_TASKS: 'Associated Tasks',
  ASSOCIATED_TICKETS: 'Associated Tickets',
  ASSOCIATING_TICKET_CLOSE: 'When associating the tickets they will be closed',
  ASSOCIATION_ITEM_TO_PROJECT: 'Link item to project',
  ASSOCIATION_ITEM_TO_PROJECT_ACTIVITY: 'Link item to activity  project',
  ASSOCIATION_ITEM_TO_PROJECT_OR_MANUAL: 'Associate the selected item with a #Project, Average Service Time, Categorization Time or Manual',
  ASSOCIATION_TICKETS: 'Association tickets',
  AT: 'At',
  ATTACH: 'Attach',
  ATTACH_DATE: 'Attach Date',
  ATTACH_EVIDENCE: 'Attach Evidence',
  ATTACH_FILE: 'Attach file',
  ATTACH_FILES: 'Attach files',
  ATTACH_FILE_ERROR: 'This file has an invalid format or is larger than allowed.',
  ATTACH_FILES_HERE: 'Attach files here',
  ATTACHED_EDIT: 'Edited attachments',
  ATTACHMENT: 'Attachment',
  ATTACHMENTS: 'Attachments',
  ATTACH_FILES: 'Attach files',
  ATTACH_FILE_ERROR:
    'This file has an invalid format or is larger than allowed.',
  ATTACH_FILES_HERE: 'Attach files here',
  ATTACHED_EDIT: 'Edited attachments',
  ATTEND: 'Answer',
  ATTENDANCE: 'ATTENDANCE',
  ATTENDANCE_AVERAGE: 'ATTENDANCE AVERAGE',
  ATTENDANCE_DEADLINE: 'Attendance Deadline',
  ATTENDANCE_GROUP: 'Attendance Group',
  ATTENDANCE_STOPPED: 'Called stopped',
  ATTENDANT: 'Attendant',
  ATTENDED: 'Attended',
  ATTENTION: 'Attention!',
  AUDIT: 'Audit',
  AUDIT_CATALOG_MANAGEMENT_BODY: 'Audit catalog',
  AUDIT_COMPANY_MANAGEMENT_BODY: 'Audit company',
  AUDIT_FLOW_APPROBATION_GMUD: 'Audit flow approbation GMUD',
  AUDIT_FORM: 'Audit form',
  AUDIT_USERS_MANAGEMENT_BODY: 'Audit users',
  AUTHOR: 'Author',
  AUTOMATIC_ANSWER: 'Automatic answer',
  AUTOMATIC_APPROVER_GROUPS: 'Automatic Approver Groups',
  AUTOMATIC_ASSIGN_TICKET_AFTER: 'Automatically assign ticket after',
  AUTOMATIC_CLOSING: 'Automatic Closing',
  AUTOMATION: 'Automation',
  AVAILABILITY: 'Availability',
  AVAILABILITY_MANAGEMENT: 'Availability Management',
  AVAILABLE_CLASSES: 'Available classes',
  AVALIATION_SENT: 'Thank you, the satisfaction survey has been successfully sent.',
  AVALIATION_TABLE: 'Avaliation Tables',
  AVALIATION_TICKET: 'Evaluation of the ticket',
  AVALIATION_TICKET_RESOLUTION_QUALITY: "On a scale from 0 to 10, what is your assessment of the solution's quality for the ticket?",
  AVALIATION_TICKET_RESOLUTION_TIME: 'On a scale from 0 to 10, what is your assessment of the resolution time for the ticket?',
  AVALIATION_TICKET_TEAM: 'On a scale from 0 to 10, what was your experience with our customer service team?',
  AVERAGE: 'Average',
  AVERAGE_AVAILABILITY: 'Average availability',
  AVERAGE_CATEGORIZATION_TIME: 'Average Categorization Time',
  AVERAGE_OF_THE_LAST_TWELVE_MONTHS: 'Average of the last 12 months',
  AVERAGE_SERVICE_TIME: 'Average service time',
  AVERAGE_TIME: 'Average time',
  AVERAGES_OF_EACH_STANDARD: 'Averages of each standard',
  AWAITING_EXECUTION: 'Awaiting execution',
  BACK: 'Back',
  BACKDATING_IS_NOT_ALLOWED: 'Backdating is not allowed!',
  BACKLOG: 'BackLog',
  BACKLOG_ACTIVITY: 'Backlog of activities',
  BACKLOG_PROJECTS: 'Backlog of projects',
  BACKUP: 'Backup',
  BACKUP_LIST: 'Backup List',
  BACKUP_MANAGEMENT: 'Backup Management',
  BACKUP_SIDE_DISH: 'Backup Side Dish',
  BACKUP_TYPE: 'Backup Type',
  BALANCE: 'Balance',
  BALANCE_SCORE_CARD: 'Balanced Score Card',
  BASE: 'Base',
  BASIC_INFO: 'Basic Info',
  BASIC_TICKET_INFORMATION: 'Basic ticket information',
  BEFORE: 'Before',
  BEFORE_DEADLINE: 'Before the deadline',
  BENEFITED_AREA_PROJECT: 'Benefited Area of ​​the Project',
  BENEFITS: 'Benefits',
  BILLING_DATE: 'Billing Date',
  BILLING_DATE_MONTH: 'Billing Month',
  BILLING_DAY: 'Billing Day',
  BILLING_TYPE: 'Billing Type',
  BIND_TICKET: 'Bind Ticket',
  BIOS_MANUFACTURER: 'Bios manufacturer',
  BIOS_NAME: 'BIOS name',
  BIOS_SERIAL_NUMBER: 'BIOS serial number',
  BIOS_VERSION: 'BIOS version',
  BLOCK_TICKET_WITH_TASKS_PENDENT: 'Block tickets with pendent tasks',
  BOARD_DESIRE: 'Board Desire',
  BOARD_MANUFACTURER: 'Board manufacturer',
  BOARD_PRODUCT: 'Board product',
  BOARD_SERIAL_NUMBER: 'Board serial number',
  BOARD_VERSION: 'Board version',
  BOSS: 'Boss',
  BSC: 'BSC',
  BSC_IN: 'Indicators',
  BSC_INDICATOR: 'Indicator',
  BSC_METAS: ' Goals',
  BSC_OB: 'Corporate Objective',
  BSC_PERS: 'Perspective',
  BSC_TI: 'Purpose of TI',
  BUDGETED_AMOUNT: 'Budgeted amount',
  BUDGETED_DEVIATION: 'Budget deviation',
  BUILD: 'Build',
  BURST: 'Burst',
  BUSINESS_AREA: 'Business area',
  BUSINESS_CONTINUITY_MANAGEMENT: 'Business Continuity Management',
  BY: 'By',
  BY_AREA: 'By area',
  BY_CATEGORIZATION: 'By categorization',
  BY_CLOSING: 'By closing',
  BY_CONSUMPTION: 'By consumption',
  BY_DATE: 'By date',
  BY_DEPARTMENT: 'By department',
  BY_ID: 'By ID',
  BY_MONTH: 'Per month',
  BY_OPENING: 'By opening',
  BY_TECHNICAL_GROUP_WITH_CONSUMED_HOURS: 'BY TECHNICAL GROUP WITH CONSUMED HOURS',
  BY_TYPE: 'By type',
  BY_VALUE: 'By value',
  CAB_ACCEPT: 'Accept CAB',
  CAB_ALERT: 'Change Advisory Board saved successfully',
  CAB_ANALYSIS: 'CAB Analysis',
  CAB_ATTACH: 'Attach CAB Record',
  CAB_EVENTS: 'CAB Events',
  CAB_REJECT: 'Reject CAB',
  CAB_REJECTED: 'CAB Rejected',
  CAB_SCHEDULING: 'CAB Scheduling',
  CADASTRE_MADE: 'Cadastre Made',
  CADASTRE_TIME: 'Cadastre Time',
  CALENDAR: 'Calendar',
  CALENDAR_GMUD: 'Calendar Change',
  CALENDAR_OF_ACTIVITIES: 'Calendar of activities',
  CALL: 'Call',
  CALL_CLOSING: 'Call closing',
  CALL_EVALUATION: 'Call evaluation',
  CALL_NUMBER: 'Call Number',
  CALL_QUANTITY_GRAPH: 'Call quantity graph',
  CALL_REJECTED: 'Call Rejected',
  CALL_REPORTING: 'Call reporting',
  CALL_TREND_PER_DAY: 'Call trend per day month',
  CALLED_BY_ANALYST: 'Called By Analyst',
  CALLED_PROVIDER: 'Called Provider',
  CALLED_TYPE: 'Called Type',
  CALLS: 'Calls',
  CALLS_OF_MONTH: 'Calls of the month',
  CALLS_PER_MONTH: 'Calls per month',
  CANCEL: 'Cancel',
  CANCEL_ACTIVITY: 'Cancel Activity',
  CANCEL_CALL: 'Cancel Call',
  CANCELED: 'Canceled',
  CANCELED_PROJECTS: 'Canceled Projects',
  CANNOT_BE_ASSOCIATED_DIFFERENT_COMPANIES: 'Item cannot be associated with different companies!',
  CANVAS: {
    '1_TO_5': '1 to 5',
    ACTIONS_SUPPORT_COMPANY_STRATEGY: 'Actions that support company strategy',
    ACTIVITY_PRD: 'Activity Predecessora',
    BOARD_DIRECTORS: 'Board of Directors',
    BUSINESS_PARTHER_CONTRACTS: 'Business Partner Contracts (Value Chain)',
    COMMUNICATION_RESULTS: 'Communication and Results',
    CRITICAL_PROCESS_IT_SUPPORTS: 'Critical processes that IT supports with direct impact',
    CRITICAL_SERVICES: 'Critical Services',
    EXPENSES: 'Expenses',
    HOW_RESULTS_MONITORED: 'How will the results be monitored and presented?',
    ID_ACESS_REMOTE: 'Remote Access Id',
    INDICATOR_TYPES: 'Indicator Types',
    INVESTMENT: 'Investment',
    INVESTMENTS: 'Investments',
    IT_MODEL_CANVAS: 'IT Model Canvas',
    IT_OBJECTIVE: 'IT objective',
    ITEMS_IMPACTS_BUSSINES: "Impediments to the company's strategic objectives",
    ITEMS_SUPPORT_GOALS: 'Items that support company goals',
    PLANED_REQUIRED_EXPENSES: 'Planned / Required Expenses',
    PLANNED_INVESTIMENTS: 'Planned / Required Investments',
    PRIORITIZATION: 'Prioritization',
    RISK_MAPPING: 'Risk Mapping'
  },
  CANVAS_: 'Canvas',
  CAPACITY: 'Capacity',
  CAPACITY_MEMORY: 'Capacity memory',
  CAPACITY_PLAN: 'Capacity Plan',
  CATALOG: 'Catalog',
  CATALOG_LIST: 'Catalog Service List',
  CATALOG_TASK: 'Catalog Task',
  CATEGORIES: 'Categories',
  CATEGORIZATION: 'Categorization',
  CATEGORIZATION_DATA_FINAL: 'Categorization Date - Final',
  CATEGORIZATION_DATA_INITIAL: 'Categorization Date - Initial',
  CATEGORIZATION_DATE: 'Categorization Date',
  CATEGORIZATION_LIST: 'Categorization List',
  CATEGORIZATION_TIME: 'Categorization Time',
  CATEGORIZATIONS: 'Categorizations',
  CATEGORIZE: 'Categorize',
  CATEGORIZE_SELECTED: 'CATEGORIZE SELECTED',
  CATEGORY: 'Category',
  CATEGORY_ACTIVITY: 'Categories of Activities',
  CATEGORY_DESCRIPTION: 'It is an element used mainly to classify and facilitate group order.',
  CATEGORY_LIST: 'Categories List',
  CAUSE: 'Causa',
  CELL_PHONE: 'Cell Phone',
  CHANGE: 'Changes',
  CHANGE_ADDED_SUCCESSFULLY: 'Change Added Successfully',
  CHANGE_DATA: 'Change data',
  CHANGE_DATA_SUCCESS: 'Successfully changed data',
  CHANGE_HISTORY: 'CHANGE HISTORY',
  CHANGE_LIST: 'Change List',
  CHANGE_LOCATION: 'Change location',
  CHANGE_MANAGEMENT: 'Change Management',
  CHANGE_MANAGER: 'Change Process Manager',
  CHANGE_OPEN: 'Open Change Management',
  CHANGE_PASSWORD: 'Change Password',
  CHANGE_PASSWORD_SUCCESS: 'Successfully changed password',
  CHANGE_REGISTER_MANAGER: 'Change Register Manager',
  CHANGE_RISK_ALERT: 'Successfully changed Risk',
  CHANGE_SORTING: 'Change sorting',
  CHANGE_STATUS: 'Change Status',
  CHANGE_TECH_GROUP: 'Change technical group',
  CHANGE_TECHNICIAN: 'Change Technician',
  CHANGE_TEMPLATE: 'Change Template',
  CHANGE_VISUALIZATION: 'Switch view',
  CHANNEL: 'Channel',
  CHANNELS: 'Channels',
  CHANNELS_MANAGEMENT: 'Channel Management',
  CHARACTERS: 'Characters',
  CHARGE_INTERVAL: 'Charge interval',
  CHARGE_TRANSFER: 'Charge transfer',
  CHAT: 'Chat',
  CHECK: 'Check',
  CHECK_ALL: 'Select All',
  CHECK_FIELD_SIZES: 'Check field sizes',
  CHECK_YOUR_EMAIL_BOX: 'Check your email box',
  CHECKED: 'Checked',
  CHOOSE_ICON: 'Choose Icon',
  CHOOSE_ITENS: 'Choose the items',
  CIO: 'CIO',
  CIO_COCKPIT: 'CIO Cockpit',
  CITY: 'City',
  CLASSIFICATION: 'Classification',
  CLEAR: 'Clear',
  CLEAR_FILTER: 'Clear filter',
  CLEAR_TECH_AFTER_REOPEN_TICKET: 'Clear tech after reopen ticket',
  CLICK_ICON_SEE_ATTENDANCE: 'Click the icon to see the details of the calls made',
  CLIENT: 'Client',
  CLIENTS: 'Clients',
  CLIENTS_REGISTER: 'Clients Register',
  CLOCK_SPEED: 'Clock speed',
  CLONE: 'Clone Device',
  CLONE2: 'Clone',
  CLOSE: 'Close',
  CLOSE_TICKET: 'Close ticket',
  CLOSED: 'Closed',
  CLOSED_PLURAL: 'Closed',
  CLOSED_PROJECT: 'Project Closed',
  CLOSING: 'Closing',
  CLOSING_DATE: 'Closing date',
  CMDB: {
    APPLICATION: 'Application',
    ASSET: 'Asset',
    BACKUP: 'Backup',
    BATCHJOB: 'Batch Job',
    CI_BASE: 'CI Base',
    CI_BUSINESS_SERVICE: 'CI Business Service',
    DATABASE: 'Database',
    ENDPOINT: 'Endpoint',
    IP: 'IP',
    MONITORING: 'Monitoring',
    REFERENCE_ID: 'Reference Id',
    SERVER: 'Server'
  },
  CMDB_: 'CMDB',
  CMDB_APP_NAME: 'Application Name',
  CMDB_APP_PORT: 'Application Port',
  CMDB_ASSET_COLLOCATION: 'Asset Collocation',
  CMDB_ASSET_DC: 'Asset DC',
  CMDB_ASSET_OWNER: 'Asset Owner',
  CMDB_ASSET_TAG: 'Asset Tag',
  CMDB_ASSET_TYPE: 'Asset Type',
  CMDB_BATCHJOB_CONF_DIR: 'BatchJob Configuration directory',
  CMDB_BATCHJOB_CONF_FILE: 'BatchJob Configuration file',
  CMDB_BATCHJOB_INS_DIR: 'BatchJob Installation directory',
  CMDB_BATCHJOB_INT_REF: 'BatchJob Integration Reference',
  CMDB_BATCHJOB_JUSTIFY: 'BatchJob Justification',
  CMDB_BATCHJOB_RUN_PROC_COM: 'BatchJob Running process command',
  CMDB_BATCHJOB_RUN_PROC_COM_HASH: 'BatchJob Running process command hash',
  CMDB_BATCHJOB_RUN_PROC_KEY_PARAM: 'BatchJob Running process key parameters',
  CMDB_BATCHJOB_RUN_PROC_KEY_PARAM_HASH: 'BatchJob Running process key parameters hash',
  CMDB_BATCHJOB_TCP_PORTS: 'BatchJob TCP port(s)',
  CMDB_BKP_CLIENT_HOST: 'Backup Client Host',
  CMDB_BKP_JOB_TYP: 'Backup Job Type',
  CMDB_BKP_MASTER_CLIENT: 'Backup Master Server',
  CMDB_BKP_SCHEDULES: 'Backup Schedules',
  CMDB_CI_BASE_ACN: 'CI Base Acn',
  CMDB_CI_BASE_ASSIGN_GROUP: 'CI Base Assignment Group',
  CMDB_CI_BASE_CDPT: 'CI Base Cdpt',
  CMDB_CI_BASE_CI_TYPE: 'CI Base Ci Type',
  CMDB_CI_BASE_CODE: 'CI Base Code',
  CMDB_CI_BASE_ENV: 'CI Base Environment',
  CMDB_CI_BASE_FQDN: 'CI Base Fqdn',
  CMDB_CI_BASE_LOCATION: 'CI Base Location',
  CMDB_CI_BASE_OPER_DATE: 'CI Base Operational Date',
  CMDB_CI_BASE_OPER_STATUS: 'CI Base Operational Status',
  CMDB_CI_BASE_SUP_GROUP: 'CI Base Support Group',
  CMDB_CI_BASE_SUPPORTED: 'CI Base Supported',
  CMDB_CI_BASE_VERSION: 'CI Base Version',
  CMDB_CI_BS_BUSINESS_CRITICALITY: 'CI Business Service\tCriticality',
  CMDB_CI_BS_BUSINESS_IMPACT: 'CI Business Service\tImpact',
  CMDB_CI_BS_BUSINESS_PROCESS: 'CI Business Service\tProcess',
  CMDB_CI_BS_SERVICE_NAME: 'CI Business Service\tName',
  CMDB_CI_STATUS: 'CI Status',
  CMDB_DB_INSTANCE: 'Database Instance',
  CMDB_DB_TCP: 'Database TCP Port',
  CMDB_ENDPOINT_PORT: 'Endpoint Port',
  CMDB_ENDPOINT_PROTOCOL: 'Endpoint Protocol',
  CMDB_IP_MANAGED_GROUP: 'Ip Managed Group',
  CMDB_IP_OWNER: 'Ip Owner',
  CMDB_IP_TYPE: 'Ip Type',
  CMDB_MON_POLICY_ID: 'Monitoring Policy Id',
  CMDB_MON_TOOL: 'Monitoring Tool',
  CMDB_REF_ID: 'Reference ID',
  CMDB_REF_TOOL: 'Reference Tool',
  CMDB_SERIAL_NUMBER: 'Asset Serial Number',
  CMDB_SRV_CPU: 'Server Cpu',
  CMDB_SRV_DISK_SPACE: 'Server Disk Space',
  CMDB_SRV_HOST: 'Server Host',
  CMDB_SRV_HOST_ID: 'Server Host Id',
  CMDB_SRV_IS_VIRTUAL: 'Server Is Virtual',
  CMDB_SRV_LOCALIZATION: 'Server Localization',
  CMDB_SRV_OBJECT_ID: 'Server Object Id',
  CMDB_SRV_OS: 'Server Os',
  CMDB_SRV_RAM: 'Server Ram',
  COBIT: 'COBIT',
  CODE: 'Code',
  CODE_PEP: 'Code PEP',
  CODE_RESOLUTION_NAME: 'Resolution code name',
  CODE_RESOLUTION_VALUE: 'Resolution code value',
  CODE_SAP: 'Code SAP',
  COIN: 'Currency',
  COIN_LIST_AFGHAN: 'Afghan',
  COIN_LIST_ARUBAN_FLORIN: 'Aruban Florin',
  COIN_LIST_AZERBAIJANI_MANAT: 'Azerbaijani Manat',
  COIN_LIST_BAHAMIAN_DOLLAR: 'Bahamian Dollar',
  COIN_LIST_BAHRAINI_DINAR: 'Bahraini Dinar',
  COIN_LIST_BALBOA: 'Balboa (The US dollar USD is the currency in the country)',
  COIN_LIST_BARBADIAN_DOLLAR: 'Barbadian Dollar',
  COIN_LIST_BELARUSIAN_RUBLE: 'Belarusian Ruble',
  COIN_LIST_BELIZE_DOLLAR: 'Belize Dollar',
  COIN_LIST_BERMUDIAN_DOLLAR: 'Bermudian Dollar',
  COIN_LIST_BOLIVAR: 'Venezuelan Sovereign Bolívar',
  COIN_LIST_BOLIVAR2: 'Venezuelan Sovereign Bolívar',
  COIN_LIST_BRUNEI_DOLLAR: 'Brunei Dollar',
  COIN_LIST_BURUNDIAN_FRANC: 'Burundian Franc',
  COIN_LIST_CANADIAN_DOLLAR: 'Canadian Dollar',
  COIN_LIST_CAYMAN_ISLANDS_DOLLAR: 'Cayman Islands Dollar',
  COIN_LIST_COLON: 'Costa Rican Colón',
  COIN_LIST_CONGOLESE_FRANC: 'Congolese Franc',
  COIN_LIST_CONVERTIBLE_MARK: 'Mark convertible',
  COIN_LIST_CORDOBA_NICARAGUAN: 'Nicaraguan Córdoba',
  COIN_LIST_CZECH_KRONE: 'Czech Krone',
  COIN_LIST_DANISH_KRONE: 'Danish Krone',
  COIN_LIST_FALKLAND_ISLANDS_POUND: 'Falkland Islands Pound',
  COIN_LIST_FIJIAN_DOLLAR: 'Fijian Dollar',
  COIN_LIST_GIBRALTAR_POUND: 'Gibraltar Pound',
  COIN_LIST_GUINEAN_FRANC: 'Guinean Franc',
  COIN_LIST_GUYANESE_DOLLAR: 'Guyanese Dollar',
  COIN_LIST_HUNGARIAN_FORINT: 'Hungarian Forint',
  COIN_LIST_ICELANDIC_KRONE: 'Icelandic Krone',
  COIN_LIST_IRANIAN_RIAL: 'Iranian Rial',
  COIN_LIST_IRAQI_DINAR: 'Iraqi Dinar',
  COIN_LIST_JAMAICAN_DOLLAR: 'Jamaican Dollar',
  COIN_LIST_KENYAN_SHILLING: 'Kenyan Shilling',
  COIN_LIST_KUWAITI_DINAR: 'Kuwaiti Dinar',
  COIN_LIST_MAROCCAN_DIRHAM: 'Maroccan Dirham',
  COIN_LIST_MEXICAN_INVESTIMENT_UNIT: 'Mexican Investment Unit',
  COIN_LIST_MOLDOVAN_LEU: 'Moldovan Leu',
  COIN_LIST_NAMIBIAN_DOLLAR: 'Namibian Dollar',
  COIN_LIST_NEW_ZEALAND_DOLLAR: 'New Zealand Dollar',
  COIN_LIST_NORWEGIAN_KRONE: 'Norwegian Krone',
  COIN_LIST_QATARI_RIYAL: 'Qatari Riyal',
  COIN_LIST_ROMANIAN_LEU: 'Romanian Leu',
  COIN_LIST_RWANDAN_FRANC: 'Rwandan Franc',
  COIN_LIST_SERBIAN_DINAR: 'Serbian Dinar',
  COIN_LIST_SEYCHELLOIS_RUPEE: 'Seychellois Rupee',
  COIN_LIST_SINGAPORE_DOLLAR: 'Singapore Dollar',
  COIN_LIST_SOLOMON_ISLANDS_DOLLAR: 'Solomon Islands Dollar',
  COIN_LIST_SOMALI_SHILLING: 'Somali Shilling',
  COIN_LIST_SOUTH_KOREAN_WON: 'South Korean Won',
  COIN_LIST_SRI_INDIAN_RUPEE: 'Indian Rupee',
  COIN_LIST_SRI_LANKAN_RUPEE: 'Sri Lankan Rupee',
  COIN_LIST_SUCRE: 'Sucre (The US dollar USD is the currency)',
  COIN_LIST_SUCRE2: 'Sucre (The US dollar USD is the currency in the country)',
  COIN_LIST_SUDANESE_DINAR: 'Sudanese Dinar',
  COIN_LIST_SURINAMESE_DOLLAR: 'Surinamese Dollar',
  COIN_LIST_SWEDISH_KRONE: 'Swedish Krone',
  COIN_LIST_SWISS_FRANC: 'Swiss Franc',
  COIN_LIST_SYRIAN_POUND: 'Syrian Pound',
  COIN_LIST_TANZANIAN_SHILLING: 'Tanzanian Shilling',
  COIN_LIST_TRINIDAD_AND_TOBAGO_DOLLAR: 'Trinidad and Tobago Dollar',
  COIN_LIST_TUNISIAN_DINAR: 'Tunisian Dinar',
  COIN_LIST_UGANDAN_SHILLING: 'Ugandan Shilling',
  COIN_LIST_UKRAINIAN_HRYVNIA: 'Ukrainian Hryvnia',
  COIN_LIST_UNIT_OF_ACCOUNT: 'Unit of Account (Fund Code)',
  COIN_LIST_URUGUAYAN_PESO: 'Uruguayan Peso',
  COIN_LIST_URUGUAYAN_PESO_IN_INDEXED_UNITS: 'Uruguayan Peso in Indexed Units',
  COIN_LIST_WIR_EURO: 'WIR Euro (complementary currency)',
  COIN_LIST_WIR_FRANC: 'WIR franc (complementary currency)',
  COIN_LIST_YEMENI_RIAL: 'Yemeni Rial',
  COIN_LIST_ZIMBABWEAN_DOLLAR: 'Zimbabwean Dollar',
  COIN_MANAGEMENT: 'Currency Management',
  COLLECTION_HISTORY: 'Collection History',
  COLOR: 'Color',
  COLORS_CUSTOMIZATION_TICKET_PRIORITY_URGENCY_IMPACT: 'Customization of Priority/Urgency/Impact colors on tickets',
  COMERCIAL_PHONE: 'Commercial Phone',
  COMMENT: 'Comments',
  COMMENT_DETAILS: 'Comments Details',
  COMMENTAR: 'Comment',
  COMMENTARY: 'Commentary',
  COMMUNICATION_AND_OPERATIONS_MANAGEMENT: 'Communication And Operations Management',
  COMMUNITY: 'Community',
  COMPANIES: 'Companies',
  COMPANY: 'Company',
  COMPANY_CAB_GLOBAL: 'Global CAB company',
  COMPANY_CHANGE: 'Trocar Empresa',
  COMPANY_CONTRACTED: 'Company Contracted',
  COMPANY_DESCRIPTION: 'The company is an economic and social unit, composed of human and materials.',
  COMPANY_DETAIL: 'Register your business hours by module',
  COMPANY_DOES_NOT_HAVE_SMTP_CONFIGURATION_SELECTED: 'Company does not have SMTP configuration selected',
  COMPANY_FILTER_CONTRACT: 'Company filter and contract',
  COMPANY_FILTER_DEVICE: 'Company filter and device',
  COMPANY_FILTER_STANDARDS: 'Company filter and standards',
  COMPANY_HOUR: 'Business Hours',
  COMPANY_HOUR_DETAIL: "Register the company's opening hours by module.",
  COMPANY_LIST: 'Company List',
  COMPANY_LOGO: 'Company logo',
  COMPANY_OBJECTIVE: 'Company Objective',
  COMPARATION: 'Comparation',
  COMPL_INFO: 'Complementary Information',
  COMPLEMENT: 'Complement',
  COMPLETE: 'Complete',
  COMPLETED: 'Completed',
  COMPLETED_LATE: 'Completed late',
  COMPLETED_PROJECT: 'Completed Projects',
  COMPLETED_SPRINTS: 'Completed Sprints',
  COMPLETED_TICKETS: 'Completed Tickets',
  COMPLETION_DATE: 'Date of the conclusion',
  COMPLEXITY: 'Complexity',
  COMPLEXITY_AND_COST: 'Complexity and cost',
  COMPONENT: 'Components',
  COMPUTER_NAME: 'Computer Name',
  CON: 'Concluded',
  CONCLUDE: 'Conclude',
  CONCLUDED: 'Concluded',
  CONCLUDED_CANCEL: 'Concluded Canceled',
  CONCLUDED_DATE: 'Completion Date',
  CONCLUDED_FAIL: 'Concluded with failure',
  CONCLUDED_PART: 'Concluded Partially',
  CONCLUDED_SUCCESS: 'Successfully concluded',
  CONCLUDES_THE_PREVIOUS_ACTIVITIES_TO_THIS: 'Concludes the previous activities to this',
  CONCLUI: 'Concluded',
  CONDITION: 'Condição',
  CONFIDENTIALITY: 'Confidentiality',
  CONFIG: 'Configurations',
  CONFIG_ALTERNATE_BOSS_BODY: 'Inform the alternate boss of the users',
  CONFIG_ANSWERS_BODY: 'Create auto responders and make the service desk easier',
  CONFIG_AUTOMATION_BODY: 'Automation of demands',
  CONFIG_CAB_EVENTS_BODY: 'Create events for the CAB',
  CONFIG_CATEGORIES_BODY: 'Target your service catalog',
  CONFIG_CHANGE_TEMPLATE_BODY: 'Create templates for changes',
  CONFIG_CHANNELS_BODY: 'Channel management',
  CONFIG_COIN_MANAGEMENT_BODY: 'Configure currencies to use in contracts',
  CONFIG_COMPANIES_BODY: 'Create, edit and/or delete companies',
  CONFIG_COMPANY_LOGO_BODY: 'Attach your company logo here',
  CONFIG_CONTRACT_BODY: "Manage your company's contracts here",
  CONFIG_DEPARTMENT_BODY: 'Create, edit and/or delete departments',
  CONFIG_DEVICE_BODY: "Register the company's devices",
  CONFIG_DEVICE_TYPE_BODY: 'Create, edit and/or delete device types',
  CONFIG_EMAIL_TEMPLATES_2_BODY: 'Register email templates here',
  CONFIG_EMAIL_TEMPLATES_BODY: 'Register email templates here',
  CONFIG_FILE_EXTENSIONS_BODY: 'Configure supported file extensions on the platform',
  CONFIG_FORMS_BODY: 'Create custom forms to suit your business',
  CONFIG_GENERAL_PARAMETERS_BODY: 'Configure access parameters on the platform',
  CONFIG_GROUPS_BODY: 'Create the service group(s) for your company',
  CONFIG_HELP_DESK_BODY: 'Configure Help Center tutorials',
  CONFIG_LDAP_BODY: 'Integrate your AD into the Sensr.IT platform',
  CONFIG_LOCATION_BODY: 'Create, edit and/or delete locations',
  CONFIG_MAIL_POLICY_BODY: 'Configure the platform communication email',
  CONFIG_OPENING_HOURS_BODY: 'Parameterize the start and end of the platform service',
  CONFIG_OUTAGE_BODY: 'Outage Registration',
  CONFIG_PANEL: 'Settings Panel',
  CONFIG_PARAM_ADMIN_ATTENDANCE: 'Accounting for Attendances by Administrators',
  CONFIG_PARAM_ADMIN_HISTORIC_REQUEST: 'Manager view only his tickets',
  CONFIG_PARAM_ALLOW_EDIT_FORM_CATEGORIZATION: 'Allow form editing in categorization screen',
  CONFIG_PARAM_APPROVE_GROUP_USERS: 'Approve Request using group of approvers',
  CONFIG_PARAM_APPROVE_REQUEST_CHIEF: 'Allow Manager to Approve their own tickets',
  CONFIG_PARAM_ASSOCIATE_REMOTE_ACCESS_TICKET: 'Associate remote access to ticket',
  CONFIG_PARAM_ASSOCIATED_TICKETS_MUST_TRACK_MAIN_TICKET_STATUS: 'Associated tickets must track status of the main ticket',
  CONFIG_PARAM_AVALIATION_TICKETS: 'Evaluate NPS',
  CONFIG_PARAM_CALL_OPENING: 'Enable opening calls on login page',
  CONFIG_PARAM_CAUSE_PROBLEM: 'Enable Cause Trouble',
  CONFIG_PARAM_CHANGE_GROUP_TECH_APPROVE: 'Automatic Technical Group Association as Change Approver',
  CONFIG_PARAM_COUNT_HOURS_TECH_CLIENT: 'Count hours by technicians of customer type',
  CONFIG_PARAM_COUNT_TICKET_BROKEN_ONCE: 'Count overflow tickets only once',
  CONFIG_PARAM_EMAIL_RESPONSE_CHAT: 'Enable Chat/Email Response',
  CONFIG_PARAM_ENABLE_CHANGE_STATUS_REASON: 'Enable reason in Ticket status change',
  CONFIG_PARAM_ENABLE_CREATE_PROBLEM_WHEN_RESOLVE_CRISIS: 'Enable issue creation when resolve crisis',
  CONFIG_PARAM_ENABLE_DESCRIPTION_EDITING: 'Enable description editing',
  CONFIG_PARAM_ENABLE_LOGS_DEVICE_GMUD_TICKET: 'Enable recording logs for Ticket and Change containing linked devices',
  CONFIG_PARAM_ENABLE_MULTIPLE_SELECTION: 'Enable selection of all requesters and approvers on OS creation',
  CONFIG_PARAM_ENABLE_REQUIRED_TEMPLATE_GMUD: 'Enable mandatory form selection in Change',
  CONFIG_PARAM_ENABLE_SERVICE_PORTAL_BY_COMPANIES: 'Enable Service Portal by Companies',
  CONFIG_PARAM_EVALUATE_TICKET: 'Enable ticket evaluation',
  CONFIG_PARAM_GMUD_CLOSING_OF_GMUD: 'Open a Problem Ticket when Change is closed with failure',
  CONFIG_PARAM_GMUD_PARTIAL_TICKET_OPEN_CATEGORY: 'First level of Category used on Ticket',
  CONFIG_PARAM_GMUD_TICKET_OPEN_CATEGORY: 'First level of Category used on Ticket',
  CONFIG_PARAM_GMUD_TICKET_OPEN_CATEGORY_SECOND: 'Second level of Category used on Ticket',
  CONFIG_PARAM_GMUD_TICKET_OPEN_CATEGORY_THIRD: 'Third level of Category used on Ticket',
  CONFIG_PARAM_GOOGLE_LOGIN: 'Enable Integration via Google',
  CONFIG_PARAM_HOURS_DAY: 'Amount of working hours in 1 day to measure activities in days',
  CONFIG_PARAM_LINKING_MULTI_COMPANY_TICKETS: 'Link tickets between multiple companies',
  CONFIG_PARAM_MICROSOFT_LOGIN: 'Enable Integration via Microsoft',
  CONFIG_PARAM_MULT_CATALOG: 'Enable Multiple Catalogs',
  CONFIG_PARAM_OPEN_TICKET_WHEN_GMUD_FAILS: 'Automatically Opens a Problem type Ticket when GMUD is finalized as fail',
  CONFIG_PARAM_READJUSTMENT_CONTRACT: 'Readjustment in mandatory contract',
  CONFIG_PARAM_REQUEST_BY_PORTAL: 'Open requests through the portal',
  CONFIG_PARAM_REQUEST_CLOSED_TICKETS: 'Number of days to close requests with Approval status',
  CONFIG_PARAM_REQUEST_SOLICITANT_SELECT: 'Select Requestor',
  CONFIG_PARAM_SEND_EMAIL_CATEGORIZATION: 'Send emails when categorizing',
  CONFIG_PARAM_SEND_EMAIL_CREATE_TASK_IN_TICKET: 'Send email when creating task in ticket',
  CONFIG_PARAM_SEND_EMAIL_GROUP_TECH: 'Enable Email Change Technician Group',
  CONFIG_PARAM_SEND_MAIL_OBSERVERS: 'Enable Email Observer(s)',
  CONFIG_PARAM_SHOW_REQUEST_CANCELLED_IN_CATEGORIZATION: 'Show rejected requests in categorization screen',
  CONFIG_PARAM_SUBTICKET_CLOSED_VERIFY: 'Calls can be finished regardless of having open subclauses',
  CONFIG_PARAM_TECHNICAL_EVALUATION_REGISTRATION: 'Enable call evaluation by technician',
  CONFIG_PARAM_TICKET_CLOSE_DAYS: 'Number of days to close tickets automatically',
  CONFIG_PARAM_TICKET_CLOSED_TASKS: 'Close ticket only when all tasks are completed',
  CONFIG_PARAM_TICKET_CONTRACT_SOLICITANT: 'Opening ticket solicitant global device',
  CONFIG_PARAM_TICKET_PARTIAL_CLOSING: 'Open Solicit when Change activity is closed with failure',
  CONFIG_PARAM_TICKET_SIMPLIFIED: 'Simplified ticket opening',
  CONFIG_PARAM_TIME_REFRESH: 'Time to refresh ticket page in minutes',
  CONFIG_PARAM_TIME_REFRESH_VISAO: 'Time to refresh the management view page in minutes',
  CONFIG_PARAM_TOOLIP_ENABLE_REQUIRED_TEMPLATE_GMUD: 'Enable mandatory form selection in Change',
  CONFIG_PARAM_TOOLTIP_ADMIN_ATTENDANCE: 'If this parameter is enabled, the hours of service performed by Administrators will be counted in the contract.',
  CONFIG_PARAM_TOOLTIP_ADMIN_HISTORIC_REQUEST: 'By enabling this parameter, the department manager will only view his tickets',
  CONFIG_PARAM_TOOLTIP_ALLOW_EDIT_FORM_CATEGORIZATION: 'By enabling this parameter, the forms on the categorization screen can be edited',
  CONFIG_PARAM_TOOLTIP_APPROVE_GROUP_USERS: 'Enabling this parameter to approve requests',
  CONFIG_PARAM_TOOLTIP_ASSOCIATE_REMOTE_ACCESS_TICKET: 'Enabling this parameter, remote accesses will not be counted in the summary and in the technicians report',
  CONFIG_PARAM_TOOLTIP_ASSOCIATED_TICKETS_MUST_TRACK_MAIN_TICKET_STATUS: 'Enabling this parameter, when associating one or more tickets to a main ticket, they will not be closed, but when updating the status of the main ticket, the status of the child ticket(s) will also be closed will be updated',
  CONFIG_PARAM_TOOLTIP_COUNT_HOURS_TECH_CLIENT: 'Enabling this parameter will count the hours of tickets handled by customer-type technicians',
  CONFIG_PARAM_TOOLTIP_COUNT_TICKET_BROKEN_ONCE: 'By enabling this parameter, tickets that overflow will always be counted in the month they overflowed',
  CONFIG_PARAM_TOOLTIP_ENABLE_MULTIPLE_SELECTION: 'Enable selection of all requesters and approvers on OS creation',
  CONFIG_PARAM_TOOLTIP_EVALUATE_TICKET: 'By enabling this parameter, the requester will be able to evaluate the ticket.',
  CONFIG_PARAM_TOOLTIP_GMUD_LEADERS_APPROVERS: 'By enabling this parameter allows user sponsors and user alternative sponsors to approve GMUD workflows.',
  CONFIG_PARAM_TOOLTIP_GMUD_WORKFLOW: 'By enabling this parameter allows you to create an approvers workflow for a GMUD based on established conditions.',
  CONFIG_PARAM_TOOLTIP_LINKING_MULTI_COMPANY_TICKETS: 'Enabling this parameter will make it possible to link tickets between multiple companies.',
  CONFIG_PARAM_TOOLTIP_REQUEST_BY_PORTAL: 'Enabling this parameter will open requests from the My Experience screen via the new self-service portal',
  CONFIG_PARAM_TOOLTIP_SEND_EMAIL_CATEGORIZATION: 'By enabling this parameter, an email will be sent when categorizing a ticket.',
  CONFIG_PARAM_TOOLTIP_SEND_EMAIL_CREATE_TASK_IN_TICKET: 'By enabling this parameter every time a task is created within a ticket, the responsible technical group will receive an email informing',
  CONFIG_PARAM_TOOLTIP_SHOW_REQUEST_CANCELLED_IN_CATEGORIZATION: 'By enabling this parameter, canceled requests will be shown on the categorization screen',
  CONFIG_PARAM_TOOLTIP_SUBTICKET_CLOSED_VERIFY: 'Calls can be finished regardless of having open subclauses',
  CONFIG_PARAM_TOOLTIP_TECHNICAL_EVALUATION_REGISTRATION: 'By enabling this parameter at the end of the ticket on the Ticket Management screen, the Technician will be able to place registered evaluations',
  CONFIG_PARAM_VISION_REQUEST: 'Number of days to present in the management view',
  CONFIG_PASSWORD_VAULT_BODY: 'Organize your passwords',
  CONFIG_POSITIONS_BODY: 'Create, edit and/or delete positions',
  CONFIG_PROFILE_BODY: 'Edit your profile, change your credentials whenever necessary',
  CONFIG_PROGRESS_STATUS_BODY: 'Create your statuses for ticket progress',
  CONFIG_PROJECT_TEMPLATE_BODY: 'Register a template to be used in the project',
  CONFIG_RISK_CHANGE_BODY: 'Create questions to assess the risks of the Change',
  CONFIG_SEND_MAIL: 'Email sending configuration',
  CONFIG_SLA_PARAMS_BODY: 'Sla registration',
  CONFIG_SLA_POLICY_BODY: 'Register the contract compliance SLA here',
  CONFIG_SLA_WORKDAY_BODY: 'Hours worked',
  CONFIG_SNMP_BODY: 'Register devices via network protocol',
  CONFIG_SPRINT_ACTIVITY_EFFORT_BODY: 'Configure the efforts of the activities of a Sprint',
  CONFIG_SQUAD_BODY: 'Configure Squad Body',
  CONFIG_SUBCATEGORIES_BODY: 'Organize activities for service in groups',
  CONFIG_SUBDEPARTMENT_BODY: 'Create, edit and/or delete subdepartments',
  CONFIG_THIRD_LEVEL_CATEGORY_BODY: 'Register all activities for service',
  CONFIG_USERS_BODY: 'Create, edit and/or delete users on the platform',
  CONFIG_USERS_PERMISSIONS: 'Create, edit, and/or delete user permissions on the platform',
  CONFIGURATION_MODULE: 'Configuration Module',
  CONFIGURE_COLUMNS: 'Configure Columns',
  CONFIGURE_MULTIPLE_TICKETS: 'Configure multi tickets',
  CONFIGURE_RELATIONSHIP_TREE: 'Configure Relationship Tree',
  CONFIGURE_RESOLUTION_CATEGORY: 'Configure Resolution Categories',
  CONFIGURE_RESOLUTION_CODE: 'Configure Resolution Codes',
  CONFIRM: 'Confirm',
  CONFIRM_APPROVAL: 'Confirm Approval',
  CONFIRM_CHANGE: 'Are You Sure to Open Change?',
  CONFIRM_CLOSE_ACESS_SUPPORT: 'Do you want to close support remote access?',
  CONFIRM_CLOSE_ASSESSMENT: 'Are you sure you want to end this service?',
  CONFIRM_CRISES: 'Confirm Crisis',
  CONFIRM_DELETE: 'Do you really want to delete this item?',
  CONFIRM_DELETE_BODY: 'After deleting this item you will no longer be able to recover it, be sure you want to delete it before proceeding.',
  CONFIRM_DELETE_BUSINESS_AREA: 'Do you want to delete business area?',
  CONFIRM_DELETE_COMPANY: 'Attention: When you delete the COMPANY, all items related to it will be deleted, do you really want to delete it?',
  CONFIRM_DELETE_ITEM: 'Do you want to delete this item?',
  CONFIRM_DETACH: 'Are you sure you want to remove the attach?',
  CONFIRM_DIALOG: 'Are You Sure You Want To Save ?',
  CONFIRM_DISABLE: 'Do you really want to disable this item?',
  CONFIRM_ENABLE: 'Do you really want to enable this item?',
  CONFIRM_PASSWORD: 'Confirm password',
  CONFIRM_SAVE_ITEM: 'Do you want to save this item?',
  CONFIRM_START_SERVICE: 'Do you want to start this service?',
  CONFIRM_STOP_MONITOR: 'Are you sure you want to stop monitoring this device?',
  CONFIRM_TICKETS_ASSESSMENT: 'Select below the tickets finalized in this assessment',
  CONFIRM_UPDATE_AGENT: 'Are you sure you want to update the agent for this device?',
  CONFIRMATION: 'Confirmation',
  CONNECT: 'Connect',
  CONNECTION: 'Connection',
  CONSULT_APPROVALS: 'Consult Approvals',
  CONSULT_HISTORIC: 'Consult history',
  CONSULT_INTEGRATION_CALL: 'Consult Integration',
  CONSULTANT: 'Consultant',
  CONSULTED: 'Consulted',
  CONSUMED: 'Consumed',
  CONSUMED_HOURS: 'Consumed Hours',
  CONSUMED_TIME: 'Hours consumed',
  CONSUMED_VALUE: 'Consumed value',
  CONSUMED_VALUES: 'Values Consumed',
  CONSUMER_SERVICE_SYSTEM: 'Consumer Service System',
  CONSUMPTION_OF_FORECAST_REALIZED_AND_CONSUMED: 'Consumption of predicted, Realized and consumed',
  CONTACT: 'Contact',
  CONTACTS: 'Contacts',
  CONTAIN: 'Contain',
  CONTEST: 'Contest',
  CONTINUE_PROJECT: 'Continuar Projeto',
  CONTRACT: 'Contract',
  CONTRACT_CONSUMPTION: 'Contract consumption',
  CONTRACT_DESCRIPTION: 'Efficient Contract Management that allows controlling and monitoring the hours of third parties.',
  CONTRACT_DETAILS: 'Contract Details',
  CONTRACT_FILTER: 'Contract Filter',
  CONTRACT_INVOLVED: 'Contract involved',
  CONTRACT_LIST: 'Contracts List',
  CONTRACT_MANAGEMENT: 'Contract Management',
  CONTRACT_OVERVIEW: 'Contract Overview',
  CONTRACT_SUPORT: 'Contract suport',
  CONTRACT_TITLE: 'Register Contract',
  CONTRACT_TYPE: 'Type of Contract',
  CONTRACT_TYPE_LIST: 'Contract Type List',
  CONTRACT_VALUE: 'Contract Value',
  CONTRACTED: 'Contracted',
  CONTRACTED_HOUR: 'Contracted Hour Price',
  CONTRACTED_HOURS: 'Contracted Hours',
  CONTRACTOR: 'Contractor',
  CONTRACTS: 'Contract',
  CONTRACTS_FILTER: 'Filter By Contracts',
  CONTRACTS_MANAGEMENT: 'Contracts Management',
  CONTRACTUAL_BILLING: 'Contractual Billing',
  CONTRIBUTION_TO_BUSINESS: 'Contribution to Business',
  CONTROL: 'Control',
  CORE: 'CORES',
  CORRECTION: 'Correction',
  CORRECTIVE: 'Corrective',
  CORRELATION: 'Correlation',
  CORRELATION_DISPLAY: 'Correlation Display',
  COST: 'Cost',
  COST_CENTER: 'Cost Center',
  COST_DESCRIPTION: 'Cost Description',
  COST_PER_HOUR: 'Cost per hour',
  COST_PER_PROJECT: 'Cost per project',
  COST_TITLE: 'Cost Title',
  COST_VALUE: 'Cost Value',
  COUNT: 'Count',
  COUNTRIES: 'Countries',
  COUNTRY: 'Country',
  COUNTRY_AFGHANISTAN: "Afghanistan",
  COUNTRY_ALBANIA: "Albania",
  COUNTRY_ALGERIA: "Algeria",
  COUNTRY_AMERICAN_SAMOA: "American Samoa",
  COUNTRY_ANDORRA: "Andorra",
  COUNTRY_ANGOLA: "Angola",
  COUNTRY_ANGUILLA: "Anguilla",
  COUNTRY_ANTIGUA_AND_BARBUDA: "Antigua and Barbuda",
  COUNTRY_ARGENTINA: "Argentina",
  COUNTRY_ARMENIA: "Armenia",
  COUNTRY_ARUBA: "Aruba",
  COUNTRY_ASCENSION_ISLAND: "Ascension Island",
  COUNTRY_AUSTRALIA: "Australia",
  COUNTRY_AUSTRIA: "Austria",
  COUNTRY_AZERBAIJAN: "Azerbaijan",
  COUNTRY_BAHAMAS: "Bahamas",
  COUNTRY_BAHRAIN: "Bahrain",
  COUNTRY_BANGLADESH: "Bangladesh",
  COUNTRY_BARBADOS: "Barbados",
  COUNTRY_BELARUS: "Belarus",
  COUNTRY_BELGIUM: "Belgium",
  COUNTRY_BELIZE: "Belize",
  COUNTRY_BENIN: "Benin",
  COUNTRY_BERMUDA: "Bermuda",
  COUNTRY_BHUTAN: "Bhutan",
  COUNTRY_BOLIVIA: "Bolivia",
  COUNTRY_BOSNIA_AND_HERZEGOVINA: "Bosnia and Herzegovina",
  COUNTRY_BOTSWANA: "Botswana",
  COUNTRY_BRAZIL: "Brazil",
  COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY: "British Indian Ocean Territory",
  COUNTRY_BRITISH_VIRGIN_ISLANDS: "British Virgin Islands",
  COUNTRY_BRUNEI: "Brunei",
  COUNTRY_BULGARIA: "Bulgaria",
  COUNTRY_BURKINA_FASO: "Burkina Faso",
  COUNTRY_BURUNDI: "Burundi",
  COUNTRY_CAMBODIA: "Cambodia",
  COUNTRY_CAMEROON: "Cameroon",
  COUNTRY_CANADA: "Canada",
  COUNTRY_CAPE_VERDE: "Cape Verde",
  COUNTRY_CAYMAN_ISLANDS: "Cayman Islands",
  COUNTRY_CENTRAL_AFRICAN_REPUBLIC: "Central African Republic",
  COUNTRY_CHAD: "Chad",
  COUNTRY_CHILE: "Chile",
  COUNTRY_CHINA: "China",
  COUNTRY_CHRISTMAS_ISLAND: "Christmas Island",
  COUNTRY_COCOS_ISLANDS: "Cocos (Keeling) Islands",
  COUNTRY_CODE: 'Country code',
  COUNTRY_COLOMBIA: "Colombia",
  COUNTRY_COMOROS: "Comoros",
  COUNTRY_CONGO: "Congo",
  COUNTRY_COOK_ISLANDS: "Cook Islands",
  COUNTRY_COSTA_RICA: "Costa Rica",
  COUNTRY_CROATIA: "Croatia",
  COUNTRY_CUBA: "Cuba",
  COUNTRY_CYPRUS: "Cyprus",
  COUNTRY_CZECH_REPUBLIC: "Czech Republic",
  COUNTRY_DENMARK: "Denmark",
  COUNTRY_DJIBOUTI: "Djibouti",
  COUNTRY_DOMINICA: "Dominica",
  COUNTRY_DOMINICAN_REPUBLIC: "Dominican Republic",
  COUNTRY_ECUADOR: "Ecuador",
  COUNTRY_EGYPT: "Egypt",
  COUNTRY_EL_SALVADOR: "El Salvador",
  COUNTRY_EQUATORIAL_GUINEA: "Equatorial Guinea",
  COUNTRY_ERITREA: "Eritrea",
  COUNTRY_ESTONIA: "Estonia",
  COUNTRY_ESWATINI: "Eswatini",
  COUNTRY_ETHIOPIA: "Ethiopia",
  COUNTRY_FALKLAND_ISLANDS: "Falkland Islands",
  COUNTRY_FAROE_ISLANDS: "Faroe Islands",
  COUNTRY_FIJI: "Fiji",
  COUNTRY_FINLAND: "Finland",
  COUNTRY_FRANCE: "France",
  COUNTRY_FRENCH_GUIANA: "French Guiana",
  COUNTRY_FRENCH_POLYNESIA: "French Polynesia",
  COUNTRY_GABON: "Gabon",
  COUNTRY_GAMBIA: "Gambia",
  COUNTRY_GAZA_STRIP: "Gaza Strip",
  COUNTRY_GEORGIA: "Georgia",
  COUNTRY_GERMANY: "Germany",
  COUNTRY_GHANA: "Ghana",
  COUNTRY_GIBRALTAR: "Gibraltar",
  COUNTRY_GREECE: "Greece",
  COUNTRY_GREENLAND: "Greenland",
  COUNTRY_GRENADA: "Grenada",
  COUNTRY_GUADELOUPE: "Guadeloupe",
  COUNTRY_GUAM: "Guam",
  COUNTRY_GUATEMALA: "Guatemala",
  COUNTRY_GUINEA: "Guinea",
  COUNTRY_GUINEA_BISSAU: "Guinea-Bissau",
  COUNTRY_GUYANA: "Guyana",
  COUNTRY_HAITI: "Haiti",
  COUNTRY_HONDURAS: "Honduras",
  COUNTRY_HONG_KONG_SAR: "Hong Kong SAR",
  COUNTRY_HUNGARY: "Hungary",
  COUNTRY_ICELAND: "Iceland",
  COUNTRY_INDIA: "India",
  COUNTRY_INDONESIA: "Indonesia",
  COUNTRY_IRAN: "Iran",
  COUNTRY_IRAQ: "Iraq",
  COUNTRY_IRELAND: "Ireland",
  COUNTRY_ISLE_OF_MAN: "Isle of Man",
  COUNTRY_ISRAEL: "Israel",
  COUNTRY_ITALY: "Italy",
  COUNTRY_IVORY_COAST: "Ivory Coast",
  COUNTRY_JAMAICA: "Jamaica",
  COUNTRY_JAPAN: "Japan",
  COUNTRY_JARVIS_ISLAND: "Jarvis Island",
  COUNTRY_JORDAN: "Jordan",
  COUNTRY_KAZAKHSTAN: "Kazakhstan",
  COUNTRY_KENYA: "Kenya",
  COUNTRY_KIRIBATI: "Kiribati",
  COUNTRY_KUWAIT: "Kuwait",
  COUNTRY_KYRGYZSTAN: "Kyrgyzstan",
  COUNTRY_LAOS: "Laos",
  COUNTRY_LATVIA: "Latvia",
  COUNTRY_LEBANON: "Lebanon",
  COUNTRY_LESOTHO: "Lesotho",
  COUNTRY_LIBERIA: "Liberia",
  COUNTRY_LIBYA: "Libya",
  COUNTRY_LIECHTENSTEIN: "Liechtenstein",
  COUNTRY_LITHUANIA: "Lithuania",
  COUNTRY_LUXEMBOURG: "Luxembourg",
  COUNTRY_MACAU: "Macau",
  COUNTRY_MADAGASCAR: "Madagascar",
  COUNTRY_MALAWI: "Malawi",
  COUNTRY_MALAYSIA: "Malaysia",
  COUNTRY_MALDIVES: "Maldives",
  COUNTRY_MALI: "Mali",
  COUNTRY_MALTA: "Malta",
  COUNTRY_MARSHALL_ISLANDS: "Marshall Islands",
  COUNTRY_MARTINIQUE: "Martinique",
  COUNTRY_MAURITANIA: "Mauritania",
  COUNTRY_MAURITIUS: "Mauritius",
  COUNTRY_MAYOTTE: "Mayotte",
  COUNTRY_MEXICO: "Mexico",
  COUNTRY_MICRONESIA: "Micronesia",
  COUNTRY_MONACO: "Monaco",
  COUNTRY_MONGOLIA: "Mongolia",
  COUNTRY_MONTENEGRO: "Montenegro",
  COUNTRY_MONTSERRAT: "Montserrat",
  COUNTRY_MOROCCO: "Morocco",
  COUNTRY_MOZAMBIQUE: "Mozambique",
  COUNTRY_MYANMAR: "Burma",
  COUNTRY_MYANMAR: "Myanmar",
  COUNTRY_NAMIBIA: "Namibia",
  COUNTRY_NAURU: "Nauru",
  COUNTRY_NEPAL: "Nepal",
  COUNTRY_NETHERLANDS: "Netherlands",
  COUNTRY_NETHERLANDS_ANTILLES: "Netherlands Antilles",
  COUNTRY_NEW_CALEDONIA: "New Caledonia",
  COUNTRY_NEW_ZEALAND: "New Zealand",
  COUNTRY_NICARAGUA: "Nicaragua",
  COUNTRY_NIGER: "Niger",
  COUNTRY_NIGERIA: "Nigeria",
  COUNTRY_NIUE: "Niue",
  COUNTRY_NORFOLK_ISLAND: "Norfolk Island",
  COUNTRY_NORTH_MACEDONIA: "North Macedonia",
  COUNTRY_NORTHERN_CYPRUS: "Northern Cyprus",
  COUNTRY_NORTHERN_MARIANA_ISLANDS: "Northern Mariana Islands",
  COUNTRY_NORWAY: "Norway",
  COUNTRY_OMAN: "Oman",
  COUNTRY_PAKISTAN: "Pakistan",
  COUNTRY_PALAU: "Palau",
  COUNTRY_PANAMA: "Panama",
  COUNTRY_PAPUA_NEW_GUINEA: "Papua New Guinea",
  COUNTRY_PARAGUAY: "Paraguay",
  COUNTRY_PERU: "Peru",
  COUNTRY_PHILIPPINES: "Philippines",
  COUNTRY_PITCAIRN_ISLAND: "Pitcairn Island",
  COUNTRY_POLAND: "Poland",
  COUNTRY_PORTUGAL: "Portugal",
  COUNTRY_PUERTO_RICO: "Puerto Rico",
  COUNTRY_QATAR: "Qatar",
  COUNTRY_REUNION: "Reunion",
  COUNTRY_ROMANIA: "Romania",
  COUNTRY_RUSSIA: "Russia",
  COUNTRY_RWANDA: "Rwanda",
  COUNTRY_SAINT_HELENA: "Saint Helena",
  COUNTRY_SAINT_KITTS_AND_NEVIS: "Saint Kitts and Nevis",
  COUNTRY_SAINT_LUCIA: "Saint Lucia",
  COUNTRY_SAINT_PIERRE_AND_MIQUELON: "Saint Pierre and Miquelon",
  COUNTRY_SAINT_VINCENT_AND_THE_GRENADINES: "Saint Vincent and the Grenadines",
  COUNTRY_SAMOA: "Samoa",
  COUNTRY_SAN_MARINO: "San Marino",
  COUNTRY_SAO_TOME_AND_PRINCIPE: "Sao Tome and Principe",
  COUNTRY_SAUDI_ARABIA: "Saudi Arabia",
  COUNTRY_SENEGAL: "Senegal",
  COUNTRY_SERBIA: "Serbia",
  COUNTRY_SERBIA_AND_MONTENEGRO: "Serbia and Montenegro",
  COUNTRY_SEYCHELLES: "Seychelles",
  COUNTRY_SIERRA_LEONE: "Sierra Leone",
  COUNTRY_SINGAPORE: "Singapore",
  COUNTRY_SLOVAKIA: "Slovakia",
  COUNTRY_SLOVENIA: "Slovenia",
  COUNTRY_SOLOMON_ISLANDS: "Solomon Islands",
  COUNTRY_SOMALIA: "Somalia",
  COUNTRY_SOUTH_AFRICA: "South Africa",
  COUNTRY_SOUTH_GEORGIA_AND_SOUTH_SANDWICH_ISLANDS: "South Georgia and South Sandwich Islands",
  COUNTRY_SOUTH_KOREA: "South Korea",
  COUNTRY_SOUTH_SANDWICH_ISLANDS: "South Sandwich Islands",
  COUNTRY_SPAIN: "Spain",
  COUNTRY_SRI_LANKA: "Sri Lanka",
  COUNTRY_SUDAN: "Sudan",
  COUNTRY_SURINAME: "Suriname",
  COUNTRY_SVALBARD_AND_JAN_MAYEN: "Svalbard and Jan Mayen",
  COUNTRY_SWEDEN: "Sweden",
  COUNTRY_SWITZERLAND: "Switzerland",
  COUNTRY_SYRIA: "Syria",
  COUNTRY_TAIWAN: "Taiwan",
  COUNTRY_TAJIKISTAN: "Tajikistan",
  COUNTRY_TANZANIA: "Tanzania",
  COUNTRY_THAILAND: "Thailand",
  COUNTRY_TIMOR_LESTE: "Timor-Leste",
  COUNTRY_TOGO: "Togo",
  COUNTRY_TOKELAU: "Tokelau",
  COUNTRY_TONGA: "Tonga",
  COUNTRY_TRINIDAD_AND_TOBAGO: "Trinidad and Tobago",
  COUNTRY_TRISTAN_DA_CUNHA: "Tristan da Cunha",
  COUNTRY_TUNISIA: "Tunisia",
  COUNTRY_TURKEY: "Turkey",
  COUNTRY_TURKMENISTAN: "Turkmenistan",
  COUNTRY_TURKS_AND_CAICOS_ISLANDS: "Turks and Caicos Islands",
  COUNTRY_TUVALU: "Tuvalu",
  COUNTRY_UGANDA: "Uganda",
  COUNTRY_UKRAINE: "Ukraine",
  COUNTRY_UNITED_ARAB_EMIRATES: "United Arab Emirates",
  COUNTRY_UNITED_KINGDOM: "United Kingdom",
  COUNTRY_UNITED_STATES: "USA",
  COUNTRY_URUGUAY: "Uruguay",
  COUNTRY_US_VIRGIN_ISLANDS: "US Virgin Islands",
  COUNTRY_UZBEKISTAN: "Uzbekistan",
  COUNTRY_VANUATU: "Vanuatu",
  COUNTRY_VATICAN_CITY: "Vatican City",
  COUNTRY_VENEZUELA: "Venezuela",
  COUNTRY_VIETNAM: "Vietnam",
  COUNTRY_WALLIS_AND_FUTUNA: "Wallis and Futuna",
  COUNTRY_WEST_BANK: "West Bank",
  COUNTRY_WESTERN_SAHARA: "Western Sahara",
  COUNTRY_YEMEN: "Yemen",
  COUNTRY_YUGOSLAVIA: "Yugoslavia",
  COUNTRY_ZAIRE: "Zaire",
  COUNTRY_ZAMBIA: "Zambia",
  COUNTRY_ZIMBABWE: "Zimbabwe",
  CPF: 'Social Security Numbers',
  CPF_CNPJ_ALREADY_EXISTIS: 'CPF/CNPJ already existis in the database',
  CPU: 'CPU',
  CPU_CAPTION: 'CPU caption',
  CPU_USAGE: 'CPU Usage(%)',
  CREATE: 'Created',
  CREATE_ATTENDANCE: 'Create Attendance',
  CREATE_EDIT_AND_OR_EXCLUDE_RELATIONSHIP_TYPES: 'Create, edit and/or exclude relationship types',
  CREATE_OR_ADD_TAGS_ACTIVITY: 'Create or add tags to this activity',
  CREATE_OR_ADD_THIS_ACTIVITY_TO_AN_ACTIVITY_GROUP: 'Create or add this activity to an activity group',
  CREATE_PRIORITY_CUSTOM_GLOBAL_SETTING: 'Create priority custom global setting',
  CREATE_SUB_CALL: 'Create Sub Call',
  CREATE_TAG: 'Create Tag',
  CREATE_TASK_FLOW: 'Create Task Flow',
  CREATE_TEMPLATE_BASED_GMUD: 'Create Template Based Change?',
  CREATE_WORKFLOW: 'Create Workflow',
  CREATE_YOUR_OWN_MASTER_PASSWORD: 'Create your master password',
  CREATED: 'Created',
  CREATED_IN: 'Created in',
  CREATOR: 'Creator',
  CRISIS_GROUP: 'Crisis group',
  CRISIS_IS_CONFIRMED: 'Crisis is confirmed',
  CRISIS_IS_UNCONFIRMED: 'Crisis is unconfirmed',
  CRISIS_PLURAL: 'Crisis',
  CRITICAL: 'Critical',
  CRITICAL_UNIT: 'Critical Unit',
  CRITICALITY: 'Criticality',
  CURRENCY: 'US$',
  CURRENT: 'Current',
  CURRENT_MATURITY: 'Current maturity',
  CURRENT_MONTH_AVAILABILITY: 'Current month availability',
  CUST: 'Cust',
  CUSTOMER_DATA: 'Customer Data',
  CUSTOMIZE_PRIORITY: 'Customize priority',
  CUSTOMIZED: 'Customized',
  DAILY: 'Daily',
  DANGER: 'Danger',
  DANGEROUS: 'DANGEROUS',
  DASH: {
    SLA_BURST_CLOSED: 'SLA Burst of Foreclosed'
  },
  DASHBOARD: 'Dashboard',
  DASHBOARD_G: 'Vision Manager',
  DASHBOARD_PAGE: {
    ATTENDANCE: 'Attendance Service',
    DM: 'Demand Management',
    GCF: 'Contracts and Suppliers Management',
    GI: 'Infrastructure Management',
    GSD: 'Service Desk Management',
    SUB_TITLE: 'Information Panel',
    TITLE: 'Dashboard Tickets'
  },
  DATA_LOG: 'Data log',
  DATA_SENT_SUCCESSFULLY: 'Data sent successfully',
  DATABASE_SUPPORT: 'Database Support',
  DATAMAT: {
    mat: ['', 'Initial / Ad Hoc', ' Repeatable but intuitive', 'Defined Processes', 'Managed and Measured', 'Optimized']
  },
  DATE: 'Date',
  DATE_ATTENDANCE: 'Attendance date',
  DATE_CAB: 'CAB Date',
  DATE_CAD: 'Date Register',
  DATE_CLOSED_RELATED: 'Related Record Closed Date',
  DATE_DATE_FINAL: 'Date Date - Final',
  DATE_DATE_INITIAL: 'Date Date - Initial',
  DATE_END_RELATED_GMUD: 'Final date of execution of the Change Record',
  DATE_FOR_THE_ACTIVITY: 'Date for the activity',
  DATE_INITIAL_STEP: 'Data Ini. Etapa',
  DATE_LANG: {
    dayAbbreviation: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    dayFullList: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayList: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    monthList: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    monthLongList: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  },
  DATE_LAST_CHANGE: 'Last Change Date',
  DATE_NORMALIZATION: 'Date of Normalization',
  DATE_OPTIONS: 'Date Options',
  DATE_OUTSIDE_PROJECT_PERIOD: 'Date outside the project period',
  DATE_REGISTER: 'Date register',
  DATE_REMOVED: 'Date Removed',
  DATE_START_PRODUCTION: 'Data to Activate in Production',
  DATE_START_RELATED: 'Related Record Create Date',
  DATE_START_RELATED_GMUD: 'Start date of execution of the Change Record',
  DATE_TARGET: 'Target date',
  DATE_TIME: 'Date and Time',
  DATE_WITH: 'By Date of',
  DAY: 'Day',
  DAYS: 'days',
  DAYS_AUTO_CLOSE: 'Days to auto close',
  DAYS_IN_MONTH: 'Days in the month',
  DC: 'DC',
  DEADLINE: 'Deadline',
  DECIMAL_PLACES: 'Cecimal places',
  DECREASING: 'Decreasing',
  DEFAULT: 'Default',
  DEFAULT_CHANNEL_TICKETS: 'Canal predeterminado en tickets',
  DEFAULT_COMPANY_CRISIS: 'Default compnay in crisis',
  DEFAULT_COMPANY_PROJETS: 'Default company in projects',
  DEFAULT_GROUP_FALE: 'Grupo padrão FALE',
  DEFINE_LIKE: 'Define like',
  DELAYED: 'Delayed',
  DELETE: 'Delete',
  DELETE_ITEM: 'Exclui item',
  DELETE_ONE_OR_MORE_ITEM: 'Remove one or more items',
  DELETE_PERSPECTIVE: 'Delete Perspective',
  DELETE2: 'Deletar',
  DELIVERY_DATE: 'Delivery date',
  DELIVERY_MANAGER: 'Delivery Manager',
  DEMAND_MANAGEMENT: 'Demand Management',
  DEMANDS: 'Demands',
  DEPARTMENT: 'Department',
  DEPARTMENT_BOSS: 'Department Boss',
  DEPARTMENT_DESCRIPTION: 'Department is a separate division or segment of a company',
  DEPARTMENT_INVOLVED: 'Departments Involved',
  DEPARTMENT_LIST: 'Department List',
  DEPARTMENT_NAME: 'Department name',
  DEPARTMENT_STATUS_CHART: 'Department status chart',
  DEPARTMENT_TABLE: 'Department Tables',
  DEPARTMENTS: 'Departments',
  DEPARTMENTS_SUBDEPARTMENTS: 'Departments/Sub-Departments',
  DEPARTURE_DATE: 'Departure date',
  DESCRIBE_ANALYZE_PROBLEM: 'Analysis: Describe the analysis performed',
  DESCRIBE_THE_GOALS_OF_THIS_PROJECT: 'Describe the goals of this project',
  DESCRIBE_THE_RISKS_INVOLVED_IN_THIS_PROJECT: 'Describe the risks involved in this project',
  DESCRIBE_THE_RISKS_THAT_SURROUND_THIS_PROJECT: 'Describe the risks that surround this project',
  DESCRIPTION: 'Description',
  DESCRIPTION_CANNOT_BE_BLANK: 'Description cannot be blank',
  DESCRIPTION_OF_CONTRACT: 'description of contract',
  DESIGNATED_ANALYST: 'Designated Analyst',
  DESIGNATION_HISTORY: 'Designation history',
  DESIRED_MATURITY: 'Desired maturity',
  DESKTOP: 'Desktop',
  DETAILED_VIEW: 'Detailed View',
  DETAILS: 'Details',
  DETAILS_OF_THE_CALLS_MADE: 'Details of the calls made',
  DETRACTORS: 'Detractors',
  DEVELOP: 'Development',
  DEVELOPMENT: 'development',
  DEVICE: 'Device',
  DEVICE_ASSOCIATED_WITH_ANOTHER_GMUD: 'Device associated with another Change',
  DEVICE_ATTACH: 'Attach Device',
  DEVICE_ATTACH_WARNING: 'All available devices are already attached to this contract',
  DEVICE_CONF: 'Configuration Items',
  DEVICE_CONFIG: 'Device Configurations',
  DEVICE_CONFIGURATION: 'Device configuration',
  DEVICE_DESCRIPTION: 'A set of conditions that a ticket must satisfy in order for the SLA policy to be applied.',
  DEVICE_IC: 'Device IC',
  DEVICE_INFO: 'Device Information',
  DEVICE_LIST: 'Device List',
  DEVICE_MAP: 'Device map',
  DEVICE_MONITORING: 'Device Monitoring',
  DEVICE_NAME: 'Device name',
  DEVICE_STATUS: 'Device Status',
  DEVICE_TABLE: 'Device table',
  DEVICE_TITLE: 'Register Devices',
  DEVICE_TYPE: 'Type of Device',
  DEVICE_TYPE_DESCRIPTION: 'Register Device Types',
  DEVICE_TYPE_IC: 'Type of Device IC',
  DEVICES: 'Devices',
  DEVICES_GROUP: 'Devices Group',
  DEVICES_GROUP_DETAIL: 'Devices Group Details',
  DEVICES_SERVERS_IMPACTED: 'Devices (servers) impacted',
  DIFFERENCE_BETWEEN_EXPECTED_AND_CONSUMED: 'Difference between Expected and Consumed',
  DIFFERENT_FROM: 'Different from',
  DIRECT_LEADER: 'Direct Leader',
  DIRECTOR: 'Director',
  DISABLE: 'Desabilitar',
  DISABLE_AUTOMATIC_LOADING: 'Disable automatic loading',
  DISABLE_FIELDS: 'Disable fields',
  DISABLE_TEMPLATE_LIST: 'Disable in template lists',
  DISABLED: 'Desabilitada',
  DISCARD: 'discard',
  DISCHECK_ALL: 'Uncheck All',
  DISCOVERY: 'Discovery',
  DISK: 'Disks',
  DISK_HEADER: 'Disk header',
  DISK_SECTOR: 'Disk sector',
  DISK_TRACKS: 'Disk tracks',
  DISK_USAGE: {
    boot: 'Disk Usage /boot',
    home: 'Disk Usage /home',
    repo: 'Disk Usage /repo',
    root: 'Disk Usage /',
    tmp: 'Disk Usage /tmp',
    tools: 'Disk Usage /tools',
    u01: 'Disk Usage /u01',
    usr: 'Disk Usage /usr',
    var: 'Disk Usage /var',
    varLog: 'Disk Usage /var/log'
  },
  DISTRIBUTE_TICKETS_AUTOMATICALLY: 'Distribute tickets automatically',
  DISTRICT: 'District',
  DISTRO: 'Distro',
  DIVISION: 'Division',
  DIVISIONS: 'Divisions',
  DO_YOU_REALLY_WANT_TO_APPROVE_DOCUMENTATION: 'Do you really want to Approve Documentation?',
  DO_YOU_REALLY_WANT_TO_APPROVE_MATURITY: 'Do you really want to Approve Maturity?',
  DO_YOU_REALLY_WANT_TO_DELETE_THE_SPRINT: 'Do you really want to delete the SPRINT',
  DO_YOU_REALLY_WANT_TO_DELETE_THIS_FAVORITE: 'Do you really want to delete this bookmark?',
  DO_YOU_WANT_REMOVE_THIS_ITEM: 'Deseja remover esse item?',
  DO_YOU_WANT_TO_CHANGE_THE_LANGUAGE: 'Do you want to change the language?',
  DO_YOU_WANT_TO_CHANGE_YOUR_TIMEZONE: 'Want to change your timezone?',
  DO_YOU_WANT_TO_CREATE_THE_PROJECT_FROM_A_TEMPLATE: 'Do you want to create the project from a Template?',
  DO_YOU_WANT_TO_PUT_ACTIVITY_IN_PROGRESS: 'Do you want to put activity in progress?',
  DO_YOU_WANT_TO_REMOVE_ACTIVITY: 'Do you want to remove activity?',
  DO_YOU_WANT_TO_REMOVE_SUB_ACTIVITY: 'Do you want to remove the sub-activity?',
  DO_YOU_WANT_TO_REMOVE_THE_EXPECTED_RESULT: 'Do you want to remove the expected result?',
  DO_YOU_WANT_TO_REMOVE_THIS_COST: 'DESEJA REMOVER ESTE CUSTO?',
  DOC: 'DOcument',
  DOCS: 'Documents',
  DOING: 'Doing',
  DOMAIN: 'Domain',
  DONT_COUNT: "Don't count",
  DOWNLOAD: 'Download',
  DOWNLOAD_ATTACHMENT: 'Download Attachment',
  DOWNLOAD_CSV: 'Download CSV',
  DOWNLOAD_JPEG: 'Download JPEG image',
  DOWNLOAD_MIDI: 'Download MIDI',
  DOWNLOAD_PDF: 'Download PDF document',
  DOWNLOAD_PNG: 'Download PNG image',
  DOWNLOAD_READY: 'Download Successful!',
  DOWNLOAD_SVG: 'Download SVG image',
  DOWNLOAD_XLS: 'Download XLS',
  DOWNTIME: 'Downtime',
  DRAG_AND_DROP_AN_IMAGE_FILE_HERE_OR_CLICK: 'Drag and drop an image file here or click',
  DRAG_THE_FILE: 'Drag the File or Click in Icon below',
  DT_CAD: 'Date Create',
  DUPLICATE: 'Duplicate',
  DUPLICATE_FIELDS_WORKFLOW: 'There cannot be repeated levels.',
  ECONOMY: 'Economy',
  EDIT: 'Edit',
  EDIT_ASSIGNMENT: 'Edit Assignment Add',
  EDIT_BLACKOUT: 'Edit Blackout',
  EDIT_BUILD: 'Edit Build',
  EDIT_CATALOG: 'Edit Services Catalog',
  EDIT_CATEGORY: 'Edit Category',
  EDIT_CHANGE: 'Edit Change',
  EDIT_COMPANY: 'Edit Company',
  EDIT_CONFIGURATION: 'Edit Configurations',
  EDIT_CONTRACT: 'Edit Contract',
  EDIT_CRISIS_CONFIRMATION: 'Edit crisis confirmation',
  EDIT_DEPARTMENT: 'Edit Department',
  EDIT_DEVICE: 'Edit Device',
  EDIT_EXPECTED_RESULT: 'Edit Expected Result',
  EDIT_FORM: 'Edit Form',
  EDIT_GROUP: 'Edit Group',
  EDIT_MAIL: 'Edit Mail',
  EDIT_NEW_TASK: 'Edit new task',
  EDIT_OR_ADD: 'Edit or add',
  EDIT_OUTSOURCED: 'Edit Outsourced',
  EDIT_POSITION: 'EDIT POSITION',
  EDIT_SERVICE_ORDER: 'Edit Service Order',
  EDIT_SLA: 'Edit SLA',
  EDIT_SOLICITANT: 'Edit Requester',
  EDIT_SPONSOR: 'Edit Sponsor',
  EDIT_SUB_ACTIVITIES: 'Edit Sub-Activities',
  EDIT_SUBDEPARTMENT: 'Edit Subdepartment',
  EDIT_TAG: 'Edit Tag',
  EDIT_TASK: 'Edit Task',
  EDIT_TECH: 'Edit Tech',
  EDIT_TICKETS: 'Editar Tickets',
  EDITING_OF_TECH_GMUD_AFTER_APPROVAL: 'Editing of technical gmud after approval',
  EDITING_SUBACTIVITY_STATUS: 'Editing Subactivity Status',
  EDITION: 'Edition',
  EDITION_OF_SUBACTIVITY_STATUS: 'Edition of subactivity status',
  EDITOR: 'Editor',
  EDITPROJECT: 'Edit Project',
  EFFECTIVENESS_ANALYSIS: 'Effectiveness Analysis',
  EFFICIENCY: 'Efficiency',
  EFFORT: 'Effort',
  EFFORT_VALUE: 'Effort Value',
  EMAIL: 'E-mail',
  EMAIL_ALREADY_REGISTERED: 'E-mail already registered',
  EMAIL_BODY: 'E-mail body',
  EMAIL_POLICY_DESCRIPTION: 'E-mail Policy Description',
  EMAIL_SUCCESSFULLY_RESENT: 'E-mail successfully resent',
  EMAIL_TEMPLATES: 'E-mail Templates',
  EMAILS_SENT_SUCCESSFULLY: 'E-mails successfully sent',
  EMERGENCI_CHART_TECH: 'Emergency Chart by Technicians',
  EMERGENCY: 'Emergency',
  EMPTY: 'Empty',
  ENABLE: 'enable',
  ENABLE_ASSET_INVOLVED_TICKET_CREATION: 'Enable Asset Involved in ticket creation',
  ENABLE_AUTOMATIC_LOADING: 'Enable automatic loading',
  ENABLE_COMPANY_CATALOG_GROUPING: 'Enable company catalog grouping',
  ENABLE_CRISIS_TICKET_TYPE: 'Enable Crisis ticket type',
  ENABLE_CRITICAL_INCIDENT_TICKET_TYPE: 'Enable Critical Incident ticket type',
  ENABLE_EVENT_TICKET_TYPE: 'Enable Event ticket type',
  ENABLE_GENERAL_APPROVE_REPROVE_BUTTON_GMUD: 'Enable rules to show buttons (Approve/Disapprove) in change management',
  ENABLE_GMUD_APPROVERS_WORKFLOW: 'Enable GMUD approvers workflow',
  ENABLE_GMUD_FORMS_FILTER: 'Enable GMUD forms filter',
  ENABLE_GMUD_LEADERS_AS_APPROVERS: 'Enable sponsors and alternative sponsors to approve GMUD workflow',
  ENABLE_NOTIFICATION_CENTER: 'Enable Notification Center',
  ENABLE_REQUIREMENT_CHANNEL_TICKET: 'Enable mandatory channels in tickets',
  ENABLE_SSO: 'Enable SSO',
  ENABLE_TICKET_LISTING_FOR_ASSOCIATED_COMPANIES: 'Enable ticket listing for associated companies',
  ENABLE_TICKET_LISTING_FOR_THE_DEPARTMENT: 'Enable ticket listing for the department',
  ENABLED: 'enabled',
  END: 'End',
  END_: 'End',
  END_CALL: 'end call',
  END_DATE: 'End date',
  END_DATE_BREAK: 'End Date Break',
  END_HOUR: 'End Hour',
  END_OF_PROJECT: 'End of project',
  END_OUTAGE: 'End Outage',
  END_RECURRENCE: 'End of recurrence',
  END_SELECTED_TICKETS: 'End selected tickets',
  END_TIME: 'End Time',
  END_TIME_BREAK: 'End Time Break',
  ENDPOINT_MANAGEMENT: 'Endpoint Management',
  ENDS_WITH: 'Ends with',
  ENTER: 'Enter',
  ENTER_DESCRIPTION: 'Enter the description',
  ENTER_END_DATE_CHANGE: 'Enter the end date of this change.',
  ENTER_EVOLUTION: 'Enter the evolution',
  ENTER_TEMPLATE_BELOW: 'Enter the template below',
  ENTER_THE_CORRECT_AMOUNT_OF_ACTIVITY_EFFORT: 'Enter the correct amount of activity effort',
  ENTER_THE_CORRECT_TICKET_EFFORT_VALUE: 'Enter the correct ticket effort value',
  ENTER_THE_END_DATE_OF_THE_SPRINT: 'Enter the end date of the sprint',
  ENTER_THE_END_TIME_OF_THE_SPRINT: 'Enter the end time of the sprint',
  ENTER_THE_NAME_OF_THE_SPRINT: 'Enter the name of the Sprint',
  ENTER_THE_START_DATE: 'Enter the start date',
  ENTER_THE_START_DATE_OF_THE_SPRINT: 'Enter the start date of the sprint',
  ENTER_THE_START_TIME_OF_THE_SPRINT: 'Enter the start time of the sprint',
  ENTER_TICKET_PRIORITY: 'Enter ticket priority',
  ENTER_VALID_TIME_VALUE: 'Enter a valid time value',
  ENTRY_DATE: 'Entry Date',
  EQUAL_TO: 'Equal to',
  EQUIPELOCATION: 'EQUIPE LOCATION',
  EQUIPMENT: 'Equipment',
  ERASE: 'Erase',
  ERRO_PJ_AC: 'There are registered activities with start date less than project date',
  ERROR_CODE: {
    E001: "Old password don't match",
    E002: "Password cano't be like the last one",
    E007: 'Flow Error',
    E008: 'You are not allowed to take this action',
    E009: 'Review already submitted',
    E010: 'Videos were not found',
    E011: 'User already registered',
    E012: 'end date can not be less than the starting date',
    E019: 'Atividade vinculada não foi concluida!',
    E020: 'Value not allowed',
    E404: 'Route not found',
    E500: 'Internal Server Error'
  },
  ERROR_LOADING_CURRENT_SMTP_CONFIGURATION: 'Error loading current SMTP configuration',
  ERROR_LOADING_SMTP_LIST: 'Error loading SMTP list',
  ERROR_PASSWORD_NOT_ALLOWED: 'The password does not meet the required criteria.',
  ERROR_PROCESSING: 'Processing Error',
  ERROR_REQUEST: 'Error Request',
  ERROR_SELECTING_SMTP: 'Error selecting SMTP',
  ESCALATION_INFORMATION: 'Escalation Information',
  ESTIMATED_COMPLETION_DATE: 'Estimated Completion Date',
  ESTIMATED_TIME: 'Estimated Time',
  EVALUATE: 'Evaluate OS',
  EVALUATE_CALL: 'Evaluate Call',
  EVALUATE1: 'Evaluate',
  EVALUATION_REGARDING_ATTENDANCE_TEAM: 'from 0 to 10, what was your experience with our service team?',
  EVALUATION_REGARDING_CALL_RESOLUTION_QUALITY: 'from 0 to 10, what is your evaluation regarding the quality of the call resolution?',
  EVALUATION_REGARDING_CALL_RESOLUTION_TIME: 'from 0 to 10, what is your evaluation regarding the call resolution time?',
  EVALUTION: 'Evaluation',
  EVENT: 'Event',
  EVENT_CAB_ALERT: 'Successfully changed CAB event',
  EVENTS: 'Events',
  EVIDENCE: 'Evidence',
  EVIDENCE_DESCRIPTION: 'Evidence Description',
  EVIDENCES: 'Evidences',
  EVOLU: 'Project Evolution',
  EVOLUTION: 'Evolution',
  EVOLUTION_DATE: 'Evolution date',
  EVOLUTION_KPIS: "Evolution KPI's",
  EVOLUTION_OF_ACTIVITIES: 'Evolution of Activities',
  EVOLUTION_OF_TESTS: 'EVOLUTION OF TESTS',
  EVOLUTION_OF_TICKETS: 'EVOLUTION OF TICKETS',
  EVOLUTION_PERCENTAGE: 'Evolution Percentage',
  EVOLUTION_STATUS: 'Evolution Status',
  EVOLUTION_TESTS_PLANS: '% Evolution Tests Plans',
  EVOLUTIONARY: 'Evolutionary',
  EXCEEDED: 'Exceeded',
  EXECUTED: 'Executed',
  EXECUTION: 'Execution',
  EXECUTION_ANNEXES: 'Execution annexes',
  EXECUTOR: 'Executor',
  EXPECTED_COST: 'Expected Cost',
  EXPECTED_END: 'Expected End',
  EXPECTED_RESULT: 'Expected Result',
  EXPECTED_START: 'Expected start',
  EXPENSE: 'Expense',
  EXPENSES: 'Expenses',
  EXPERIENCE: 'Experience',
  EXPIRATION_DATE: 'Expiration Date',
  EXPIRE: 'Expire',
  EXPIRE_THIS_WEEK: 'Expired this week',
  EXPIRE_TODAY: 'Expired today',
  EXPIRED: 'Expired',
  EXPIRED_SLA: 'Expired SLA',
  EXPIRY_DATE: 'Expiry Date',
  EXPORT: 'Export',
  EXPORT_COMPANIES: 'Export Companies',
  EXPORT_DATE: 'Export Data',
  EXPORT_DEVICES: 'Export Devices',
  EXPORT_EXCEL: 'Export excel',
  EXPORT_INVENTORY: 'Export Inventory',
  EXPORT_LOCATION: 'Export location',
  EXPORT_SOFTWARE: 'Export Softwares',
  EXPORT_TASKS: 'Export Tasks',
  EXPORT_TO_EXCEL: 'Export to excel',
  EXTEND_OS: 'Extend OS',
  EXTENSION: 'Extension',
  EXTERNAL: 'External',
  FACE_TO_FACE: 'Face-to-face',
  FACTORY: 'Factory',
  FAIL: 'Fail',
  FAILURE: 'failure',
  FARM: 'Farm',
  FAVORITES: 'favorites',
  FIELD: 'Field',
  FIELD: 'Field',
  FIELD_EXTENSION: 'Extension (Add .* to release all file extensions)',
  FIELD_TYPE: 'Field type',
  FILE: 'File',
  FILE_EXTENSIONS: 'File Extensions',
  FILE_NAME: 'File Name',
  FILE_SYSTEM: 'File type',
  FILE_TYPE: 'File Type',
  FILES: 'Files',
  FILL_IN_ALL_FIELDS: 'Fill in all fields',
  FILL_REQUIRED: 'Fill in the Required Fields!',
  FILL_THE_FIELD: 'Fill the field',
  FILTER: 'Filter',
  FILTER_BY: 'Filter by',
  FILTER_CONDITION: 'Filter Condition',
  FINAL_DATE: 'Final Date',
  FINAL_HOUR: 'Final Hour',
  FINALIZE: 'Finalize',
  FINALIZE_CONTRACT: 'Finalize Contract',
  FINANCIAL: 'Financial',
  FINANCIAL_ANALYSIS_OF_CONTRACT: 'Financial analysis of the contract',
  FINANCIAL_FEEDBACK: 'Financial Feedback',
  FINANCIAL_SUMMARY: 'Financial Summary',
  FINISH_DONE: 'Completed Termination',
  FINISH_GMUD: 'Finish GMUD',
  FINISH_THE_TASK_BEFORE_MOVING_IT_TO_THE_FINISHED_COLUMN: 'Finish the task before moving it to the finished column',
  FINISHED: 'Finished',
  FINISHED_CALL: 'Finished Call',
  FINISHED_CANCEL: 'Completed Cancelled',
  FINISHED_FAIL: 'Ended with failure',
  FINISHED_GMUD: 'Finished Change',
  FINISHED_PARTIALLY: 'Completed Partially',
  FINISHED_SUCCESS: 'Completed successfully',
  FIRMWARE_VERSION: 'Firmware version',
  FIRST_NEED_FINALIZE_SUBCALLS: 'Must finish Sub Calls first',
  FIVE_WHYS_METHODOLOGY: '5 Whys Methodology',
  FLAG: 'Flag',
  FOLDER_ID: 'Folder ID',
  FOLLOY_DAY: 'Follow-up of the day',
  FOLLOY_MONTH: 'Follow-up of the month',
  FOLLOY_WEEK: 'Follow-up of the week',
  FOR_SLA: 'For SLA',
  FORECAST_TIME: 'Forecast Time',
  FORESEEN: 'Foreseen',
  FORGOT_PASS: 'Forgot your password?',
  FORM_FIELD: 'Form field',
  FORM_FIELDS: 'Form fields',
  FORM_FIELDS_CANNOT_BE_BLANK: 'Form fields cannot be blank',
  FORM_TEMPLATE: 'Form Template',
  FORMS: 'Forms',
  FORTNIGHTLY: 'Fortnightly',
  FREE: 'Free',
  FREE_SPACE: 'Free space',
  FREE_TIMES: 'FreeTime',
  FROM: 'From',
  FULL: 'Full',
  FULL_NAME: 'Full Name',
  FULL_SCREEN: 'Full Screen',
  GANTT: 'GANTT',
  GANTT_GRAPH: 'Graphic  Gantt',
  GCF: 'GCF',
  GENERAL: 'General',
  GENERAL_DATE: 'General Data',
  GENERAL_INF: 'General Informations',
  GENERAL_INFRASTRUCTURE_RISK: 'General Infrastructure Risk',
  GENERAL_PARAMETERS: 'General Parameters',
  GENERAL_SERVICE_DESK_REPORT: 'General service desk report',
  GENERAL_SETTINGS: 'General Settings',
  GENERAL_STATUS_OF_THE_PROJECT: 'General status of the project',
  GENERAL_USER_CREATION: 'General User Creation',
  GENERAL_USER_PERMISSIONS_CREATION: 'User Permissions Creation',
  'GENERAL_USER_PERMISSIONS_EDITION ': 'User Permissions Edition',
  GENERATE: 'Generate',
  GENERATE_NEW_CHANGE: 'Generate a new change',
  GENERATE_NEW_CHANGE_TEMPLATE: 'Generate a new change template',
  GENERATE_REPORT: 'generate report',
  GENERATED_PLURAL: 'Generated',
  GENERATES_COSTS: 'Generates Costs',
  GI: 'GI',
  GLOBAL: 'Global',
  GLOBAL_DATA_ON_RISKS_OF_CHANGE_NOT_REGISTERED: 'Global data on risks of change not registered',
  GMUD: 'Change',
  GMUD_AND_CAB_RISKS_SUCCESSFULLY_REGISTERED: 'Change and CAB risks successfully registered',
  GMUD_APPROVE: 'Do you want to approve this GMUD?',
  GMUD_BACK_TO_EDITION: 'Return GMUD for editing',
  GMUD_CONCLUDE: 'Do you want to conclude this GMUD?',
  GMUD_FLOW: 'Change Approval Workflow',
  GMUD_FLOW_INFOS: 'Create and manage approval workflows for GMUD',
  GMUD_IN_CAB: 'GMUD in CAB(daily)',
  GMUD_INFORMATION: 'CHANGE INFORMATION',
  GMUD_MANAGER: 'GMUD manager only!',
  GMUD_REGISTRATION: 'Change Registration',
  GMUD_REJECT: 'Reject GMUD',
  GMUD_RESEND_EMAIL: 'Resend e-mail for approval.',
  GMUD_RISKS_SUCCESSFULLY_REGISTERED: 'Change risks successfully registered',
  GMUD_RUNNING: 'GMUD running',
  GMUD_STATUS: {
    ANALYZING: 'Analisando',
    CANCELED: 'Canceled',
    CLOSE: 'Closed',
    CLOSED: 'Closed',
    CONCLUDED: 'Concluded',
    CONCLUDED_CANCEL: 'Concluded Canceled',
    CONCLUDED_FAIL: 'Concluded with failure',
    CONCLUDED_PART: 'Partially Concluded',
    CONCLUDED_SUCCESS: 'Successfully concluded',
    CREATED: 'Created',
    FINISHED: 'Finished',
    FINISHED_CANCEL: 'Finished Cancelled',
    FINISHED_FAIL: 'Finished with failure',
    FINISHED_PARTIALLY: 'Partially Finished',
    FINISHED_SUCCESS: 'Successfully Finished',
    OPENED: 'Opened',
    POST_IMPLEMENTATION_REVIEW: 'Post-Implementation review',
    REJECTED: 'Rejected'
  },
  GMUD_STATUS_DOES_NOT_ALLOW_RUNNING_TASKS: 'GMUD status does not allow running tasks',
  GMUD_STATUS_WILL_BE_SET_AS_OPEN: `The GMUD status will be set as "Open"`,
  GMUD_SUCCESSFULLY_FINISHED: 'GMUD successfully completed',
  GMUD_TYPE: 'GMUD Type',
  GMUD_WORKFLOW_EDITION: 'Approval Flow Editing',
  GMUD_WORKFLOW_REGISTRATION: 'Approval Workflow Registration',
  GO_TO: 'Go to',
  GO_TO_ACTIVITY: 'Go to activity',
  GOAL: 'Goal',
  GP: 'GP',
  GPU_ADAPTER: 'GPU adapter',
  GPU_CAPTION: 'GPU caption',
  GRAPH_BY_STATUS: 'Graph by status',
  GRAPH_CIO: 'Total Open Tickets',
  GRAPH_DETAILS: 'Graph Details',
  GRAPH_STATUS_AVALATION: 'Status Avaliation',
  GRAPH_STATUS_TECH: 'Status Group Tech',
  GRAPH_TYPE_ACTION: 'Graph by Action Type',
  GRC: 'GRC',
  GREAT: 'GREAT',
  GROUP: 'Group',
  GROUP_BROKEN: 'Breaking group',
  GROUP_DESCRIPTION: 'User Group with profile of Technician and Requesters.',
  GROUP_DESCRIPTION2: 'Group Description',
  GROUP_GMUD: 'Change Group',
  GROUP_LEADER: 'Group Leader',
  GROUP_LIST: 'Group List',
  GROUP_NAME: 'Group name',
  GROUP_REQUIRED_FIELD_CONDITIONAL: 'Company or global parameter is required.',
  GROUP_TECH: 'Group of Tech',
  GROUPING_NAME: 'Grouping name',
  GROUPS: 'Groups',
  GROUPTECH_TABLE: 'Group Tech Tables',
  GROWING: 'Growing',
  GROWTH: 'Growth',
  GSD: 'GSD',
  GSD_INFORMATIONS: 'GSD Informations',
  HARDWARE: 'Hardware',
  HARDWARE_LIST: 'Hardware List',
  HAVE_AN_EVALUATION_OF_AT_LEAST_8_OF_SATISFACTION_IN_USING_THE_EVOLUTION_SOLUTION: 'Have an evaluation of at least 8.0 of satisfaction in using the evolution solution',
  HEALTHY: 'HEALTHY',
  HELP_DESK: 'Help Center',
  HIDE: 'Hide',
  HIGH: 'High',
  HISTORIC: 'Historic',
  HISTORIC_APPROVAL_CENTER: "Approval Center's history",
  HISTORIC_ATEND_CLIENTS: 'Customer Service History',
  HISTORIC_CALL: 'Call history',
  HISTORIC_CHANGES: 'History of changes',
  HISTORIC_GP: 'Project Actions History',
  HISTORIC_LIST: 'Historical List',
  HISTORIC_SLA: 'SLA History',
  HISTORIC_SLA_SERIES: 'Series SLA History',
  HISTORY: 'History',
  HISTORY_APPROVALS: 'History Approvals',
  HISTORY_BACK_TO_EDITION: 'Editing history',
  HISTORY_OF_REJECTIONS_AND_CANCELLATIONS: 'HISTORY OF REJECTIONS AND CANCELLATIONS',
  HOLIDAY: 'Holiday',
  HOLIDAY_NAME: 'Holiday name',
  HOLIDAYS: 'Holidays',
  HOST: 'Host Name',
  HOUR: 'Hour',
  HOUR_EXCEEDED_VALUE: 'Exceeded Hours Value',
  HOUR_NORMALIZATION: 'Hour Normalization',
  HOUR_VALUE: 'Hour Value',
  HOUR_VALUE_ADDITIONAL: 'Additional Hour Value',
  HOUR_VALUE_TECH: 'Technical Hour Value',
  HOURLY: 'Hourly',
  HOURS: 'Hours',
  HOURS_CONSUMED: 'Consumed Hours',
  HOURS_CONSUMED_BY_TECHNICIAN_GROUP_ONLY_INCLUDES_TICKET: 'Hours consumed by Technician Group (Only includes TICKET)',
  HOURS_CONSUMED_PER_TOTAL_TEAM: 'Hours consumed per total team',
  HOURS_CONTRACTED: 'Contracted Hours',
  HOURS_CONTRACTED_VS_CONSUMED: 'Contracted Hours for Consumed Hours',
  HOURS_EXCEEDED: 'Exceeded Hours',
  HOURS_PER_ANALYST: 'hours per analyst',
  HOURS_USERS: 'Hours for Users',
  HOURS_WORKED: 'Hours worked',
  HOVER_OVER_COLUMN: 'Hover over the columns to see details',
  HOW_MANY_HOURS: 'How many hours?',
  HRS_CON: 'HRS/CON',
  IC_ALREADY_INCLUDED_IN_THE_TICKET: 'IC already included in the ticket',
  IC_CONFIGURATION_ITEM: 'IC (Configuration Item)',
  IC_CONTACT_APP_GROUP: 'Infra Group',
  IC_CONTACT_CLIENT_KEY_USER_IT: 'Client - Key User IT',
  IC_CONTACT_CRISI_COMPANY_REPRESENTATIVES: 'Special Representatives',
  IC_CONTACT_CRITICAL_SERVICE_GROUP: 'Infra Group',
  IC_CONTACT_CUSTOMER_GROUP_THAT_SHOULD_RECEIVE_SMS_IN_CASE_CRISIS_OR_DEGRADATION: 'Customer Group that should receive SMS in case of Crisis or Degradation',
  IC_CONTACT_CUSTOMER_KEY_USER_BUSINESS: 'Customer - Key User Business',
  IC_CONTACT_CUSTOMER_REPRESENTATIVES: 'Customer Representatives',
  IC_CONTACT_INFRA_GROUP: 'Infra Group',
  IC_CONTACT_RESPONSIBLE_FOR_CRITICAL_SERVICE: 'Responsible for Critical Service',
  IC_CONTACT_SPECIAL_GROUP_MUST_RECEIVE_SMS_IN_CASE_CRISIS_OR_DEGRADATION: 'Special Group that must receive SMS in case of Crisis or Degradation',
  IC_CONTACT_VIRTUAL_RESTORATION_TEAM_AND_KEY_RESOLVERS: 'Virtual Restoration Team and Key Resolvers',
  IC_RELATIONSHIP: 'IC relationship',
  IC_SUCCESSFULLY_INCLUDED: 'IC successfully included',
  IC_WITHIN_BLACKOUT_RANGE_WANT_TO_CONTINUE: 'IC within blackout range, want to continue',
  IC_WITHIN_OUTAGE_RANGE_WANT_TO_CONTINUE: 'IC within Outage range, want to continue',
  ICON: 'Icon',
  ICS_WITH_LESS_AVAILABILITY_OF_CURRENT_MONTH: "Ic's with less availability of the current month",
  id: 'ID',
  ID: 'ID',
  ID_COMPANY: 'Id Company',
  ID_CORRELATION: 'ID Correlation',
  ID_DISPLAY: 'ID Display',
  ID_INTEGRATION: 'ID Integration',
  ID_PROJECT: 'ID Project',
  ID_TICKET: 'Ticket ID',
  ID_TICKET_CREATION_REQUEST: 'Id Ticket Creation Request',
  IDENTIFICATION_NUMBER: 'Identification number',
  IDS: 'IDs',
  IF_YES_HOW_MANY_DAYS: 'If yes, how many days?',
  IM_SURE: "I'm sure!",
  IMAGE: 'Image',
  IMPACT: 'Impact',
  IMPACT_CHART_TECH: 'Impact Chart by Technicians',
  IMPACTED_SERVICES: 'Impacted Services',
  IMPACTED_SYSTEM: 'Impacted System',
  IMPEDITIVE: 'Impeditive',
  IMPORT: 'Importar',
  IMPORTANT: 'Important',
  IMPROVEMENT: 'Improvement',
  IN: 'In',
  IN_APPROVAL: 'In Approval',
  IN_ARREARS: 'In arrears',
  IN_ATTENDANCE: 'In attendance',
  IN_HOURS: 'In hours',
  IN_LIST: 'On the list',
  IN_PAUSE: 'In pause',
  IN_PROGRESS: 'In Progress',
  IN_THE_LAST_SEMESTER: 'In the last semester',
  IN_THE_MONTH: 'In the month',
  IN_THE_YEAR: 'In the year',
  IN_TIME: 'In Time',
  INACTIVE: 'Inactive',
  INCIDENT: 'Incident',
  INCIDENT_MANAGEMENT: 'Incident Management',
  INCIDENTS: 'Incidents',
  INCLUDE_CHAT_HISTORY: 'Include chat history',
  INCLUDE_FORM_FIELDS: 'Include form fields',
  INCLUDE_LOWERCASE: 'Include lower case',
  INCLUDE_NUMBERS: 'Include numbers',
  INCLUDE_QTD_CHARACTERS: 'Number of characters',
  INCLUDE_SYMBOLS: 'Include symbols',
  INCLUDE_TECHNICAL_NOTES: 'Include technical notes',
  INCLUDE_TICKET_HISTORY: 'Include ticket history',
  INCLUDE_UPPERCASE: 'Include upper case',
  INCORRECT_ORDER: 'Incorrect order',
  INCREMENTAL: 'Incremental',
  INDEX: 'Index',
  INDICATORS: 'Indicators',
  INDIVIDUALS: 'Individuals',
  INFO_DATA_BELOW: 'Enter the data below',
  INFORM_A_PERSON_IN_CHARGE: 'Inform a person in charge',
  INFORM_ALL_FIELDS: 'Inform all form fields!',
  INFORM_AN_APPROVER: 'Notify an approver',
  INFORM_DATE: 'Inform date',
  INFORM_REASON: 'Inform the reason',
  INFORM_REQUIRED_FIELDS: 'Inform required fields',
  INFORM_THE_CONTRACT_INVOLVED_IN_THIS_ACTIVITY: 'Inform the contract involved in this activity',
  INFORM_THE_EFFORT_IN_DAYS_TO_BE_CARRIED_OUT: 'Inform the effort in days to be carried out',
  INFORM_THE_PRIORITY_OF_THE_ACTIVITY: 'Inform the priority of the activity',
  INFORM_THE_PROJECT_PARTICIPANTS_AND_THEIR_HOURLY_VALUE: 'Inform the project participants and their hourly rate',
  INFORM_THE_REASON: 'Inform the reason',
  INFORM_THE_REASON_FOR_THE_GMUD: 'Inform the Reason for the GMUD',
  INFORM_THE_SPRINT_COMPANY: 'Inform the Sprint company',
  INFORM_THE_SPRINT_REQUESTER: 'Inform the Sprint Requester',
  INFORM_WHAT_TIME_THE_CHANGE_SHOULD_START: 'Inform what time the change should start',
  INFORM_WHAT_WILL_BE_EFFORT_HOURCS_CARRIED: 'Inform the effort in hours to be carried out.',
  INFORM_WHAT_WILL_BE_START_DATE_CHANGE: 'Inform what will be the start date of this change',
  INFORM_WHAT_WILL_BE_THE_EFFORT_IN_HOURS_TO_BE_CARRIED_OUT: 'Inform what will be the effort in hours to be carried out',
  INFORMATION: 'Information',
  INFORMATION_ASSETS: 'Information assets',
  INFORMATION_TECHNOLOGY: 'Information Technology',
  INFORMATIONS: 'Information',
  INFORMED: 'Informed',
  INFRATRUCTURE_MANAGEMENT: 'Infratructure Management',
  INI_DATE_BREAK: 'Break Initial Date',
  INI_TIME_BREAK: 'Break Initial Time',
  INIT_OUTAGE: 'Start Outage',
  INITIAL_CAUSE_ANALYSIS: 'Root Cause Analysis',
  INITIAL_DATE: 'Initial date',
  INITIAL_HOUR: 'Initial Hour',
  INITIAL_TIME: 'Initial Time',
  INITIATE_TICKET_SERVICE_ONLY_BY_USER_BELONGING_TO_THE_TECHNICAL_GROUP: 'Initiate ticket service only by user belonging to the technical group',
  INITIATIVES: 'Initiatives',
  INNOVATION: 'Innovation',
  INPUT: 'Input',
  INSTALATION_DATE: 'Installation date',
  INSTALL_DATE: 'Install date',
  INSTALLATION_TYPE: 'Installation type',
  INSTRUCTION: 'Instruction',
  INSTRUCTIONS: 'Instructions',
  INTANGIBLES: 'Intangibles',
  INTEGRATION: 'Integration',
  INTEGRATION_DATA: 'Integration Data',
  INTEGRITY: 'Integrity',
  INTERACTION_CHAT_PUT_ATTENDANCE: 'Interaction chat put into answer',
  INTERNAL: 'Internal',
  INTERVAL: 'Interval',
  INVALID_CONTRACT_DATE: 'Invalid contract date',
  INVALID_PASSWORD: 'Incorrect password',
  INVENTORY: 'Inventory',
  INVENTORY_LIST: 'Inventory List',
  INVENTORY_REPORT: 'Inventory report',
  INVOICE: 'Invoice',
  INVOLVED_APPLICANTS: 'Involved requesters',
  INVOLVED_AREAS: 'Involved Areas',
  INVOLVED_IN_THE_CHANGE: 'Involved in the change',
  INVOLVED_IN_THE_PROJECT: 'Involved in the project',
  INVOLVED_OUTSOURCED: 'Involved Outsourced',
  INVOLVED_PLURAL: 'Involved',
  INVOLVED_TECH: 'Involved Techs',
  IP: 'IP Address',
  IS_GREATER_OR_EQUAL_TO: 'Is greater or equal to',
  IS_LESS_THAN: 'Is less than',
  IS_LESS_THAN_OR_EQUAL_TO: 'É less than or equal to',
  IS_NOT_NULL: 'Is not Null',
  IS_NULL: 'Is Null',
  ISO_9001: 'ISO 9001',
  ISO_20000: 'ISO 20000',
  ISO_27001: 'ISO 27001',
  ISO_37001: 'ISO 37001',
  ISO_INFORMATION: 'ISO Informations',
  IT_BUDGET_CONTROL: 'IT Budget Management',
  IT_EVOLUTION_MANAGEMENT: 'IT Evolution Management',
  IT_IS_NOT_NECESSARY_TO_INFORM: 'it is not necessary to inform',
  IT_MANAGEMENT: 'IT Management',
  ITEM: 'Item',
  ITEMS: 'Itens',
  ITEMS_ASSOCIATED_WITH_ANOTHER_SPRINT: 'Items Associated with another sprint',
  ITEMS_IN_SERVICE_FOR_YOU: 'Items in service for you',
  ITEMS_LISTING: 'Item Listing',
  ITEMS_WAITING_FOR_APPROVALS_AND_EVALUATIONS: 'Items awaiting approvals and reviews',
  ITS_GREATER_THAN: "It's greater than",
  ITSM: 'ITSM',
  JURIDIC: 'Juridic',
  JUSTIFICATION: 'Justification',
  JUSTIFICATION_OF_TERMINATION: 'Justification of Termination',
  JUSTIFIED: 'Justified',
  KANBAN_COLUMN: 'Kanban Column',
  KANBAN_GRAPH: 'Graphic KANBAN',
  KEY: 'Key',
  KEYS: 'Keys',
  KNOWLEDGE_BASE: 'Knowledge Base',
  KNOWLEDGE_BASE_LIST: 'Solutions List',
  KNOWN_ERROR: 'Know error',
  LABELS: 'Labels',
  LANDLINE: 'Landline',
  LANG: {
    EN: 'English',
    ES: 'Spanish',
    PT_BR: 'Portuguese'
  },
  LANGUAGE: 'Language',
  LANGUAGE_TIMEZONE_CHANGES_NOTICE: 'Language and timezone changes will only take effect after the next login.',
  LANGUAGE_TIMEZONE_CHANGES_NOTICE: "Language and timezone changes will only take effect after the next login.",
  LAST_ACTION: 'Last action',
  LAST_BOOT_TIME: 'Last boot time',
  LAST_UPDATE: 'Last Update: ',
  LAST_UPDATE_DATE: 'Last update date',
  LAST_UPDATES: 'Last updates',
  LASTNAME: 'Last Name',
  LATE: 'Late',
  LATE_ACTIVITIES: 'Late Activities',
  LDAP: 'LDAP',
  LDPA: 'LDPA',
  LEARNING: 'Learning',
  LEAVE_A_BRIEF_DESCRIPTION_ABOUT_THIS_CHANGE: 'Leave a brief description about this change',
  LEAVE_A_BRIEF_DESCRIPTION_OF_THE_BENEFITS_OF_THIS_CHANGE: 'Leave a brief description of the benefits of this change',
  LEAVE_A_BRIEF_DESCRIPTION_OF_THIS_COST: 'Leave a brief description about this cost',
  LEAVE_BRIEF_DESCRIPTION_ABOUT_PROJECT: 'Leave a brief description about this project',
  LEAVE_MONITOR: 'Leave Monitor',
  LEGAL_OBLIGATION: 'Legal obligation',
  LEGEND: 'Legend',
  LET_US_KNOW_WHAT_THE_END_DATE_WILL_BE: 'Let us know what the end date will be',
  LET_US_KNOW_WHAT_TIME_THE_CHANGE_SHOULD_END: 'Let us know what time the change should end',
  LEVEL: 'Level',
  LGPD: 'LGPD',
  LICENSE: 'License',
  LIMIT_DATE_TIME_PAUSE_SLA: 'The entered date and time is before the current date and time!',
  LIMIT_TIME_PAUSE_SLA: 'Create time limit to restart paused ticket?',
  LIMIT_TIME_PAUSE_SLA_REQUIRED: 'Force to inform pause time limit',
  LINK: 'Link',
  LINK_ACTIVITY: 'Link Activity',
  LINK_REGISTRATION: 'Link Registration',
  LIST: 'List',
  LIST_ALLOWANCE: 'List of tickets to allow',
  LIST_BY_DEVICES: 'List by devices',
  LIST_BY_GROUPS: 'List by groups',
  LIST_OF_ASSOCIATED_ITEMS: 'List of Associated Items',
  LIST_OF_ATTENDANCES: 'List of Attendances',
  LIST_OF_COUNTRIES: 'List of countries',
  LIST_OF_MONITORED_COMPANIES: 'List of Monitored Companies',
  LIST_ONLY_CUSTOMER_TYPE_COMPANIES_IN_THE_COMPANY_FIELD_WHEN_OPENING_THE_TICKET: 'List only customer type companies in the company field when opening the ticket',
  LOAD: 'Loading, Please wait...',
  LOAD_MORE: 'Load More',
  LOAD_VIDEO: 'Please wait... We are generating your video',
  LOAN: 'Loan',
  LOCATION: 'Location',
  LOCATION_DESCRIPTION: 'Register location information',
  LOCATION_LANGUAGE: 'en-US',
  LOCATION_TITLE: 'Location',
  LOG: 'Log',
  LOG_FIM_DATE: 'Log end date',
  LOG_INI_DATE: 'Log start date',
  LOGGED_IN_USER: 'User Logged In',
  LOGICAL_CONDITIONS: 'Logical Conditions',
  LOGOFF: 'Logoff',
  LOOK_FOR_SUPPLIERS_TICKETS_IN_RELATIONSHIP: 'Look for suppliers tickets in Relationship',
  LOST: 'Lost',
  LOW: 'Low',
  MACADRESS: 'Mac',
  MAIL_DEFAULT: 'If enabled, the company will be linked to new emails',
  MAIL_HOST: 'Host',
  MAIL_HOST_VALUE: 'Host Value',
  MAIL_LIST: 'Email List',
  MAIL_MAIL: 'Mail',
  MAIL_PASSWORD: 'Password',
  MAIL_POLICY: 'Email Configuration',
  MAIL_POP: 'Pop',
  MAIL_PORT: 'Port',
  MAIL_PORT_VALUE: 'Port Value',
  MAIL_SMTP: 'Smtp',
  MAIL_TLS: 'Tls',
  MAIL_TLS_VALUE: 'Tls Value',
  MAIN: 'Main',
  MAIN_MODULES: 'Main Modules',
  MAIN_USER: 'Main User',
  MAINTAIN_ORIGINAL_CATALOG_OF_REQUESTS: 'Maintain original catalog of requests',
  MAKE_YOUR_REQUEST_HERE: 'Make your request here',
  MANAGE: 'Manager',
  MANAGE_CHANGE_GROUPS: 'Manage change groups',
  MANAGEMENT_ALLOWANCE: 'SLA Ticket Management',
  MANAGEMENT_DASHBOARD: 'Management DashBoard',
  MANAGEMENT_INFORMATION_TABLE: 'Management Information Table',
  MANAGEMENT_OF_DEMANDS_MOVING: 'MANAGEMENT OF DEMANDS/MOVING',
  MANAGEMENT_TICKET: 'Ticket Management',
  MANAGEMENT_VIEW: 'Management View',
  MANAGEMENT_VISION: 'Management Vision',
  MANAGER: 'Manager',
  MANAGER_COORDINATOR: 'Manager/Coordinator',
  MANAGER_GMUD: 'Change Manager',
  MANAGER_LINE: 'Queue management',
  MANAGER_OF: 'Management of',
  MANUAL: 'Manual',
  MANUFACTURER: 'Manufacturer',
  MAP: 'Map',
  MATURITY: 'Maturity',
  MATURITY_LEVEL: 'Maturity Level',
  MATURITY_MUST_CONTAIN_AN_ACTIVITY_OR_TICKET_THAT_IS_100_PERCENT_COMPLETE: 'Maturity must contain an activity or ticket that is 100% complete!',
  MAXATTACH: 'Attachment cannot be larger than 20MB',
  MAXIMUM: 'Maximum',
  MEASURE_EFFORT_IN_DAYS: 'Measure effort in days?',
  MEASURE_IN_DAIYS: 'Measure in days',
  MEDIUM: 'Medium',
  MEM: 'MEM',
  MEMBERS: 'members',
  MEMORY: 'Memory',
  MEMORY_MANUFACTURER: 'Memory manufacturer',
  MEMORY_PART_NUMBER: 'Memory part number',
  MEMORY_SERIAL_NUMBER: 'Memory serial number',
  MEMORY_USAGE: 'Memory Usage(GB)',
  MENU: 'Menu',
  MESSAGE: 'Message',
  MIGRATE_GM: 'Migrate GM',
  MIGRATE_GP: 'Migrate GP',
  MIGRATE_TICKET: 'Migrate Ticket',
  MIGRATE_TO_A_NEW_PROJECT: 'Migrate to a New Project',
  MIGRATE_TO_A_PROJECT_ACTIVITY: 'Migrate to a project activity',
  MIGRATE_TO_CRISIS: 'Migrate to Crisis/Degradation',
  MIGRATE_TO_GMUD: 'Migrate to Change',
  MIGRATE_TO_OS: 'Migrate to OS',
  MIGRATE_TO_PROBLEM: 'Migrate to Problem',
  MIGRATE_TO_PROJECT: 'Migrate to project',
  MILESTONE: 'Milestones',
  MINIMUM: 'Minimum',
  MINUTES: 'minutes',
  MINUTES_SUCCESSFULLY_RECORDED: 'Minutes successfully recorded',
  MODE: 'Mode',
  MODEL: 'Model',
  MODIFICATION: 'Modification',
  MODIFICATION_DATE: 'Modification date',
  MODIFICATION_USER: 'User who modified',
  MODULE: 'Module',
  MONETARY_TYPE: 'Monetary Type',
  MONITOR: 'Monitor',
  MONITOR_DEVICE: 'Monitor New Device',
  MONITORING: 'Monitoring',
  MONITORING_DEVICES_AND_LINKS: 'Monitoring Devices And Links',
  MONITORING_GROUP: 'Monitoring Group',
  MONTH: 'Month',
  MONTH_SELECTED: 'Selected month',
  MONTH_VALUE: 'Month Value',
  MONTHLY: 'Monthly',
  MONTHS: 'Months',
  MORE: 'More',
  MORE_INFORMATION: 'More information',
  MORE_THAN_10MB: 'Exceeds the 10mb allowed',
  MORE_THAN_15MB: 'Exceeds the 15mb allowed',
  MORE_THAN_40MB: 'Exceeds the 40mb allowed',
  MOVIMENT: 'Moviment',
  MULTI_CALL: 'Multi Call',
  MULTI_TICKETS: 'Multi Tickets',
  MUST_COMPLETE_ALL_ACTIVITIES: 'Must complete all activities',
  MY_DEMANDS: 'My Demands',
  MY_EXPERIENCE: 'My experience',
  MY_TICKETS: 'My tickets',
  N_CONTRACT: 'N Contract',
  N1_NAME: 'Level n1 name',
  N1_VALUE: 'Level n1 value',
  N2_NAME: 'Level n2 name',
  N2_VALUE: 'Level n2 value',
  N3_NAME: 'Level n3 name',
  N3_VALUE: 'Level n3 value',
  N4_NAME: 'Level n4 name',
  N4_VALUE: 'Level n4 value',
  NAME: 'Name',
  NAME_CONTRACT: 'Contract Name',
  NAME_FORM: 'Name form',
  NAME_IS_IN_USE: 'Name is in use',
  NATURE: 'Nature',
  NEEDS_APPROVAL: 'Needs Approval',
  NEGOTIABLE: 'Negotiable',
  NEIGHBORHOOD: 'Neighborhood',
  NETWORK: 'Network',
  NEVER: 'Never',
  NEW: 'New',
  NEW_ASSESSMENT: 'New Assessment',
  NEW_ASSIGNMENT: 'Add new Assignment to Catalog Service',
  NEW_CALL: 'New Calling',
  NEW_CALLS: 'New calls',
  NEW_CATALOG: 'New Catalog',
  NEW_CATEGORY: 'New Category',
  NEW_CATEGORY_ACTIVITY: 'New Category of Activity',
  NEW_CHANGE: 'Register New Change',
  NEW_COMPANY: 'New Company',
  NEW_CONTRACT: 'New Contract',
  NEW_DEPARTMENT: 'New Department',
  NEW_DEVICE: 'New Device',
  NEW_FORM: 'New Form',
  NEW_GROUP: 'New Group',
  NEW_GROUP_OF: 'New Group of',
  NEW_LOCATION: 'New Location',
  NEW_MASTER_PASSWORD: 'Create vault master password',
  NEW_MILESTONE: 'New Milestone',
  NEW_OUTSOURCED: 'New Outsourced',
  NEW_PASSWORD: 'New password',
  NEW_PLURAL: 'New',
  NEW_POSITION: 'New position',
  NEW_PROJECT: 'New Project',
  NEW_REGISTRATION: 'Do you want to make a new registration?',
  NEW_REQUEST: 'New request',
  NEW_REQUESTER: 'New Requester',
  NEW_RISK: 'New Risk',
  NEW_SERVICE_ORDER: 'New Service Order',
  NEW_SOLUTION: 'New Solution',
  NEW_SPONSOR: 'New Sponsor',
  NEW_STANDARD_ITEM: 'New standard item',
  NEW_SUBDEPARTMENT: 'New Subdepartment',
  NEW_TASK: 'NEW TASK',
  NEW_TASK_TEMPLATE: 'New task template',
  NEW_TECH: 'New Technician',
  NEW_TEST: 'New Test',
  NEW_TICKET: 'New Ticket',
  NEW_TREATMENT: 'New Treatment',
  NEXT: 'Next',
  NEXT_EXPIRATION: 'Next expiration',
  NEXT_PAGE: 'Next page',
  NO: 'No',
  NO_ALERTS: 'No alerts will do',
  NO_ATTENDANCE: 'No Attendance will only do',
  NO_COMMENTS: 'No Comments',
  NO_CONTRACTS: 'No contract will do',
  NO_DEPARTMENT_HEADS: 'No department heads available',
  NO_DEVICES_CAPACITY_PLAN: 'No devices will only do',
  NO_DEVICES_MONITOR: 'No device added to monitor so far',
  NO_OPTIONS: 'No Options',
  NO_RECURRENCE: 'No recurrence',
  NO_REG_FIND: 'No registration found',
  NO_REGISTERED_USER: 'No Records',
  NO_STARTED: 'Not started',
  NO_TICKETS: 'No tickets will do',
  NON_CRITICAL: 'Non-critical',
  NONE: 'None',
  NONE_SO_FAR: 'None so far',
  NORMAL: 'Normal',
  NOT: 'Not',
  NOT_ALLOWED_EVOLVE_CALL_WITH_PENDING_APPROVAL: 'It is not allowed to evolve the call with pending approval tasks!',
  NOT_APPROVED: 'Not Approved',
  NOT_ASSOCIATED_WITH_ACTIVITIES: 'Not associated with activities',
  NOT_ATTRIBUTED: 'Not Attributed',
  NOT_AUTHORIZED: 'Not Authorized',
  NOT_CLOSE_TICKET: 'Do not close the ticket',
  NOT_COMPLETED_PLURAL: 'Not completed',
  NOT_EXECUTED: 'Not Executed',
  NOT_FINISHED: 'Not Finished',
  NOT_GROWTH: 'There was no growth',
  NOT_MONITORING: 'Not Monitoring',
  NOT_PERMANENT: 'Not permanent',
  NOT_REVISED: 'Not Revised',
  NOTES_ACTIVITY: 'Notes Activity',
  NOTES_TICKETS_AS_TABLE: 'Use ticket history as a table',
  NOTHING_REGISTERED: 'Not Registered',
  NOTIFICATION: 'Notification',
  NOTIFICATION_CENTER: 'Notifications Center',
  NOTIFICATION_CENTER_DESCRIPTION: 'Create rules and notification sending policies',
  NOTIFICATION_CENTER_TAGS: 'Notifications Tags',
  NOTIFICATION_CENTER_TAGS_DESCRIPTION: 'Create tags to be used as variables in the notification sending',
  NOTIFICATION_CONDITION: 'Notification Conditions',
  NOTIFICATION_GROUP: 'Notification Group',
  NOTIFICATION_REGISTRATION: 'Notification Registration',
  NOTIFICATION_SUBJECT_HOLDER: 'Use the same tags (variables) from the message here',
  NUMBER: 'Number',
  NUMBER_CALLS: 'Number Calls',
  NUMBER_HOURS_PER_DAY: 'Number of hours per day',
  NUMBER_OF_ACTIVITIES: 'Number of activities',
  NUMBER_OF_CALLS_PER_ANALYST: 'Number Of Calls Per Analyst',
  NUMBER_OF_CLOSED_TICKETS_PER_TECHNICIAN_PER_DAY: 'Number of closed tickets per technician per day',
  NUMBER_OF_DEVICES: 'Number of Devices',
  NUMBER_OF_OPEN_ACTIVITIES: 'Number of Open Activities',
  NUMBER_OF_OPEN_TASKS: 'NUMBER OF OPEN TASKS',
  NUMBER_OF_SERVERS_MONITORED: 'Number of Monitored Servers ',
  NUMBER_OF_SERVERS_MONITOREDDASH: 'Items Monitored',
  NUMBER_OF_TICKETS_PER_TECHNICIAN: 'Number of tickets per technician',
  NUMBERS_CORE: 'Numbers core',
  NUMBERS_OF_LEVELS: 'Number of resolution levels',
  OBJECT_PRIORITIZATION: 'Object Prioritization',
  OBJECTIVE: 'Objective',
  OBJECTIVE_OS: 'Objective of Service Order',
  OBLIGATION_REASON_CHANGE_TECH_GROUP_TECH: 'Obligation of Reason in the change of technical/technical group',
  OBSERVATION: 'Observation',
  OBSERVATIONS: 'Observations',
  OBSERVER: 'Observer',
  OBSERVER_ADDED_SUCCESSFULLY: 'Observer added successfully',
  of: 'of',
  OF: 'of',
  OF_THE_IT: 'from IT',
  OFF_THE_LIST: 'Off the list',
  OFFICE: 'Office',
  OK: 'Okay',
  OKR_ACTIVITIES: 'OKR activities',
  OKR_EVOLUTION: 'Evolution OKR',
  OLA: 'OLA',
  OLD_PASSWORD: 'Old password',
  ON_ALERT: 'On alert',
  ON_LATE: 'On late',
  ON_PROGRESS: 'On Progress',
  ON_TIME: 'On Time',
  ONLY_APPROVERS_CAN_ACCESS_THIS_OPTION: 'Only Approvers can access this option',
  ONLY_LINKS: 'Only Links',
  ONLY_RESPONSIBLE_FOR_FILE_CAN_DELETE: 'Only the person responsible for the file can delete',
  ONLY_SEND: 'Only send',
  ONLY_SERVERS: 'Only Servers',
  ONLY_THE_CHANGE_MANAGER_CAN_EDIT: 'Only the Change Manager can edit',
  ONLY_THE_PERSON_IN_CHARGE_CAN_PERFORM_THIS_ACTION: 'Only the person in charge can perform this action',
  ONLY_THE_PROJECT_MANAGER_CAN_PERFORM_THIS_ACTION: 'Only the Project Manager can perform this action',
  ONLY_THE_PROJECT_MANAGER_OR_ADMINISTRATOR_CAN_PERFORM_THIS_ACTION: 'Only the Project Manager or Administrator can perform this action',
  OPEN: 'Open',
  OPEN_PROJECT: 'Open Project',
  OPEN_REQUESTS: 'Open Requests',
  OPEN_TICKET: 'Open Ticket',
  OPENED: 'Open',
  OPENING: 'Opening',
  OPENING_DATA_FINAL: 'Opening Date - Final',
  OPENING_DATA_INITIAL: 'Opening Date - Initial',
  OPENING_DATE: 'Opening date',
  OPENING_HOURS: 'opening hours',
  OPENS: 'Opens',
  OPERATING_TIME: 'Operating time',
  OPERATION_NOT_PERFORMED: 'Operation not performed.',
  OPERATION_NOT_PERFORMED_MAX_20_USERS: 'Operation not performed, maximum 20 users',
  OPERATION_NOT_PERFORMED_ONLY_THE_APPROVER_CAN_PERFORM_THIS_ACTION: 'Operation not performed, only the approver can perform this action',
  OPERATION_PERFORMED_SUCCESSFULLY: 'Operation carried out successfully',
  OPERATIONAL_SYSTEM: 'Operational System',
  OPERATOR: 'Operator',
  OPTIONAL: 'Optional',
  OPTIONAL_KEYBOARD: 'Optional Keyboard',
  OR: 'or',
  ORDER: 'Order',
  ORGANIZATION: 'Organization',
  ORIGIN: 'Origin',
  ORIGIN_FIM_DATE: 'Origin end date',
  ORIGIN_ID: 'Origin ID',
  ORIGIN_ID_REAL: 'Real origin ID',
  ORIGIN_INI_DATE: 'Origin start date',
  ORIGIN_NAME: 'Origin name',
  ORIGIN_TABLE: 'Origin table',
  ORIGIN_TYPE: 'Origin type',
  OS: 'OS',
  OS_RELEASE: 'OS release',
  OS_VERSION: 'Os version',
  OTHER_PROJECT_COSTS: 'Other project costs',
  OUTAGE: 'Outage',
  OUTAGE_FIM_DATE: 'Outage end date',
  OUTAGE_ID: 'ID Outage',
  OUTAGE_INI_DATE: 'Outage start date',
  OUTAGE_OUTSIDE_GMUD_RANGE: 'Outage outside Change range',
  OUTAGES: 'Outages',
  OUTPUT: 'Output',
  OUTSOURCED: 'Outsourced',
  OUTSOURCED_COMPANY: 'Outsourced Company',
  OUTSOURCED_DESCRIPTION: 'Person contracted to provide services through the contractor.',
  OUTSOURCED_LIST: 'Outsourced List',
  OVERDUE_TASKS: 'Overdue Tasks',
  OVERVIEW: 'Overview',
  PACKAGE_NAME: 'Package name',
  PANEL_DASH: 'tactical Panel',
  PANEL_PMO: 'PMO Panel',
  PARAMETERS: 'Parameters',
  PARTIAL: 'Partial',
  PARTICIPANT: 'Participant',
  PARTICIPANT_REQUEST: 'Participants Requests',
  PARTICIPANTS: 'Participants',
  PASSIVATORS: 'Passivators',
  PASSIVE: 'Passive',
  PASSIVES: 'Passives',
  PASSWORD: 'Password',
  PASSWORD_ASSISTANT: 'Password Assistant',
  PASSWORD_ASSISTANT_NEW: 'Password creation wizard',
  PASSWORD_CHANGE_NEXT_LOGON: 'Change password on next login',
  PASSWORD_CURRENT: 'Current password',
  PASSWORD_GENERATE: 'Generate password',
  PASSWORD_QUESTION: 'Security questions',
  PASSWORD_QUESTION_DESCRIPTION: 'Create security questions for password recovery',
  PASSWORD_RULE_LABEL: 'Your password must have at least',
  PASSWORD_RULE_LOWERCASE_CHAR: 'A lowercase character',
  PASSWORD_RULE_NUMBER: 'A number',
  PASSWORD_RULE_SPECIAL_CHAR: 'A special character',
  PASSWORD_RULE_UPPERCASE_CHAR: 'An uppercase character',
  PASSWORD_SAVE: 'Save password',
  PASSWORD_SIMPLE: 'Simple password',
  PASSWORD_TYPE: 'Password type',
  PASSWORD_VAULT: 'Password Vault',
  PATRIMONY: 'Patrimony',
  PAUSE_PROJECT: 'Pause Project',
  PAUSED: 'Paused',
  PAUSED_TIME: 'Pause time',
  PENDING: 'Pending',
  PENDING_ACTIVITIES: 'Pending Activities',
  PENDING_APPROVALS: 'Pending approvals',
  PENDING_TICKET_LIST: 'Pending ticket list',
  PEOPLE: 'People',
  PER: 'Per',
  PER_PANEL: 'Per panel',
  PER_TEAM: 'By Team',
  PERCENTAGE: 'Percentage',
  PERCENTAGE_INDEX: 'Index %',
  PERCENTAGE_OF: 'Percentage of',
  PERCENTAGE_OF_MATURITY: 'percentage of maturity',
  PERFORM_AUDIT_OF_EQUIPMENT_FOR_REPLACEMENT_UNDER_WARRANTY: 'Perform equipment audit for warranty replacement',
  PERFORM_TEST_REGISTERED_EMAIL: 'Perform test with registered email',
  PERFORMANCE_OF_PROJECTS: 'Delivery satisfaction level',
  PERFORMED: 'Performed',
  PERIOD: 'Period',
  PERIOD_AND_PRIORITY: 'PERIOD AND PRIORITY',
  PERMANENT: 'Permanent',
  PERSONAL_DATA: 'Personal data',
  PERSPECTIVE: 'Perspective',
  PERSPECTIVE_OVERALL_STATUS: 'Perspective overall status',
  PHASE_ORDER: 'Phase Order',
  PHASES: 'Phases',
  PHONE: 'Phone',
  PHONE_CALL: 'Phone call',
  PHYSICAL_SECURITY_AND_ENVIRONMENT: 'Physical Security And Environment',
  PHYSICS: 'Physics',
  PLANNED: 'Planned',
  PLANNED_ACTIVITIES: 'Planned activities',
  PLANNED_AVAILABILITY: 'Planned availability',
  PLANNED_HOURS: 'Planned Hours',
  PLANNED_MATURITY: 'Planned maturity',
  PLANNING: 'Planning',
  PLATFORM: 'Platform',
  PLEASE_PROVIDE_A_BRIEF_JUSTIFICATION_FOR_THIS_CHANGE: 'Please provide a brief justification for this change.',
  PLEASE_WAIT_WE_ARE_PROCESSING_THE_DATA: 'Please wait we are processing the data',
  POP_PORT: 'Port Pop',
  POPULATE_MAP: 'Populate Map',
  PORT: 'Port',
  PORTAL_CUSTOMIZATION: 'Portal customization',
  POSITION: 'Position',
  POSITIONS: 'Positions',
  POST_IMPLEMENTATION_REVIEW: 'Post Implementation Review',
  POSTAL_CODE: 'ZIP Code',
  POSTED_HOURS: 'Posted hours',
  PREDECESSOR_ACTIVITIES: 'Predecessor Activities',
  PREDECESSOR_TASK: 'Predecessor task',
  PREDECESSOR_TASKS: 'Predecessor tasks',
  PREDECESSORS: 'PREDECESSORS',
  PREOCUTIONS: 'Concerns',
  PREVENTIVE: 'Preventive',
  PREVIOUS: 'Previous',
  PREVIOUS_PAGE: 'Previous page',
  PRIMARY: 'Primary',
  PRINT: 'Print',
  PRINT_CHART: 'Print chart',
  PRINT_PMO: 'Print PMO',
  PRIORITIES: 'Priorities',
  PRIORITIZATION: {
    HIGH: 'High',
    MEDIUM: 'Medium',
    SMALL: 'Small',
    VERY_HIGH: 'Very High',
    VERY_SMALL: 'Very Small'
  },
  PRIORITIZATION_MATRIX: 'Prioritization Matrix',
  PRIORITIZATION1: 'Prioritization',
  PRIORITY: 'Priority',
  PRIORITY_CHART_TECH: 'Priority Graph by Technicians',
  PRIORIZATION: 'PRIORIZATION',
  PRIVATE: 'Private',
  PROBABILITY: 'probability',
  PROBLEM: 'Problem',
  PROBLEM_IDENTIFICATION: 'problem identification',
  PROBLEM_MANAGEMENT: 'Problem Management',
  PROBLEM_NAME: 'Problem name',
  PROBLEMS: 'Problems',
  PROBLEMS_SOLVED: 'Problems Solved',
  PROCESS: 'Process',
  PROCESSES: 'Processes',
  PROCESSES_IMPROVEMENT: 'Processes Improvement',
  PROCESSOR: 'Processor',
  PRODUCT_CATALOG: 'Products Catalog',
  PRODUCTIVE: 'Productive',
  PRODUCTIVE_HOURS: 'Productive Hours',
  PROFILE: 'Profile',
  PROFILE_PICTURE: 'Profile Picture',
  PROFILE_TYPE: 'Profile Type',
  PROGRESS: 'Progress',
  PROGRESS_: 'Progress',
  PROGRESS_STATUS: 'Progress Status',
  PROJECT: 'Project',
  PROJECT_BENEFIT: 'Project benefit',
  PROJECT_BOOK: 'Project Book',
  PROJECT_CANCELED: 'Project Cancelled',
  PROJECT_CLOSE: 'Close project',
  PROJECT_CLOSED_IN_MONTH: 'Projects closed in the month',
  PROJECT_DELETED_SUCCESSFULLY: 'Project deleted successfully!',
  PROJECT_DETAIL: 'Project Detail',
  PROJECT_ID: 'Project ID',
  PROJECT_IN_ARREARS: 'Projects in arrears',
  PROJECT_IN_EXECUTION: 'Project in execution',
  PROJECT_LIST: 'Project List',
  PROJECT_MANAGEMENT: 'Project Management',
  PROJECT_MANAGEMENT_SPRINT: 'PROJECT MANAGEMENT / SPRINT',
  PROJECT_MANAGER: 'Project Manager',
  PROJECT_NAME: 'Project name',
  PROJECT_PORTFOLIO: 'Project Portfolio',
  PROJECT_PRIORITIZATION: 'Project Prioritization',
  PROJECT_PRIORITIZATION_MAP: 'Project Prioritization Map',
  PROJECT_PRIORITY: 'Project priority',
  PROJECT_PROGRESS: 'PROJECT PROGRESS',
  PROJECT_STARTED_IN_MONTH: 'Projects started in the month',
  PROJECT_STATUS: {
    DOING: 'Doing',
    DONE: 'Done',
    STOPPED: 'Stopped',
    TO_DO: 'To do',
    VALIDATION: 'Validation'
  },
  PROJECT_TABLE: 'Project Table',
  PROJECT_TEMPLATE: 'Project Template',
  PROJECTENT: 'PROJECT DELIVERY',
  PROJECTION_OF_ACTIVITIES: 'Projection of Activities',
  PROJECTS: 'Projects',
  PROJECTS_AWAITING_EXECUTION: 'Projects awaiting execution',
  PROJECTS_COMPLETED_LATE: 'Projects completed late',
  PROJECTS_GOAL: "Project's goal",
  PROJECTS_STARTED_LATE: 'Projects started late',
  PROMOTERS: 'Promoters',
  PROVIDER: 'Provider',
  PROVIDER_CLIENT: 'Provider Client',
  PROVIDER_TIVIT: 'Provider Tivit',
  PROVIDER2: 'Provider',
  PROVISIONED: 'Provisioned',
  PROVISIONED_ITEMS: 'Provisioned items',
  PUNCTUATION: 'Punctuation',
  QUANTITIES: 'Quantities',
  QUANTITY: 'Quantity',
  QUANTITY_OF_TICKETS: 'QUANTITY OF TICKETS',
  QUANTITY_PER_MONTH: 'Quantity Per Month',
  QUANTITY_PERIOD: 'Quantity / Period',
  QUARTER: 'Quarter',
  QUERY_MADE: 'Query made',
  QUERY_NOT_REALIZE: 'Query not performed, value exceeds 31 days',
  QUESTION: 'Question',
  QUOTE: 'Quotation',
  RANK: 'Rank',
  RANKING: 'Ranking',
  RANKING_TOP_10: 'RANKING - TOP 10',
  RAPID_ITEM_APPROVAL_AND_OR_EVALUATION: 'Fast Item Approval and/or Review',
  RATING_OF_THIS_PROJECT: 'Rating of this project',
  RATION_CONSUMPTION_VALUE: 'Value and consumption ratio',
  RDP: 'RDP',
  READ: 'Read',
  READJUSTMENT: 'Readjustment',
  READJUSTMENTS: 'Readjustments',
  REAL: 'Real',
  REAL_ID: 'ID Real',
  REALIZED: 'Realized',
  REALIZED_COST: 'Realized Cost',
  REALIZED_TIME: 'Realized Time',
  REALIZED_VALUE: 'Realized value',
  REASON: 'Reason',
  REASON_FOR_REJECTION: 'REASON FOR REJECTION',
  REASON_REQUIRED: 'Reason required',
  REASON_STATUS: 'Reason Status',
  REASSIGNMENT_COUNT: 'Reassignment Count',
  RECEIVED: 'Received',
  RECLASSIFY_ATTENDANCE: 'Reclassify Attendance',
  RECORD: 'Record',
  RECORD_UNDERACTIVITY: 'Record underactivity',
  RECOVER: 'Recover',
  RECOVERY_PASSWORD: {
    ALTERNATIVE_EMAIL: 'Add an alternative email',
    ALTERNATIVE_EMAIL_DESCRIPTION: 'Add an alternative email or select some questions and provide answers to help protect your account. If you forget your password, you can use this information to reset it.',
    ALTERNATIVE_EMAIL_INFO: 'Adding an alternative email is a great way to protect your account. If you forget your password, you can receive a link to reset it in your alternative email.',
    CHALLENGE_RECOVERY: 'Recover password using security questions',
    CHOOSE_RECOVERY_METHOD: 'Choose one of the recovery methods',
    EMAIL_RECOVERY: 'Recover password using email or alternate email',
    INCORRECT_ANSWERS: 'Incorrect answers. Please try again.',
    NO_QUESTIONS_FOUND: 'No security questions found.',
    PASSWORD_RECOVERY_QUESTIONS: 'Password recovery questions',
    PROTECT_ACCOUNT: 'Protect your account',
    SECURITY_QUESTION: 'Security Question',
    SELECT_SECURITY_QUESTIONS: 'Select security questions',
    SELECT_SECURITY_QUESTIONS_INFO: 'Selecting security questions is another way to protect your account. When you forget your password, you can answer the security questions to reset it.',
    THANK_YOU: 'Thank you for requesting password recovery. Soon, you will receive an email with a secure link to reset your password.'
  },
  RECURRENCE: 'Recurrence',
  RECURRENCE_DATE: 'Recurrence date',
  RECURRENCE_OUTAGES: 'Recurring outages',
  RECURRENCE_STOP: 'Stop Recurrence',
  RECURRENCE_TYPE: {
    DAILY: 'Daily',
    MONTHLY: 'Monthly',
    NO_RECURRENCE: 'No Recurrence',
    QUARTERLY: 'Quarterly',
    SEMESTER: 'Semester',
    WEEKLY: 'Weekly',
    YEARLY: 'Yearly'
  },
  REDEFINE_PASS: 'Reset password',
  REDO_RISK_ANALYSIS: 'Redo Risk Analysis',
  REFERENCE_MONTH: 'Reference Month',
  REFERENCE_YEAR: 'Reference Year',
  REFRESH_PAGE: 'Refresh',
  REFUSE: 'Refuse',
  REFUSED: 'Refused',
  REGISTER: 'Register',
  REGISTER_ACTIVITIES: 'Register Activities',
  REGISTER_ACTIVITIES_GROUP: 'Register Activities Group',
  REGISTER_ACTIVITIES_RISK: 'It is necessary to register at least one (activity and RollBack), and one risk',
  REGISTER_BUILD: 'Register Build',
  REGISTER_BUSINESS: 'Register business area',
  REGISTER_COMPANY_LOGO: 'Register company logo',
  REGISTER_DATA: 'Registration Data',
  REGISTER_EVOLUTION: 'Register Evolution',
  REGISTER_HOLIDAYS: 'Register holidays',
  REGISTER_HOUR: 'Register Hour',
  REGISTER_ITEM_FOR_BUILD: 'Register item for build',
  REGISTER_LOCATION_USER: 'In the registration of users only inform the location',
  REGISTER_NEW_MILESTONE: 'Register New Milestone',
  REGISTER_NEW_USER: 'Register New User',
  REGISTER_OS: 'Register OS',
  REGISTER_PHASE: 'Register Phase',
  REGISTER_PHASES: 'Registration Phases',
  REGISTER_PROJECT: 'Register Project',
  REGISTER_PROJECT_OR_OS: 'Register Project or OS',
  REGISTER_SAVE: 'Record successfully saved!',
  REGISTER_SERVICE_ORDER: 'Register New Service Order',
  REGISTER_STATUS_ALLOWANCE: 'Register the ticket statuses to pay',
  REGISTER_SUB_ACTIVITIES: 'Register Sub-Activities',
  REGISTER_TAG: 'Register Tag',
  REGISTER_TECHS: 'Technical Registration',
  REGISTER_THIRD_LEVEL_CATEGORY: 'Register Third Level Category',
  REGISTERED_USER: 'Registered user',
  REGISTRATION: 'Registration',
  REGISTRATION_DATE: 'Date of registration',
  REGISTRATIONS: 'Registrations',
  REJECT: 'Reject',
  REJECT_SELECTED: 'REJECT SELECTED',
  REJECTED: 'Rejected',
  REJECTED_THE_GMUD: 'Rejected the GMUD',
  RELATION_TICKETS: 'Type of relationship',
  RELATIONS: 'Relations',
  RELATIONSHIP: 'Relationship',
  RELATIONSHIP_WITH: 'Relationship com',
  RELEASE_DATE_OF: 'Release Date Of',
  RELOAD_ALL: 'Reload all',
  REMOTE: 'Remote',
  REMOTE_ACCESS: 'Remote access',
  REMOVE: 'Remove',
  REOPEN: 'Reopen',
  REOPEN_COUNT: 'Reopen Count',
  REOPEN_TICKET: 'Reopen Ticket',
  REOPENED: 'Reopened',
  REOPENS_COUNT: 'Reopening count',
  REPORT_AT_LEAST_ONE_EVENT_TO_THE_CAB: 'Report at least one event to the CAB',
  REPORT_EVIDENCE: 'Report Evidence',
  REPORT_OR_COST_PROVIDED_FOR_THE_REALIZATION_OF_THIS_PROJECT: 'Report or cost provided for the realization of this project',
  REPORT_OR_NAME: 'Report or name',
  REPORT_OR_VALUE_CONSUMED: 'Report or value consumed',
  REPORT_THE_TIME_SPENT: 'Report the time spent',
  REPORT_WHAT_IT_WILL_BE_AT_THE_END_OF_THIS_MOVE: 'Report what it will be at the end of this move',
  REPORT_WHAT_IT_WILL_BE_AT_THE_START_DATE_OF_THIS_MOVE: 'Report what it will be at the start date of this move',
  REPORTING_CALL_ANALYSIS: 'Call reporting analysis',
  REPORTS: 'Reports',
  REPORTS_AUDIT: 'Reports - Audit',
  REPORTS_CONTRACT_ANL: 'Reports - Contract Overview - Analitic',
  REPORTS_CONTRACT_SINT: 'Reports - Contract Overview - Synthetic',
  REPORTS_DEVICE: 'Reports - Devices',
  REPORTS_FOR_TECH: 'Reports For Techs',
  REPORTS_FOR_TECH_GROUP: 'Reports For Techs Groups',
  REPORTS_TICKETS: 'Reports - Tickets',
  REPORTS_TICKETS_OS: 'Reports - Ticket and OS',
  REPRESENTATIVE: 'Representative',
  REPROCESS_SLA: 'Reprocess SLA',
  REQUEST: 'Request',
  REQUEST_ATTACHMENTS: 'Request attachments',
  REQUEST_DESCRIPTION: 'Request Description',
  REQUEST_DETAIL: 'Request Detail',
  REQUEST_INFO: 'Request Information',
  REQUEST_MANAGEMENT: 'Request Management',
  REQUEST_OPENED_SUCCESSFULLY: 'Request Opened Successfully',
  REQUEST_RESOLUTION: 'Request Resolution',
  REQUEST_TEMPLATE: 'Application Template',
  REQUEST_TIME: 'Request Time',
  REQUEST_TITLE: 'Request Title',
  REQUESTED_APPROVAL: 'Requested on Approval',
  REQUESTER: 'Requester',
  REQUESTER_DESCRIPTION: 'Who performs the requests of some internal / external problem.',
  REQUESTER_GROUP: 'Group of Requesters',
  REQUESTER_LIST: 'Request List',
  REQUESTER_SERVICE_DESK: 'Requesters Service Desk',
  REQUESTER_TIME: 'Request Time',
  REQUESTER_TYPE: 'Request Type',
  REQUESTERS: 'Requesters',
  REQUESTERS_LIST: 'Requesters List',
  REQUESTERSDASH: 'categorization',
  REQUESTING_TECHNICIAN: 'Requesting Technician',
  REQUESTS: 'Requests',
  REQUESTS_TO_APPROVE: 'Requests to approve',
  REQUIRED: 'Required',
  REQUIRED_FIELDS: 'Required fields',
  REQUIRES_APPROVAL_EVERYONE: 'Requires approval by everyone',
  RESCHEDULE: 'Reschedule',
  RESET: 'Reset',
  RESOLUTION: 'Resolution',
  RESOLUTION_CATEGORY: 'Resolution Categories',
  RESOLUTION_CODE: 'Resolution Code',
  RESOLUTION_DESCRIPTION: 'Resolution Description',
  RESOLUTION_TIME: 'Resolution Time',
  RESOLVE: 'Resolve',
  RESOLVED: 'Resolved',
  RESOLVED_AND_FINISHED: 'Solved and finished',
  RESPONSE_TIME: 'Response Time',
  RESPONSIBLE: 'Responsible',
  RESPONSIBLE_DEPARTMENT: 'Responsible department',
  RESPONSIBLE_FOR_APPROVAL: 'Responsible For Approval',
  RESPONSIBLE_FOR_EXECUTION_THE_TASK: 'Responsible for executing the task',
  RESPONSIBLE_FOR_TRATMENT: 'Responsible For The Treatment',
  RESPONSIBLE_GROUP: 'Responsible Group',
  RESPONSIBLE_NOT_REGISTERED: 'Responsible not registered please access settings -> list of companies -> add department -> choose head of department',
  RESPONSIBLES: 'Responsible',
  RESULT: 'Result',
  RESUME_CALL: 'Resume the call',
  RESUME_TICKET: 'Resume Ticket',
  RETROACTIVE_BREAK: 'Retroactive break',
  RETROSPECTIVE_START: 'Retrospective start',
  RETURN: 'Return',
  RETURN_CALL_SERVICE_IF_REQUESTER_INTERACTS_ATTACHMENT: 'Return the call to service if the requester adds an attachment.',
  RETURN_CALL_SERVICE_IF_REQUESTER_INTERACTS_CHAT: 'Return the call to service if the requester interacts in the chat',
  RETURN_PLAN: 'Return Plan',
  REVERT: 'Revert',
  REVIEW: 'Review',
  REVIEW_PERIOD: 'Review Period',
  REVISED: 'Revised',
  REVISION_DATE: 'Revision date',
  REVISION_DAY: 'Review day',
  REVISION_REQUIRED: 'Revision Required',
  REVISIONS_HISTORY: 'Revisions History',
  RISK: 'Risk',
  RISK_AND_COMPLIANCE_MANAGEMENT: 'Risk and compliance management',
  RISK_CHANGE: 'Change Risks',
  RISK_DESCRIPTION: 'Risk Description',
  RISK_ENVIRONMENTAL: 'Environmental risk',
  RISK_IF_NOT_DONE: 'Risk if not Done',
  RISK_M_ANALYSIS: 'Risk management analysis',
  RISK_MATRIX: 'Risk matrix',
  RISK_MONITORING: 'Risk monitoring',
  RISK_NAME: 'Risk Name',
  RISK_TYPE: 'Risk type',
  RISKC: {
    AC: 'Acceptable',
    CRITICAL: 'Critical',
    IN: 'Intolerable'
  },
  RISKS: 'Risk',
  RISKS_AND_COMPLIANCE: 'Risks and Compliance',
  RISKS_INVOLVED: 'Risks Involved',
  RISKS_MANAGEMENT: 'Risks Management',
  RISKS_MANAGEMENT_COMPLIANCE: 'Risk Management and Complicance',
  RISKS_NO_TEST: 'There are no tests performed by this company',
  RISKSS: 'Risks',
  RISKY: 'RISKY',
  ROLE: 'Roll',
  ROLLBACK: 'Rollback',
  ROLLBACK_PENDING_ACTIVITIES: 'There are pending rollback activities',
  ROOT_PREVIOUS_LEVEL: 'Root / Previous Level',
  ROW: 'Rows',
  ROW_LIST: 'Rows List',
  ROW_SIZE_TABLE: 'Rows per page:',
  ROWS_PER_PAGE: 'Rows per page',
  RULE: 'Rule',
  RULE_SERVICE_LEVEL: 'Service level rule',
  RUN_REMOTE_ACCESS: 'Run remote access',
  RUNNING: 'Running',
  RUNNING_SPRINTS: 'Running Sprints',
  RUNTIME: 'Runtime',
  SAFETY_PILAR: 'Safety Pillar',
  SAMPLE_SERVICES: 'Sample Services',
  SAT: 'Satisfaction level on delivery',
  SATISFACTION: {
    BAD: 'Bad',
    GOOD: 'Good',
    GREAT: 'Great',
    UNSATISFIED: 'Unsatisfied'
  },
  SAVE: 'Save',
  SAVE_CHANGES: 'Save Changes',
  SAVE_FORM: 'Save Form',
  SCALE: 'Scale',
  SCHEDULE: 'Schedule',
  SCHEDULING: 'Scheduling',
  SCHEDULING_SINGLE: 'Single Scheduling',
  SCM: 'SCM',
  SCOPE: 'Project Scope',
  SCRIPT: "Script",
  SEARCH: 'Search',
  SEARCH_BY_CALL: 'Search by call',
  SEARCH_BY_CATEGORY: 'Search by category',
  SEARCH_BY_ID: 'Search by ID',
  SEARCH_FOR: 'Search',
  SEARCH_FOR_DEVICE_OR_SERVICES: 'Search for device or services',
  SEARCH_GROUP: 'Search Group',
  SEARCH_THE_CATALOG: 'Search the catalog',
  SECONDS: 'Seconds',
  SECTION_OF_THE_STANDARD: 'section of the standard',
  SECURITY_HR: 'Security of RH',
  SECURITY_ORGANIZATION: 'Security Organization',
  SECURITY_POLICY: 'Security Policy',
  SEE_ACTIVITIES: 'See Activities',
  SEE_FINALIZATION: 'See Finalization',
  SEE_PERCENTAGE_COLOR: 'See percentage by status by color',
  SEE_RPI: 'See RPI',
  SEGMENT: 'Segment',
  SEGMENTATION_OF_THE_OPERATING_SYSTEM: 'SEGMENTATION OF THE OPERATING SYSTEM',
  SELECT: 'Select',
  SELECT_A_CHANNEL: 'Select a channel',
  SELECT_A_CONTRACT: 'Select a contract!',
  SELECT_A_DEPARTMENT: 'Select a department',
  SELECT_A_FILE: 'Select a file',
  SELECT_A_MAIN_CATEGORY: 'Select a main category',
  SELECT_A_PROJECT_TYPE: 'Select a project type',
  SELECT_A_RISK_TYPE: 'Select a risk type',
  SELECT_A_SUBCATEGORY: 'Select a subcategory',
  SELECT_A_THIRD_LEVEL_CATEGORY: 'Select a third-level category',
  SELECT_ACTIVITIES: 'Select Activities',
  SELECT_ACTIVITY: 'Select Activity',
  SELECT_ALL: 'Select All',
  SELECT_ALL_AREAS_THAT_ARE_INVOLVED_IN_THIS_CHANGE: 'Select all areas that are involved in this change',
  SELECT_ALL_THE_AREAS_THAT_ARE_INVOLVED_IN_THIS_MOVE: 'Select all the areas that are involved in this move',
  SELECT_ALL_THE_AREAS_THAT_WILL_BE_BENEFITED_BY_THIS_PROJECT: 'Select all the areas that will be benefited by this project',
  SELECT_APPROVERS: 'Select Approvers',
  SELECT_COMPANY: 'Select the Company',
  SELECT_COMPANY_CONTRACT: 'Select the company and contract to generate the report',
  SELECT_COMPANY_STANDARD_REPORT: 'Select the company, standard and choose the dates to generate the report',
  SELECT_DEVICE: 'Select Device',
  SELECT_FILE: 'Select an image',
  SELECT_FORM: 'Select Form',
  SELECT_FORM_ASSOCIATED_WITH_GMUD: 'Select form associated with change',
  SELECT_GMUD_APPROVER_GROUPS: 'Select change Approver Groups',
  SELECT_HOW_MANY_POINTS_WILL_BE_AWARDED_TO_THIS_PROJECT: 'Select how many points will be awarded to this project',
  SELECT_IMAGE: 'Select an image',
  SELECT_ONE_BELOW: 'Select one below',
  SELECT_ONE_OF_THE_ACTIONS_BELOW_TO_DETERMINE_THE_TYPE_OF_CHANGE: 'Select one of the actions below to determine the type of change',
  SELECT_ONE_OF_THE_ACTIONS_BELOW_TO_DETERMINE_THE_TYPE_OF_PROJECT: 'Select one of the actions below to determine the type of project.',
  SELECT_ONE_OR_MORE_TICKETS_TO_LINK_TO_GMUD: 'Select one or more tickets to link to change',
  SELECT_OR_DEPARTMENT: 'Select or Department',
  SELECT_OR_FILTER_TYPE: 'Select or Filter Type',
  SELECT_PRIORIZATION_MAKING_CHANGE: 'Select the prioritization for making this change.',
  SELECT_PROJECT: 'Select Project',
  SELECT_PROJECT_TO_SAVE_DUPLICATE_ACTIVITY: 'Select project to save duplicate activity',
  SELECT_REQUESTER: 'Select applicant',
  SELECT_SUPPLIER: 'Select Supplier',
  SELECT_TEMPLATE: 'Select Template',
  SELECT_THE_AREA_INVOLVED_IN_GMUD: 'Select the Area involved in change',
  SELECT_THE_CI_CONFIGURATION_ITEM_INVOLVED_IN_THE_GMUD: 'Select the CI (Configuration Item) involved in the change',
  SELECT_THE_COMPANY_INVOLVED_IN_THE_PROJECT: 'Select the company involved in the project',
  SELECT_THE_COMPANY_THAT_WILL_RECEIVE_THE_SERVICE: 'Select the company that will receive the service',
  SELECT_THE_DAY: 'select the day',
  SELECT_THE_DEVICES_THAT_WILL_BE_ASSOCIATED_WITH_THIS_CHANGE: 'Select the devices that will be associated with this change',
  SELECT_THE_FILES: 'select the files',
  SELECT_THE_MANAGER_THAT_WILL_BE_ASSOCIATED_WITH_THIS_CHANGE: 'Select the Manager that will be associated with this change',
  SELECT_THE_MANAGER_WHO_WILL_BE_ASSOCIATED_WITH_THIS_PROJECT: 'Select the Manager who will be associated with this project',
  SELECT_THE_PREDECESSOR_ACTIVITY_FOR_THIS_ACTIVITY: 'Select the predecessor activity for this activity',
  SELECT_THE_PRIORITY_FOR_THE_REALIZATION_OF_THE_PROJECT: 'Select the priority for the realization of the project',
  SELECT_THE_REQUESTER_OF_THIS_CHANGE: 'Select the requester of this change',
  SELECT_THE_RISK_FOR_THE_REALIZATION_OF_THE_PROJECT_LOW_MEDIUM_HIGH: 'Select the risk for the realization of the project (Low, Medium, High)',
  SELECT_THE_SERVICES_THAT_WILL_BE_ASSOCIATED_WITH_THIS_CHANGE: 'Select the services that will be associated with this change',
  SELECT_THE_TECHNICAL_GROUPS_INVOLVED_IN_THE_GMUD: 'Select the Technical Groups involved in the change',
  SELECT_THE_TECHNICIAN_WHO_WILL_BE_RESPONSIBLE_FOR_THE_APPROVAL: 'Select the Technician who will be responsible for the approval',
  SELECT_THE_TECHNICIANS_WHO_WILL_BE_INVOLVED_IN_THIS_CHANGE: 'Select the technicians who will be involved in this change',
  SELECT_THE_TECHNICIANS_WHO_WILL_BE_INVOLVED_IN_THIS_PROJECT: 'Select the technicians who will be involved in this project',
  SELECT_THE_TICKETS_THAT_YOU_WANT_TO_ASSOCIATE_WITH_THIS_MOVE: 'Select the tickets that you want to associate with this move',
  SELECT_THE_TICKETS_YOU_WANT_TO_ASSOCIATE_WITH_THIS_CHANGE: 'Select the tickets you want to associate with this change',
  SELECT_TICKET_TYPE: 'Select the ticket type',
  SELECT_USER: 'Select User',
  SELECT_USERS_INVOLVED_IN_THIS_PROJECT: 'Select users involved in this project',
  SELECT_WEIGHT_ACTIVITY: 'Select the weight of this activity.',
  SELECT_WHICH_CONTRACT_WILL_BE_ASSOCIATED_WITH_THIS_PROJECT: 'Select which contract will be associated with this project',
  SELECTED_TECHNICAL_GROUP_WILL_APPROVE_THE_GMUD: 'Selected Technical Group will approve the change',
  SELF_SERVICE_PORTAL: 'Self-Service Portal',
  SELFSERVICE: 'Self-Service',
  SEMESTER: 'Semester',
  SEND: 'Send',
  SEND_EMAIL_TO_RESPONSIBLE_PERSONS: 'Send Email to Responsible Persons',
  SEND_REASON_TO_CHAT: 'Send reason to chat',
  SEND_REPLY: 'Send Reply',
  SEND_THE_ANSWERS: 'Send the Answers?',
  SERIAL: 'Serial',
  SERIAL_NUMBER: 'Serial number',
  SERIAL_SO: 'Serial Control License',
  SERVER: 'Server',
  SERVER_NAME: 'Server Name',
  SERVER_REGISTRATION: 'Server Registration',
  SERVERS: 'Servers',
  SERVICE: 'Service',
  SERVICE_CATALOG: 'Subcategory',
  SERVICE_CATALOG_DESCRIPTION: 'It is a document that consists of the description of services previously analyzed and approved.',
  SERVICE_DESK: 'Management Service Desk',
  SERVICE_DISCOVERY: 'Service discovery',
  SERVICE_INFORMATION: 'Service Information',
  SERVICE_LIST: 'Service List',
  SERVICE_NAME: 'Service Name',
  SERVICE_ORDER: 'Service Order',
  SERVICE_ORDER_DETAIL: 'Service Order Details',
  SERVICE_ORDER_LIST: 'Service Order List',
  SERVICE_ORDERS: 'Service Orders',
  SERVICE_PORTAL: {
    CATALOG: 'Catalog',
    CATEGORY: 'Category',
    COMPANY: 'Company',
    FORM_FIELD_ACTION_BUTTON_BG_COLOR: 'Background Color',
    FORM_FIELD_ACTION_BUTTON_COLOR: 'Font Color',
    FORM_FIELD_BASE_BUTTON_BG_COLOR: 'Background Color',
    FORM_FIELD_BASE_BUTTON_COLOR: 'Font Color',
    FORM_FIELD_BRAND_LEFT: 'Distance from Left',
    FORM_FIELD_BRAND_LOGO: 'Logo',
    FORM_FIELD_BRAND_PREVIEW: 'Preview',
    FORM_FIELD_BRAND_TOP: 'Distance from Top',
    FORM_FIELD_CATALOG_BG_COLOR: 'Background Color',
    FORM_FIELD_CATALOG_COLOR: 'Font Color',
    FORM_FIELD_CATALOG_TITLE_COLOR: 'Title Font Color',
    FORM_FIELD_CATALOG_TITLE_SIZE: 'Title Font Size',
    FORM_FIELD_CSS_TEXT: 'CSS Text',
    FORM_FIELD_HEADER_BG_COLOR: 'Background Color',
    FORM_FIELD_HEADER_BG_IMG: 'Background Image',
    FORM_FIELD_HEADER_BG_PREVIEW: 'Preview',
    FORM_FIELD_HEADER_COLOR: 'Font Color',
    FORM_FIELD_HEADER_COLOR_HOVER: 'Font Color (hover)',
    FORM_FIELD_HOMEPAGE_BG_COLOR: 'Background Color',
    FORM_FIELD_HOMEPAGE_BG_IMG: 'Background Image',
    FORM_FIELD_HOMEPAGE_BG_PREVIEW: 'Preview',
    FORM_FIELD_HOMEPAGE_COLOR: 'Font Color',
    FORM_FIELD_IVI_CODE: 'Script IVI (Chat Bot)',
    FORM_FIELD_LAYOUT_FLUID: 'Fluid Layout',
    FORM_FIELD_SUBHEADER_BG_COLOR: 'Background Color',
    FORM_FIELD_SUBHEADER_COLOR: 'Font Color',
    FORM_GROUP_ACTION_BUTTON: 'Action Buttons',
    FORM_GROUP_BASE_BUTTON: 'Buttons',
    FORM_GROUP_BRAND: 'Logo',
    FORM_GROUP_CATALOG: 'Service Catalog',
    FORM_GROUP_CSS: 'Custom CSS',
    FORM_GROUP_HEADER: 'Header',
    FORM_GROUP_HOMEPAGE: 'Homepage',
    FORM_GROUP_LAYOUT: 'Layout',
    FORM_GROUP_MENU: 'Menu Settings',
    FORM_GROUP_SUBHEADER: 'Sub Header',
    FORM_GROUP_TAB: 'Tab Settings',
    IN_CATEGORIZATION: 'In Categorization',
    MENU_APPROVAL_CENTER: 'My approvals',
    MENU_CHANGE_PASSWORD: 'Password Reset/Unlock',
    MENU_EXCLAMATION: 'To change the menu, save your customization.',
    MENU_FAQ: 'Frequently Asked Questions',
    MENU_HOME: 'Homepage',
    MENU_SEARCH: 'Search',
    MENU_TICKET: 'My Requests',
    MOST_USED: 'Most used services',
    PAGE_FILTERS_NOT_FOUND: 'There are no records for the selected filter',
    PAGE_NOT_RESULTS_FOUND: 'No results found.',
    PAGE_SETTINGS_NOT_FOUND: 'Settings not found.',
    PAGE_SETTINGS_NOT_FOUND_DETAILS: 'Sorry, we were unable to find the details of the company associated with your account. Please contact support for assistance.',
    SELECT: 'Select',
    SELECT_CATEGORY: 'Select a category',
    SELECT_SUBCATEGORY: 'Select a subcategory',
    SELECT_TASK: 'Select a task',
    SERVICE_PORTAL: 'Service Portal',
    SUBCATEGORY: 'Subcategory',
    TAB_CONFIGURATION_ITEMS: 'Configuration items tab',
    TAB_DESIGNATION_HISTORY: 'Designation history tab',
    TAB_DETAIL: 'Details tab',
    TAB_RELATION_TYPE: 'Relationship type tab',
    TAB_SLA_HISTORY: 'Sla history tab',
    TASK: 'Task'
  },
  SERVICE_SLA: 'Service SLA',
  SERVICE_TABLE: 'Service table',
  SERVICE_TECH: 'Service Technician',
  SERVICE_TIME: 'Service Time',
  SERVICE_TIME_DETAIL: 'Service Time Detail',
  SERVICES: 'Services',
  SETTINGS_NOT_FOUND: 'Settings not found',
  SEVERITY: 'Severity',
  SHOW: 'Show',
  SHOW_IC_COMPANY: 'Show CI of all companies in device registration',
  SHOW_INTERATION_TICKET_CHANGE: 'Enable the option for each Company to change the status to "In Progress" when the requester interacts.',
  SHOW_MORE: 'Show more',
  SIDE_DISH: 'Side Dish',
  SIDE_DISH_CALENDARY: 'Side Dish Calendar',
  SIGN_IN: 'Log in',
  SITUATION: 'Situation',
  SIZE: 'Size',
  SIZE_DISK: 'Size disk',
  SLA: 'SLA',
  SLA_ACTIVE_CATEGORIZATION: 'Sla active in categorization',
  SLA_AGREED: 'SLA Agreed',
  SLA_ATTACH: "Add SLA'S",
  SLA_ATTACH_WARNING: 'All available SLAs are already attached to this contract',
  SLA_BROKEN_OUT: 'SLA Broken Out',
  SLA_CONDITION: 'SLA Conditions',
  SLA_DATE: 'SLA Date',
  SLA_FIXED: 'Fixed SLA',
  SLA_GLOBAL_LIST_ERROR: 'Global SLA list of start type not found',
  SLA_HOLIDAY: 'Holiday SLA',
  SLA_LIST: 'SLAs List',
  SLA_MANAGEMENT: 'SLA management',
  SLA_NAME: 'SLA Name',
  SLA_PARAM_NOT_FOUND: 'SLA parameter not found',
  SLA_PARAMS: 'SLA Parameters',
  SLA_POLICY: 'SLA policy',
  SLA_POLICY_DESCRIPTION: 'A set of conditions that a ticket needs to satisfy so that the SLA policy applied.',
  SLA_PROBLEM_STEP_ANALYSIS: 'SLA for effectiveness analysis',
  SLA_PROBLEM_STEP_CORRECTION: 'SLA for correction',
  SLA_PROBLEM_STEP_RESOLUTION: 'SLA for resolution',
  SLA_PROBLEM_STEP_ROOT_CAUSE: 'SLA for root cause analysis',
  SLA_PROBLEM_STEPS: 'SLA for problem steps',
  SLA_PROGRESS_GRID: 'Progress',
  SLA_REGISTRATION: 'SLA Registration',
  SLA_REPROCESSING: 'SLA Reprocessing',
  SLA_REPROCESSING_DESCRIPTION: 'Request SLA reprocessing',
  SLA_RULE_INCOMPATIBLE: 'SLA rule incompatible with the company',
  SLA_RULES_NO_MATCH: 'No SLA rules matched',
  SLA_STATUS: {
    DELAYED: 'Delayed',
    IN_TIME: 'In Time'
  },
  SLA_TIME: 'SLA Time',
  SLA_TYPE: 'SLA type',
  SLA_WORKDAY: 'SLA Workday',
  SMS: 'Mail/Request',
  SMTP_PORT: 'Port smtp',
  SMTP_SELECTED_SUCCESSFULLY: 'SMTP selected successfully',
  SNMP: 'SNMP',
  SNMP_LIST: 'Lista de SNMPs',
  SNMP_REGISTRATION: 'SNMP Registration',
  SO_BUILD_NUMBER: 'Service order build number',
  SO_CAPTION: 'Service order caption',
  SO_VERSION: 'Service order version',
  SOFTWARE: 'Software',
  SOFTWARE_ASSETS: 'Software assets',
  SOFTWARE_LIST: 'Software List',
  SOLICITACAO: 'Request',
  SOLICITANT: 'Solicitant',
  SOLICITATION_FORM: 'Applicant Opening Form',
  SOLICITATION_DATE: 'Solicitation Date',  
  SOLICITATION_HOUR: 'Solicitation Hours',
  SOLUTION: 'Solutions',
  SOLUTION_DETAIL: 'Solutions Details',
  SOLUTION_DEVELOPMENT: 'Solution development',
  SOLUTION_TIME: 'Solution Time',
  SOLVED: 'Solved',
  SPEAK: 'SPEAK',
  SPENT: 'Spent',
  SPONSOR: 'Sponsor',
  SPONSOR_DESCRIPTION: 'Register Sponsor of Contract',
  SPONSOR_LIST: 'Sponsor List',
  SPRINT: 'Sprint',
  SPRINT_ACTIVITY_EFFORT: 'Sprint Activity Effort',
  SPRINT_EFFORT: 'Sprint Effort',
  SPRINT_NAME: 'Sprint Name',
  SPRINTS_CREATED: 'Sprints Created',
  SQUAD: 'Squad',
  SRD: 'SRD',
  SSH: 'SSH',
  SSH_KEY: 'SSH Key',
  STABLES: 'Stables',
  STAGE_NAME: 'Stage Name',
  STAGE_RESPONSIBLE: 'Stage Resp.',
  STAGE_TOTAL_TIME: 'Stage Total Time',
  STANDARD: 'Standard',
  STANDARD_DETAIL: 'Standard Detail',
  STANDARDS: 'Standards',
  START: 'Start',
  START_: 'Start',
  START_ASSESSMENT: 'Start Assessment',
  START_ATTENDANCE: 'Start Attendance',
  START_DATE: 'Start Date',
  START_DATE_AND_TIME: 'Start date and time',
  START_DATE_CANNOT_BE_GREATER_THAN_END_DATE: 'Start date cannot be greater than end date',
  START_DATE_TIME: 'Attend. Start Date',
  START_DONE: 'Start Done',
  START_HOUR: 'Start Hour',
  START_MODE: 'Start mode',
  START_NAME: 'Start name',
  START_OF_ACTIVITY: 'Start of activity',
  START_OF_THE_PROJECT: 'Start of the project',
  START_RECURRENCE: 'Start Recurrence',
  START_WITH: 'Start with',
  STARTED: 'Started',
  STATE: 'State',
  STATE_DEADLINE: 'Stage Deadline',
  STATUS: 'Status',
  STATUS_ACTIVITY: 'Activity Status',
  STATUS_CHANGE: 'Status change',
  STATUS_CHART_BY_DEVICE: 'Status Chart by Device',
  STATUS_CHART_BY_SERVICE: 'Status Chart by service',
  STATUS_CHART_BY_TECH: 'Status Chart by technician',
  STATUS_LOG_ANALYZE: 'Analysing',
  STATUS_LOG_CAB: 'CAB',
  STATUS_LOG_CANCELLED: 'Canceled',
  STATUS_LOG_CLOSED: 'Closed',
  STATUS_LOG_CONCLUDED: 'Completed',
  STATUS_LOG_CONCLUDED_CANCEL: 'Completed Cancelled',
  STATUS_LOG_CONCLUDED_FAIL: 'Completed Failed',
  STATUS_LOG_CONCLUDED_PARTIAL: 'Partially Completed',
  STATUS_LOG_CORRETIVA: 'Corrective',
  STATUS_LOG_CREATE: 'Created',
  STATUS_LOG_EMERGENCIA: 'Emergency',
  STATUS_LOG_FINISH_CANCEL: 'Finished Cancelled',
  STATUS_LOG_FINISH_FAIL: 'Finished Failed',
  STATUS_LOG_FINISH_PARTIAL: 'Partially Finished',
  STATUS_LOG_FINISHED: 'Finished',
  STATUS_LOG_INCIDENT: 'Incident',
  STATUS_LOG_LIBERATION: 'Liberation',
  STATUS_LOG_NORMAL: 'Normal',
  STATUS_LOG_OPEN: 'Open',
  STATUS_LOG_PADRAO: 'Default',
  STATUS_LOG_PROBLEM: 'Problem',
  STATUS_LOG_PROGRESS: 'In Progress',
  STATUS_LOG_REJECTED: 'Rejected',
  STATUS_LOG_ROLLBACK: 'Rollback',
  STATUS_LOG_SOLICITATION: 'Request',
  STATUS_NOT_ALLOWED: 'Status not allowed',
  STATUS_OF_PROJECT: 'Status of project',
  STATUS_OF_TASK: 'Status of Task',
  STATUS_OF_TICKET: 'Status of Ticket',
  STATUS_OF_WAITING: 'Status of Waiting',
  STATUS_SHOULD_SELECT_VENDOR: 'Should this status select a vendor?',
  STATUS_SHOULD_SELECT_VENDOR_REQUIRED: 'Obrigar a informar um fornecedor',
  STATUS_TICKET_ALLOWANCE: 'SLA ticket status to request',
  STATUS_WILL_AUTOMATICALLY_CLOSE_TICKET: 'Will this status close the ticket automatically?',
  STATUS_WILL_STOP_SLA: 'Will this status stop the SLA?',
  STEP: 'Step',
  STOCK: 'Stock',
  STOCK_APPROVER: 'approver',
  STOCK_DEPARTAMENT: 'Department',
  STOCK_DT: 'Date',
  STOCK_IN: 'Product Entry',
  STOCK_IN_HIST: 'Inventory Movement',
  STOCK_INF_IN: 'Incoming Stock',
  STOCK_INF_OUT: 'Inventory Registration',
  STOCK_LISTING: 'Stock Listing',
  STOCK_MANAGEMENT: 'Stock Management',
  STOCK_MODEL: 'Model',
  STOCK_NAME: 'Name',
  STOCK_OUT: 'Outflow',
  STOCK_POLICY: 'Stock Registration',
  STOCK_QTDINPUT: 'Stock',
  STOCK_REASON: 'Reason',
  STOCK_SPONSO: 'Sponso',
  STOCK_STOCK_ERROR: 'Value exceeds current stock',
  STOCK_TYPE: 'Type',
  STOCK_TYPE_INOUT: 'Movement',
  STOCK_USER: 'Responsible',
  STOP_ATTENDANCE: 'Stop Attendance',
  STOP_DATE: 'Stop Date',
  STOP_MONITORING: 'Stop monitoring',
  STRATEGIC: 'Strategic',
  STRATEGIC_ALIGMENT: 'Strategic Alignment',
  STRATEGIC_PARTNERS: 'Strategic Partners',
  STREET: 'Street',
  SUB_ACTIVITIES: 'Sub-Activities',
  SUB_ACTIVITY_DELETED_SUCCESSFULLY: 'Sub-activity deleted successfully',
  SUB_CALL: 'Sub Call',
  SUB_CALLS: 'Sub Calls',
  SUB_CATEGORY: 'Sub Category',
  SUB_INSTITUTION: 'sub institution',
  SUBACTIVITIES: 'subactivities',
  SUBCATEGORIES: 'Subcategories',
  SUBCATEGORY: 'Subcategory',
  SUBDEPARTMENT: 'Subdepartment',
  SUBDEPARTMENT_BOSS: 'Subdepartment Head',
  SUBDEPARTMENT_DESCRIPTION: 'Sub-department is a division within a department.',
  SUBDEPARTMENT_LIST: 'Subdepartment List',
  SUBJECT: 'Subject',
  SUBJECT_OF_THE_CALL: 'Subject of the call',
  SUBJECTS_LIST: 'Subjects List',
  SUBJECTS_REGISTER: 'Subjects Register',
  SUBMENU: 'Submenu',
  SUBSTITUTION: 'substitution',
  SUCCESS: 'Success',
  SUCCESS_PASSWORD: 'Successfully created password',
  SUCCESSFULLY_DELETED_RESULT: 'Successfully deleted result',
  SUCCESSFULLY_UPDATED: 'Successfully updated',
  SUGGESTION: 'Suggestion',
  SUGGESTIONS: 'Sugestões',
  SUPPORT_CONTACT: 'Support Contact',
  SURPRISE_WITH_THE_IMPROVEMENTS: 'Surprise with the improvements',
  SURVEILLANCE: 'Surveillance',
  SWITCH_TO_DEFAULT: 'Toggle to default',
  SWITCH_TO_NEW_SCREEN: 'Switch to new screen',
  SWITCH_TO_SUBCATEGORY: 'Switch to SubCategory',
  SWITCH_TO_THIRD_LEVEL_CATEGORY: 'Switch to third level categories',
  SYNTHETIC: 'Synthetic',
  SYNTHETIC_DETAILS: 'Synthetic details',
  SYSTEM: 'System',
  SYSTEM_DEVELOPMENT_AND_MAINTENANCE: 'System Development And Maintenance',
  SYSTEM_MANUAL: 'System Manual',
  SYSTEM_VERSION: 'System Version',
  TABLE: 'Table',
  TAG: 'Tag',
  TAG_DESCRIPTION: 'Tags are words that serve as a tag and help when organizing information.',
  TAG_LIST: 'TagList',
  TAG_NAME: 'tag name',
  TAG_TAGNAME_DOES_NOT_BELONG_TO_EVENT: 'Tag tagName does not belong to event',
  TARGET: 'Target',
  TASK: 'Third Level Category',
  TASK_CREATED_SUCCESSFULLY: 'Task created successfully',
  TASK_DESCRIPTION: 'A task is an activity or a work to do.',
  TASK_FLOW: 'Task Flow',
  TASK_FORM: 'Task Form',
  TASK_LIST: 'Task List',
  TASK_LIST_TEMP: 'List Templates',
  TASK_MANAGEMENT: 'Task Management',
  TASK_NAME: 'Task Name',
  TASK_STATUS: {
    APPROVED: 'Approved',
    CANCELED: 'Canceled',
    DONE: 'Done',
    IN_PROGRESS: 'In Progress',
    PENDING: 'Pending',
    REJECTED: 'Rejected',
    STARTED: 'Started',
    WAITING: 'Waiting'
  },
  TASK_TEMP: 'Form',
  TASK_TEMPLATE: 'Tasks Template',
  TASK_TOTALS: 'Task totals',
  TASK2: 'Task',
  TASKS: 'Tasks',
  TASKS_LIST: 'Task List',
  TASKS_PER_PERIOD: 'TASKS PER PERIOD',
  TASKS_TO_APPROVE: 'Tasks to approve',
  TEAM_ALLOCATION: 'Team allocation',
  TEAM_AVERAGE: 'Team average',
  TECH: 'Technician',
  TECH_ATTACH: 'Attach Technician',
  TECH_ATTACH_WARNING: 'All available technicians are already attached to this contract',
  TECH_BY_HOURS: 'Technicians By hours',
  TECH_BY_VALUE: 'Technicians By Value',
  TECH_DESCRIPTION: 'Who performs the requests of some internal / external problem.',
  TECH_DETAILS: 'technician details',
  TECH_GROUP: 'Tech Group',
  TECH_GROUP_COMPANY: 'Company technical group',
  TECH_LIST: 'Tech List',
  TECH_TABLE: 'Technical table',
  TECH_TYPE: 'Type of Technician',
  TECHIN: 'The technician finished the OS activities, awaiting approval',
  TECHNICAL_ACCOUNT_MANAGER: 'Technical Account Manager',
  TECHNICAL_COST: 'Technical cost',
  TECHNICAL_FORM: 'TECHNICAL FORM',
  TECHNICAL_GROUP_COUNT: 'Technical group count',
  TECHNICAL_GROUPS: 'Technical Groups',
  TECHNICAL_NOTES: 'Technical Notes',
  TECHNICAL_REPORT: 'Technical report',
  TECHNICIAN_BY_VALUES_CONSUMED_TOTAL: 'Technician by values consumed total',
  TECHNICIAN_PER_HOURS_CONSUMED_TOTAL: 'Technician per hours consumed total',
  TECHS: 'Technicians',
  TELEPHONE_EXTENSION: 'Extension',
  TEMPERATURE: 'Temperature',
  TEMPLATE: 'Request Template',
  TEMPLATE_ADD_CATALOG: 'Add request template ',
  TEMPLATE_DESCRIPTION: 'Register the request templates',
  TEMPLATE_S_CATALOG: 'Select request template ',
  TEMPLATE_SUBJECT_CLOSE: 'Select the closing form',
  TEMPLATE_SUBJECT_OPEN: 'Select the opening form',
  TEMPLATES: 'Templates',
  TERM: 'Term',
  TERM_CHANGE: 'Change term',
  TERM_CLOSURE: 'Closure term',
  TERM_OPENING: 'Opening term',
  TERMINAL: 'Terminal',
  TERMINATE_CONTRACT: 'Terminate Contract',
  TEST: 'Test',
  TESTS: 'Tests',
  TESTS_PLAN: 'Tests Plan',
  THE_JUSTIFICATION_FIELD_IS_REQUIRED_FOR_APPROVAL: 'The justification field is required for approval',
  THE_JUSTIFICATION_FIELD_REQUIRED_FOR_APPROVAL: 'The justification field is required for approval.',
  THE_TECHNICIAN_NOT_ALLOWED_CATEGORIZE_CALLS_GROUP_THAT_DOES_NOT_BELONG: 'The technician is not allowed to categorize calls, from the technical group he does not belong to!',
  THE_TICKET_WILL_BE_CLOSED_DO_YOU_REALLY_WANT_TO_JOIN: 'The ticket will be closed, do you really want to join?',
  THEME: 'Theme',
  THEME_REGISTER: 'Theme Register',
  THERE_ARE_NO_RECORDS_TO_DELETE: 'There are no records to delete',
  THERE_CHANGE_WORKFLOW: 'There are changes in analysis with this workflow',
  THERE_IS_ONLY_ONE_RECORD_MODIFY_THE_CURRENT_ONE: 'There is only one record, modify the current one',
  THERE_WAS_AN_ERROR_SAVING_THE_FIELDS: 'There was an error saving the fields',
  THIRD_LEVEL: 'Third Level',
  THIRD_LEVEL_CATEGORIES: 'Third level categories',
  THIRD_LEVEL_CATEGORY: 'Third Level Category',
  THIRDED_LEVEL: '3rd Level',
  THIS_ACTION_WILL_ERASE_ALL_PREVIOUS_RECORDS_TO_RE_ANALYZE_THE_RISKS_OF_THIS_CHANGE: 'This action will erase all previous records to re-analyze the risks of this change',
  THIS_ACTIVITY_HAS_BEEN_COMPLETED_IT_CANNOT_BE_EDITED: 'This activity has been completed, it cannot be edited',
  THIS_ACTIVITY_RECORDED_AUDITED: 'This activity is recorded and audited',
  THIS_GMUD_WILL_OBLIGATORILY_GO_TO_THE_CAB_FOR_APPROVAL: 'This Change will obligatorily go to the CAB for approval.',
  THIS_MONTH: 'This month',
  THIS_TICKET_WAS_CREATED_USING_MULTI_TICKETS: 'This ticket was created using multi tickets',
  THREAT: 'Threat',
  THREE_HOURS: 'three hours',
  TICKET: 'Ticket',
  TICKET_ALLOWANCE: 'Paid Tickets',
  TICKET_AUTOMATION_REGISTRATION: 'Ticket automation registration',
  TICKET_CONTAINS_RULES: 'Ticket contains rules',
  TICKET_DETAIL: 'Ticket Detail',
  TICKET_DETAILS: 'Ticket Details',
  TICKET_DOES_NOT_CONTAIN_RULES: 'Ticket does not contain rules',
  TICKET_LIST: 'Tickets List',
  TICKET_MANAGEMENT: 'Ticket Management',
  TICKET_NOT_FOUND: 'Ticket not found',
  TICKET_OPEN_TODAY: 'Ticket open today',
  TICKET_PRIORITY: {
    CRITICAL: 'Critical',
    HIGH: 'High',
    MEDIUM: 'Medium',
    SMALL: 'Small',
    URGENT: 'Urgent'
  },
  TICKET_RESOLUTION: {
    CANNOT_REPRODUCE: 'Cannot reproduce',
    DONE: 'Done',
    DUPLICATE: 'Duplicate',
    FIXED: 'Fixed',
    INCOMPLETE: 'Incomplete',
    NOT_RESOLVED: 'Not Resolved',
    RESOLVED: 'Resolved',
    WONT_FIX: 'Wont fix'
  },
  TICKET_STATE_NOT_ALLOWED: 'Ticket status not allowed for reprocessing',
  TICKET_STATUS: {
    APPROVE: 'Approve',
    APPROVED: 'Approved',
    CANCELLED: 'Canceled',
    CANCELLEDS: 'Canceleds',
    CLOSED: 'Closed',
    CLOSEDDETAIL: 'Close',
    FINALIZED: 'Finalized',
    IN_APPROVAL: 'In Approval',
    IN_PROGRESS: 'In Progress',
    NEW: 'New Tickets',
    OPEN: 'Open',
    OPENS: 'Open',
    REJECTED: 'Rejected',
    REOPEN: 'Reopens',
    REOPENED: 'Reopened',
    RESOLVED: 'Resolved',
    SOLVED: 'SOLVED',
    TASKS: 'Recurrent',
    WAITING: 'Waiting'
  },
  TICKET_SUBJECT: 'Ticket subject',
  TICKET_SUCCESSFULLY_EVALUATED: 'Call successfully evaluated!',
  TICKET_TASKS: 'TICKET TASKS',
  TICKET_TELEMETRY: 'TICKET TELEMETRY',
  TICKET_TIME: 'Ticket Time',
  TICKET_TITLE: 'TICKET TITLE',
  TICKET_TYPE_LABEL: 'Ticket Type',
  TICKET_TYPES: {
    CHANGE: 'Change',
    CRISIS: 'Crisis',
    CRITICAL_INCIDENT: 'Critical Incident',
    EVENT: 'Event',
    INCIDENT: 'Incident',
    LIBERATION: 'Request for Approval',
    PROBLEM: 'Problem',
    SOLICITATION: 'Request'
  },
  TICKET_VIEW: 'Ticket view',
  TICKET_WITHOUT_SLA: 'Tickets without SLA',
  TICKET_WORKFLOW_OF_CHANGE: {
    ANALYZED: 'Analysed',
    DEVELOPED: 'Developed',
    FINALIZED: 'Finalized',
    IN_ANALYSIS: 'In Analysis',
    IN_DEVELOPMENT: 'In development',
    IN_TEST: 'In test',
    OPEN: 'Open',
    REJECTED: 'Rejected',
    TESTED_WITH_ERRORS: 'Tested with errors',
    TESTED_WITHOUT_ERRORS: 'Tested with errors'
  },
  TICKETCONHIST: 'Historic Tickets',
  TICKETS: 'Tickets',
  TICKETS_CATEGORY_MONTH: 'Tickets by sub category',
  TICKETS_STATUS: 'Tickets Status',
  TICKETS_TECH_MONTH: 'Tickets by coaches in the month',
  TICKETS_TO_CATEGORIZE: 'Tickets to categorize',
  TIME: 'Time',
  TIME_CONSUMED: 'Time Consumed',
  TIME_DETAIL: 'Time Detail',
  TIME_SPENT: 'Time Spent',
  TIME_TO_RESOLUTION: 'Time to Resolution',
  TIME_TO_RESPONSE: 'Time to Response',
  TIME_TOTAL_STEP: 'Total Step Time',
  TIMEOUT_PRICE: 'Timeout price',
  TIMEZONE: 'Timezone',
  TIN: 'TINs',
  TITLE: 'Title',
  TO_CHARGE: 'To charge',
  TO_DO: 'To Do',
  TO_MEET: 'Meet',
  TO_MONITOR: 'To monitor',
  TO_REGISTER: 'Register',
  TO_REMOVE: 'Remove',
  TO_SEND: 'Send',
  TODAY: 'Today',
  TOGGLE_TO_GRAY: 'Toggle to gray',
  TOKEN: 'Token',
  TOP_10_CATEGORIES: 'Top 10 Categories',
  TOP_10_COMPANIES: 'Top 10 Companies',
  TOP_10_CONSUMPTION: 'Top 10 Consumption',
  TOP_10_DEPARTAMENT: 'Top 10 Department',
  TOP_10_DEPARTAMENTS: 'Top 10 Departments',
  TOP_TEN_RECENTE: 'Most recent top 10',
  TOPIC: 'Topic',
  TOPICS_REGISTER: 'Topics Register',
  TOTAL: 'Total',
  TOTAL_ACTIVITIES: 'Total activities',
  TOTAL_ACTIVITIES_COMPLETED: 'Total activities completed',
  TOTAL_AMOUNT_HOURS: 'Total amount of hours',
  TOTAL_APPROVED: 'Total Approved',
  TOTAL_APPROVERS: 'Total Approvers',
  TOTAL_BY_COMPANY: 'Total by Company',
  TOTAL_CALLS: 'total calls',
  TOTAL_CALLS_CURRENT_MONTH: 'Total calls (current month)',
  TOTAL_CONTRACTS: 'Total Contracts',
  TOTAL_CORPORATE_OBJECTIVES: 'Total corporate objectives',
  TOTAL_COST: 'Total cost',
  TOTAL_COST_CARE: 'Total cost of care',
  TOTAL_FACE_TO_FACE_HOURS: 'Total face-to-face hours',
  TOTAL_GMUD: 'Total Change',
  TOTAL_GOALS: 'Total goals',
  TOTAL_HOURS_CONSUMED: 'Total Hours Consumed',
  TOTAL_IC_MONITORING: 'Total IC`s monitoring',
  TOTAL_KEYS: 'Total keys',
  TOTAL_MONTH: 'Total in the month',
  TOTAL_OUT_OF_DATE_TICKETS: 'Total out-of-date tickets',
  TOTAL_PEOPLE_INVOLVED: 'Total people involved',
  TOTAL_REJECTED: 'Total Rejected',
  TOTAL_REMOTE_HOURS: 'Total remote hours',
  TOTAL_SUM_MORE_100: 'The total sum of the items exceeds 100%',
  TOTAL_TICKET: 'TOTAL TICKET',
  TOTAL_TICKET_TIME: 'Total Ticket Time',
  TOTAL_TICKET_TIME_BETWEEN_OPENING_AND_RESOLUTION: 'Total time of the ticket, it is the time from the opening of the request to its resolution',
  TOTAL_TICKETS: 'Total tickets',
  TOTAL_TICKETS_CLOSED_PER_DAY: 'Total Tickets Closed per Day',
  TOTAL_TICKETS_ON_TIME: 'Total tickets on time',
  TOTAL_TIME_ATTENDANCE: 'Total Time of Attendance',
  TOTAL_VALUE_IN_THE_PROJECT: 'Total value in the project',
  TOTAL_VALUE_PROJECT_IN_MONTH: 'Total value of projects in the month',
  TOWER: 'Tower',
  TRAINING: 'Training',
  TRANSFERRED: 'Transferred',
  TREATMENT: 'Treatment',
  TREND: 'Trend',
  TREND_ANALYSIS_BY_DEVICE: 'Trend Analysis by Device',
  TREND_LINE: 'Trend Line',
  TRIGGER: 'Trigger',
  TWO_HOURS: 'two hours',
  TYPE: 'Type',
  TYPE_ACTION: 'Type Action',
  TYPE_CLIENT: 'Type Client',
  TYPE_DEVICE: 'Type Device',
  TYPE_DEVICE_ADD: 'New type device',
  TYPE_DEVICE_EDIT: 'Edit type device',
  TYPE_MASTER_PASSWORD: 'Enter vault master password',
  TYPE_OF_ACTIVITY: 'Type of Activity',
  TYPE_OF_APPROVAL: 'Type of Approval',
  TYPE_OF_APPROVER: 'Type of Approver',
  TYPE_OF_CAPITAL: 'Type of Capital',
  TYPE_OF_DEMAND: 'Type of Demand',
  TYPE_OF_MONITORING: 'Type of Monitoring',
  TYPE_OF_SERVICE: 'Type of service',
  TYPE_PASSWORD_ENTER: 'Enter password to enter',
  TYPE_PROJECT: 'Type of Project',
  TYPE_PROJECT_ITEMS: [{
    color: 'info',
    icon: 'fas fa-compass',
    label: 'Strategic',
    value: 1
  }, {
    color: 'purple',
    icon: 'fas fa-dumbbell',
    label: 'Improvement',
    value: 2
  }, {
    color: 'primary',
    icon: 'fas fa-wrench',
    label: 'Corrective',
    value: 3
  }, {
    color: 'success',
    icon: 'fas fa-chart-line',
    label: 'Evolutionary',
    value: 4
  }, {
    color: 'danger',
    icon: 'fas fa-gavel',
    label: 'Legal Obligation',
    value: 5
  }],
  TYPE_PROVIDER: 'Type Provider',
  TYPE_RELATIONSHIP: 'Association type',
  TYPE_SLA_STATUS_CANCELED: 'Canceled',
  TYPE_SLA_STATUS_CONCLUDED: 'Completed',
  TYPE_SLA_STATUS_IN_PROGRESS: 'In Progress',
  TYPE_SLA_STATUS_PAUSE: 'Pause',
  TYPE_SLA_STATUS_RESUMPTION: 'Resumption',
  TYPE_SLA_STATUS_START: 'Start',
  TYPE_TO_SEARCH_OR_CLICK_MAGNIFYING_GLASS: 'Type to search or click magnifying glass to view all.',
  TYPE_YOUR_EMAIL: 'Enter your email',
  TYPE_YOUT_EMAIL: 'Type your email',
  UNABLE_TO_EVALUATE: 'Unable to evaluate',
  UNAVAILABLE: 'Unavailable',
  UNFREEZE_SUCCESSFULLY_COMPLETED: 'Unfreeze successfully completed',
  UNINFORMED: 'Uninformed',
  UNPLANNED_ACTIVITIES: 'Unplanned activities',
  UNREGISTERED_USER: 'Unregistered user',
  UNRESOLVED_PROBLEMS: 'Unresolved problems',
  UNSTABLE: 'Unstable',
  UNTIL: 'until',
  UPDATE: 'Update',
  UPDATE_AGENT: 'Update Agent',
  UPDATE_ITEM_LIST: 'Update Item List',
  UPDATE_TICKET_LIST_SCREEN: 'Update of ticket list screen',
  UPDATE_TICKET_MANAGEMENT_VIEW_SCREEN: 'Update of Ticket Management View screen',
  UPDATE_USER_INFO: 'Update User Information',
  UPDATED: 'Updated',
  UPDATED_CALL: 'Updated Call',
  UPDATED_FIELDS: 'Updated fields',
  UPDATES: 'Updates',
  UPLOAD_ERROR: 'Enter the contract to upload!',
  UPLOAD_FILE: 'Upload File',
  UPTIME: 'Uptime',
  URGENCY: 'Urgency',
  URL: 'URL',
  USE_APPROVAL_CENTER: 'Use Approval Center',
  USE_CUSTOM_FORM_FOR_CHANGE_MANAGEMENT_CAB: 'Use custom form for change management CAB',
  USE_IN_GMUD: 'Use in change',
  USE_IN_TICKET: 'Use in ticket',
  USE_MULTI_FILTER_TICKET: 'Use Multi Filter on Ticket Screen',
  USE_ONLY_GROUP_TECH_IN_CREATE_GMUD_ACTIVITY: 'When enabled, it will only show tech groups to be selected on the change activity creation screen.',
  USE_RESOLUTION_CATEGORY: 'Use resolution categories',
  USE_SERVICE_PORTAL_ONLY: 'Service portal only',
  USE_STATUS_SUB_CALLS: 'Use Status on Sub calls',
  USE_TECH_GROUP_IN_GMUD_ACTIVITY: 'Using tech groups in Change activities',
  USED: 'Used',
  USED_FOR: 'Used for',
  USER: 'User',
  USER_APPROVED: 'User who approved',
  USER_CAD: 'User who approved',
  USER_DOES_NOT_BELONG_TO_THE_TECHNICAL_GROUP: 'User does not belong to the technical group',
  USER_DOES_NOT_HAVE_PERMISSION: 'User does not have permission to perform this action',
  USER_HAS_ALREADY_APPROVED: 'User has already approved',
  USER_HAS_ALREADY_MADE_APPROVAL: 'User has already made approval',
  USER_HAS_ALREADY_PERFORMED_THIS_ACTION: 'User has already performed this action',
  USER_IS_NOT_PART_OF_THE_ACTIVITY: 'User is not part of the activity',
  USER_IS_NOT_PART_OF_THE_APPROVAL_GROUP: 'User is not part of the approval group',
  USER_IS_NOT_PART_OF_THE_APPROVER_GROUP: 'User is not part of the approver group',
  USER_IS_NOT_RESPONSIBLE_REGISTERED_IN_THE_TASK: 'User is not responsible registered in the task',
  USER_MANAGEMENT: 'Users Management',
  USER_NOT_ALLOWED: 'User not allowed',
  USER_NOT_ALLOWED_TO_CATEGORIZE: 'User not allowed to categorize!',
  USER_REGISTERED: 'User registered',
  USER_REGISTRATION: 'User Registration',
  USER_VIP: 'This is a VIP category user',
  USER_WHO_REJECTED: 'User who rejected',
  USER_WILL_BE_INVOLVED_IN_THE_PROJECT: 'User _user_ will be involved in the project!',
  USERNAME: 'Username',
  USERS: 'Users',
  USERS_CREATION: 'User Creation',
  USERS_INVOLVED: 'Users Involved',
  USERS_PERMISSIONS: 'User Permissions',
  UST: 'Technical Support Unit',
  UTILIZATION: 'Utilization',
  VALIDATE_RELATIONSHIP: 'Validate Relationship',
  VALIDATE_RELATIONSHIP_BETWEEN_CI: 'Validate relationship between CI',
  VALIDATION: 'Validation',
  VALIDATIONS_APPROVALS: 'Validations/Approvals',
  VALUE: 'Value',
  VALUE_OF_BENEFITS: 'Value of Benefits',
  VALUES: 'Values',
  VARIATION: 'Variation',
  VENDOR: 'Vendor',
  VERSION: 'Version',
  VERY: 'Very',
  VIEW: 'View',
  VIEW_ATTACHMENT: 'View Attachment',
  VIEW_COSTS: 'View Costs',
  VIEW_FULL_SCREEN: 'View in full screen',
  VIEW_FULL_TICKET: 'View Full Ticket',
  VIEW_LINE: 'View Queues',
  VIEW_OF_SELECTED_CONTRACT: 'View of selected contract',
  VIEW_PROJECTS: 'View Projects',
  VIP: 'VIP',
  VIRTUAL_MEMORY: 'Virtual memory',
  VISUALIZATION_OF_TASKS: 'Visualization of tasks',
  VISUALIZE: 'Visualize',
  VOLUME_NAME: 'Volume name',
  VOLUME_SERIAL_NUMBER: 'Volume serial number',
  VOTES: 'Votes',
  VULNERABILITY: 'Vulnerability',
  VULNERABILITY_MANAGEMENT: 'Vulnerability management',
  WAIT_GENERATING_REPORT: 'Wait generating report!',
  WAITING: 'Waiting',
  WAITING_FOR_PREDECESSORS: 'WAITING FOR PREDECESSORS',
  WANT_TO_CLOSE_TICKET_WHEN_MIGRATING_TO_OS: 'Do you want to close the ticket when migrating to OS?',
  WARNING: 'Warning',
  WARNING_DELETE_COMPANY: 'Attention: When deleting the COMPANY, all items related to it will be deleted, do you really want to delete it?',
  WARNING_DELETE_ITEM: 'Do you really want to delete this item?',
  WARNING_DELETE_ITEM_SELECTED: 'Do you really want to delete the selected item?',
  WARNING_DELETE_LOCATION: 'Warning: Do you really want to delete this location?',
  WARNING_SUBACTIVITY_OUTSIDE_PROJECT_PERIOD: 'Subactivity outside the project period',
  WARNING_WANT_CANCEL_PROJECT: 'PROJECT ALREADY STARTED, DO YOU WANT TO CANCEL THE PROJECT?',
  WARNING_WANT_REMOVE_PROJECT: 'DO YOU WANT TO REMOVE THE PROJECT?',
  WARRANTY: 'Warranty',
  WEEK: 'Week',
  WEEKLY: 'Weekly',
  WEIGHT: 'Weight',
  WEIGHT_CAB: 'CAB and Weight',
  WELCOME_PAGE: {
    CONTRACTS_MANAGEMENT: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et erat nec metus tristique vulputate. Maecenas vitae urna viverra, pellentesque urna efficitur, commodo lacus.',
    INFRATRUCTURE_MANAGEMENT: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et erat nec metus tristique vulputate. Maecenas vitae urna viverra, pellentesque urna efficitur, commodo lacus.',
    RISKS_MANAGEMENT: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et erat nec metus tristique vulputate. Maecenas vitae urna viverra, pellentesque urna efficitur, commodo lacus.',
    SERVICE_DESK: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et erat nec metus tristique vulputate. Maecenas vitae urna viverra, pellentesque urna efficitur, commodo lacus.',
    SUBTITLE: 'Choose an option below to get started',
    TITLE: 'Welcome'
  },
  WHANT_TO_APPROVE: 'Are you sure of Approval?',
  WHAT_YOUR_SUBCATEGORY: 'What is your subcategory?',
  WHEN_PERFORMING_THE_LANGUAGE_CHANGE_YOU_WILL_BE_DISCONNECTED: 'When performing the language change, you will be disconnected and need to login again.Do you want to continue?',
  WHEN_YOU_CHANGE_YOUR_TIMEZONE_YOU_WILL_BE_DISCONNECTED: 'When you change your timezone, you will be disconnected and need to login again.Do you want to continue?',
  WHICH_KANBAN_COLUMN_SPRINT_WILL_STATUS_MATCH: 'Which kanban column in the sprint will this status match?',
  WHY: 'Why',
  WISH: 'WISH',
  WISHE: 'Wish',
  WISHES: 'Wishes',
  WORKDAY: 'Workday',
  WORKED_TICKETS: 'Worked Tickets',
  WORKFLOW: 'Workflow',
  WORKFLOW_OF_CHANGE: 'Workflow of change',
  WRITE: 'Write',
  WRITE_STATUS_DESCRIPTION: 'Write the status description',
  WRITE_STATUS_NAME: 'Write status name',
  WRITE_THE_BENEFIT_HERE: 'Write the benefit here',
  WRITE_THE_DESCRIPTION_HERE: 'Write the description here',
  YEAR: 'Year',
  YEAR_OPTIONS: ['2017', '2018', '2019', '2020', '2021', '2022', '2023'],
  YEAR_VALUE: 'Year Value',
  YEARLY: 'Yearly',
  YES: 'Yes',
  YOU_ARE_CALLER: 'You are the caller!',
  YOU_ARE_NOT_PART_OF_THE_GROUP_REVISION: 'You are not part of the group with permission to review this GMUD',
  YOU_ARE_NOT_PART_OF_THE_TECH_GROUP: 'You are not part of the tech group for the activity',
  YOU_DO_NOT_HAVE_ACCESS_TO_CREATE_USERS: 'You do not have access to create users',
  YOU_HAVE_BEEN_ADDED_OBSERVER: 'You have been added as an observer!',
  YOUR_FAVORITES: 'your favorites',
  YOUR_POINT_AVERAGE_NPS: 'Your POINT AVERAGE (NPS)',
  YOUR_REPORT_WILL_BE_GENERATED_IN_A_FEW_SECONDS: 'Your Report will be generated in a few seconds'
}